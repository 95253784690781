import React from 'react'
import { Col, Pagination } from 'react-bootstrap'
import RowsPerPageDropdown from '../RowsPerPageDropdown'

function PaginationWrapper({
    setCurrentPage,
    currentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
    showOnMobile = false,
    className = '',
}) {
    const handleClick = (page) => {
        setCurrentPage(page)
    }
    return (
        <>
            <div
                className={`${
                    showOnMobile ? '' : 'd-none'
                } d-md-flex align-items-center justify-content-start ${className}`}
            >
                <Col className="p-3 d-flex align-items-center justify-content-between pagination-wrapper">
                    <h5 className={`${!showOnMobile && 'ms-3'}`}>
                        Ukupno: {total}
                    </h5>
                    <div className="d-flex align-items-center">
                        <h5 className={!showOnMobile ? 'me-3' : 'me-1'}>
                            Redova po stranici:
                        </h5>
                        <RowsPerPageDropdown
                            className={!showOnMobile ? 'me-3' : 'me-1'}
                            rowsPerPage={rowsPerPage}
                            onSelect={(e) => setRowsPerPage(e)}
                        />
                        {!showOnMobile && (
                            <h5
                                className={!showOnMobile ? 'me-3' : 'me-3'}
                                style={{ minWidth: '40px', textAlign: 'end' }}
                            >
                                {total === 0
                                    ? total
                                    : (currentPage - 1) * rowsPerPage + 1}
                                -
                                {currentPage >= pageCount
                                    ? total
                                    : currentPage * rowsPerPage}
                            </h5>
                        )}
                        {!showOnMobile && (
                            <Pagination
                                size={!showOnMobile ? 'sm' : 'md'}
                                className={
                                    !showOnMobile ? 'me-3' : 'mobile-pagination'
                                }
                            >
                                <Pagination.Prev
                                    disabled={currentPage === 1}
                                    onClick={() =>
                                        handleClick((prev) => prev - 1)
                                    }
                                    className={
                                        showOnMobile && 'mobile-prev-next'
                                    }
                                />
                                <Pagination.Next
                                    disabled={currentPage >= pageCount}
                                    onClick={() =>
                                        handleClick((prev) => prev + 1)
                                    }
                                    className={
                                        showOnMobile && 'mobile-prev-next'
                                    }
                                />
                            </Pagination>
                        )}
                    </div>
                </Col>
            </div>

            {showOnMobile && (
                <div className="d-flex align-items-center justify-content-between bg-white p-3">
                    <h5
                        className={!showOnMobile ? 'me-3' : 'me-3'}
                        style={{ minWidth: '40px' }}
                    >
                        {total === 0
                            ? total
                            : (currentPage - 1) * rowsPerPage + 1}
                        -
                        {currentPage >= pageCount
                            ? total
                            : currentPage * rowsPerPage}
                    </h5>

                    <Pagination
                        size={!showOnMobile ? 'sm' : 'md'}
                        className={!showOnMobile ? 'me-3' : 'mobile-pagination'}
                    >
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handleClick((prev) => prev - 1)}
                            className={showOnMobile && 'mobile-prev-next'}
                        >
                            &lsaquo; Prethodna
                        </Pagination.Prev>
                        <Pagination.Next
                            disabled={currentPage >= pageCount}
                            onClick={() => handleClick((prev) => prev + 1)}
                            className={showOnMobile && 'mobile-prev-next'}
                        >
                            Sledeća &rsaquo;
                        </Pagination.Next>
                    </Pagination>
                </div>
            )}
        </>
    )
}

export default PaginationWrapper
