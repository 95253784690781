import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import {
    addPlayerOnTurnament,
    calculateSeed,
    checkIsPlayerAlreadyCheckedInForTournament,
    getLastMedicalCerificate,
    removePlayerFromTournament,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import {
    formatDate,
    formatImgUrl,
    handleGenders,
    handlePlayerCheckInCategory,
    isPlayerDisciplinaryPunished,
} from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'

function TableRow({ result, index, mobile = false }) {
    const { user, handleRefresh, setShowCustomToast } = useAppContext()

    const [isAlreadyCheckedIn, setIsAlreadyCheckedIn] = useState(false)
    const [showCheckoutModal, setShowCheckoutModal] = useState(false)
    const [showCheckInModal, setShowCheckInModal] = useState(false)

    const [tournamentParticipantsEntry, setTournamentParticipantsEntry] =
        useState(null)

    const checkIsAlreadyCheckedIn = async (playerUuid, tournamentUuid) => {
        const { tournaments } =
            await checkIsPlayerAlreadyCheckedInForTournament(
                playerUuid,
                tournamentUuid
            )

        if (tournaments) {
            if (tournaments?.length > 0) {
                setTournamentParticipantsEntry(tournaments[0])
                setIsAlreadyCheckedIn(true)
            } else {
                setIsAlreadyCheckedIn(false)
            }
        }
    }

    const handleAutoSeed = async () => {
        await calculateSeed({
            tournament_uuid: result?.attributes?.uuid,
            gender: user?.data?.attributes?.gender?.gender_name,
        })
    }

    const checkIn = async () => {
        if (await handleCheckInConditions()) {
            const dataToSend = {
                tournament_uuid: result?.attributes?.uuid,
                player_1_uuid: user?.data?.attributes?.uuid,
                gender: {
                    gender_name: user?.data?.attributes?.gender?.gender_name,
                },
            }

            try {
                const addedPlayer = await addPlayerOnTurnament(dataToSend)

                if (addedPlayer) {
                    handleAutoSeed()
                    setShowCheckInModal(false)
                    setShowCustomToast({
                        show: true,
                        type: 'success',
                        message: 'Uspešno ste se prijavili na takmičenje.',
                    })
                    handleRefresh()
                }
            } catch (error) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        }
    }

    const checkOut = async () => {
        if (handleCheckOutConditions()) {
            try {
                const removedPlayer = await removePlayerFromTournament(
                    tournamentParticipantsEntry?.attributes?.uuid
                )

                if (removedPlayer) {
                    handleAutoSeed()
                    setShowCheckoutModal(false)
                    setShowCustomToast({
                        show: true,
                        type: 'success',
                        message: 'Uspešno ste se odjavili sa takmičenja.',
                    })
                    handleRefresh()
                }
            } catch (error) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        }
    }

    const handleIsPunished = async (playerUuid) => {
        let isPlayerPunished = await isPlayerDisciplinaryPunished(playerUuid)

        return isPlayerPunished
    }

    const handleCheckInConditions = async () => {
        let conditions = {
            end_date: false,
            gender: false,
            competition: false,
            punished: false,
            medical: false,
            is_active: false,
        }

        if (user?.data?.attributes?.status?.status_name === 'active') {
            conditions.is_active = true
        } else {
            conditions.gender = false
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Nemate plaćenu članarinu.',
            })

            return false
        }
        if (result?.attributes?.genders?.length > 1) {
            if (
                result?.attributes?.genders?.some(
                    (item) =>
                        item.gender_name ===
                        user?.data?.attributes?.gender?.gender_name
                )
            ) {
                conditions.gender = true
            } else {
                conditions.gender = false

                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Ne ispunjavate kriterijume za ovo takmičenje: Pol.',
                })

                return false
            }
        } else {
            if (
                result?.attributes?.genders[0].gender_name ===
                user?.data?.attributes?.gender?.gender_name
            ) {
                conditions.gender = true
            } else {
                conditions.gender = false

                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Ne ispunjavate kriterijume za ovo takmičenje: Pol.',
                })

                return false
            }
        }

        if (
            new Date(result?.attributes?.application_deadline_date).setHours(
                0,
                0,
                0,
                0
            ) >= new Date().setHours(0, 0, 0, 0)
        ) {
            conditions.end_date = true
        } else {
            conditions.end_date = false

            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Rok za prijavu na takmičenje je istekao.',
            })

            return false
        }

        // if (
        //     handlePlayerCheckInCategory({
        //         birthdayDate: user?.data?.attributes?.person_info?.birthdate,
        //         competitionCategory:
        //             result?.attributes?.players_competition
        //                 ?.players_competition_name,
        //         competitionSubCategory:
        //             result?.attributes?.players_competition
        //                 ?.players_competition_subcategory_name,
        //         nextYearTournamentDate:
        //             result?.attributes?.next_year_tournament_date,
        //     })
        // ) {
        //     conditions.competition = true
        // } else {
        //     conditions.competition = false

        //     setShowCustomToast({
        //         show: true,
        //         type: 'error',
        //         message:
        //             'Ne ispunjavate kriterijume za ovo takmičenje: Konkurencija.',
        //     })

        //     return false
        // }

        conditions.competition = true

        const isPlayerPunished = await handleIsPunished(
            user?.data?.attributes?.uuid
        )

        if (isPlayerPunished) {
            conditions.punished = false
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Ne ispunjavate kriterijume za ovo takmičenje: Kazna.',
            })
        } else {
            conditions.punished = true
        }

        const lastMedicalCert = await getLastMedicalCerificate(
            user?.data?.attributes?.uuid
        )

        if (lastMedicalCert?.length) {
            if (lastMedicalCert[0]?.attributes?.end_date) {
                if (
                    new Date(lastMedicalCert[0]?.attributes?.end_date).setHours(
                        0,
                        0,
                        0,
                        0
                    ) < new Date().setHours(0, 0, 0, 0)
                ) {
                    conditions.medical = false
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message:
                            'Ne ispunjavate kriterijume za ovo takmičenje: Istekao lekarski pregled.',
                    })
                } else {
                    conditions.medical = true
                }
            } else {
                conditions.medical = false
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Ne ispunjavate kriterijume za ovo takmičenje: Nema datuma za lekarski pregled.',
                })
            }
        } else {
            conditions.medical = false
            setShowCustomToast({
                show: true,
                type: 'error',
                message:
                    'Ne ispunjavate kriterijume za ovo takmičenje: Nema lekarskog pregleda.',
            })
        }

        if (
            conditions?.end_date &&
            conditions?.gender &&
            conditions?.competition &&
            conditions?.punished &&
            conditions?.medical &&
            conditions?.is_active
        ) {
            return true
        } else {
            return false
        }
    }

    const handleCheckOutConditions = () => {
        if (
            new Date(result?.attributes?.checkout_deadline_date).setHours(
                0,
                0,
                0,
                0
            ) >= new Date().setHours(0, 0, 0, 0)
        ) {
            return true
        } else {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Rok za odjavu sa takmičenja je istekao.',
            })

            return false
        }
    }

    useEffect(() => {
        if (user) {
            checkIsAlreadyCheckedIn(
                user?.data?.attributes?.uuid,
                result?.attributes?.uuid
            )
        }
    }, [user])

    return (
        <>
            {!mobile ? (
                <tr className="club" key={index}>
                    <td>
                        <Link
                            to={`/takmicar/takmicenja/${result?.attributes?.uuid}`}
                        >
                            <p className="link">{result?.attributes?.name}</p>
                        </Link>
                    </td>
                    <td>
                        {result?.attributes?.club_organizer?.attributes
                            ?.name ? (
                            <>
                                <img
                                    src={
                                        result?.attributes?.club_organizer
                                            ?.attributes?.logo?.data
                                            ? formatImgUrl(
                                                  result?.attributes
                                                      ?.club_organizer
                                                      ?.attributes?.logo?.data
                                                      ?.attributes?.url
                                              )
                                            : generateAvatar(
                                                  result?.attributes
                                                      ?.club_organizer
                                                      ?.attributes?.name
                                              )
                                    }
                                    alt={`Logo kluba`}
                                    width={'24px'}
                                    height={'24px'}
                                    style={{
                                        maxHeight: '24px',
                                        maxWidth: '24px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <Link
                                    to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                >
                                    <p className="ms-2 link">
                                        {
                                            result?.attributes?.club_organizer
                                                ?.attributes?.name
                                        }
                                    </p>
                                </Link>
                            </>
                        ) : (
                            <p>-</p>
                        )}
                    </td>
                    <td>
                        <p>
                            {result?.attributes?.application_deadline_date
                                ? formatDate(
                                      result?.attributes
                                          ?.application_deadline_date
                                  )
                                : '-'}
                        </p>
                    </td>
                    <td>
                        <p>
                            {result?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      result?.attributes?.checkout_deadline_date
                                  )
                                : '-'}
                        </p>
                    </td>
                    <td>
                        <p>
                            {result?.attributes?.genders
                                ? handleGenders(result?.attributes?.genders)
                                : '-'}
                        </p>
                    </td>
                    <td>
                        <p>
                            {result?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? result?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : '-'}
                        </p>
                    </td>
                    <td>
                        <p>{result?.attributes?.ranking_points || 0}</p>
                    </td>
                    {result?.attributes?.tournament_type
                        ?.tournament_type_name === 'Individualno' ? (
                        !isAlreadyCheckedIn ? (
                            <td>
                                <p
                                    onClick={async () =>
                                        (await handleCheckInConditions())
                                            ? setShowCheckInModal(true)
                                            : null
                                    }
                                    className="link"
                                >
                                    Prijavi se
                                </p>
                            </td>
                        ) : (
                            <td>
                                <p
                                    onClick={() =>
                                        handleCheckOutConditions()
                                            ? setShowCheckoutModal(true)
                                            : null
                                    }
                                    className="link"
                                >
                                    Odjavi se
                                </p>
                            </td>
                        )
                    ) : (
                        <td></td>
                    )}
                </tr>
            ) : (
                <Card
                    key={index}
                    className={`club-card competitor-tournaments-card mb-3 ${
                        index % 2 !== 0 ? 'white-club-card' : 'grey-club-card'
                    }`}
                >
                    <Card.Body className="py-0">
                        <Row>
                            <Col>
                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Naziv</h4>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <Link
                                            to={`/takmicar/takmicenja/${result?.attributes?.uuid}`}
                                        >
                                            <p className="link">
                                                {result?.attributes?.name}
                                            </p>
                                        </Link>
                                    </Col>
                                </Row>

                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Organizator</h4>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <div className="d-flex align-items-center align-items-center justify-content-start">
                                            {result?.attributes?.club_organizer
                                                ?.attributes?.name ? (
                                                <>
                                                    <img
                                                        src={
                                                            result?.attributes
                                                                ?.club_organizer
                                                                ?.attributes
                                                                ?.logo?.data
                                                                ? formatImgUrl(
                                                                      result
                                                                          ?.attributes
                                                                          ?.club_organizer
                                                                          ?.attributes
                                                                          ?.logo
                                                                          ?.data
                                                                          ?.attributes
                                                                          ?.url
                                                                  )
                                                                : generateAvatar(
                                                                      result
                                                                          ?.attributes
                                                                          ?.club_organizer
                                                                          ?.attributes
                                                                          ?.name
                                                                  )
                                                        }
                                                        alt={`Logo kluba`}
                                                        width={'24px'}
                                                        height={'24px'}
                                                        style={{
                                                            maxHeight: '24px',
                                                            maxWidth: '24px',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                    <Link
                                                        to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                                    >
                                                        <p className="ms-2 link">
                                                            {
                                                                result
                                                                    ?.attributes
                                                                    ?.club_organizer
                                                                    ?.attributes
                                                                    ?.name
                                                            }
                                                        </p>
                                                    </Link>
                                                </>
                                            ) : (
                                                <p>-</p>
                                            )}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Prijava do</h4>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <p>
                                            {result?.attributes
                                                ?.application_deadline_date
                                                ? formatDate(
                                                      result?.attributes
                                                          ?.application_deadline_date
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Odjava do</h4>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <p>
                                            {result?.attributes
                                                ?.checkout_deadline_date
                                                ? formatDate(
                                                      result?.attributes
                                                          ?.checkout_deadline_date
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Pol</h4>
                                    </Col>
                                    <Col>
                                        <p>
                                            {result?.attributes?.genders
                                                ? handleGenders(
                                                      result?.attributes
                                                          ?.genders
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="car-row align-items-center">
                                    <Col>
                                        <h4>Kategorija</h4>
                                    </Col>
                                    <Col>
                                        <p>
                                            {result?.attributes
                                                ?.tournament_category
                                                ?.tournament_category_name
                                                ? result?.attributes
                                                      ?.tournament_category
                                                      ?.tournament_category_name
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                {result?.attributes?.tournament_type
                                    ?.tournament_type_name ===
                                'Individualno' ? (
                                    !isAlreadyCheckedIn ? (
                                        <Row className="car-row border-bottom-0 align-items-center">
                                            <Col>
                                                <p
                                                    onClick={async () =>
                                                        (await handleCheckInConditions())
                                                            ? setShowCheckInModal(
                                                                  true
                                                              )
                                                            : null
                                                    }
                                                    className="link"
                                                >
                                                    Prijavi se
                                                </p>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row className="car-row border-bottom-0 align-items-center">
                                            <Col>
                                                <p
                                                    onClick={() =>
                                                        handleCheckOutConditions()
                                                            ? setShowCheckoutModal(
                                                                  true
                                                              )
                                                            : null
                                                    }
                                                    className="link"
                                                >
                                                    Odjavi se
                                                </p>
                                            </Col>
                                        </Row>
                                    )
                                ) : null}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
            <ConfirmationModal
                show={showCheckoutModal}
                onHide={() => setShowCheckoutModal(false)}
                modalTitle="Odjava sa takmičenja"
                modalText={`Jeste li sigurni da želite da se odjavite sa takmičenja - ${result?.attributes?.name}?`}
                firstButtonText="Odustani"
                secondButtonText="Odjavi se"
                onClick={checkOut}
            />
            <ConfirmationModal
                show={showCheckInModal}
                onHide={() => setShowCheckInModal(false)}
                modalTitle="Prijava na takmičenje"
                modalText={`Jeste li sigurni da želite da se prijavite na takmičenje - ${result?.attributes?.name}?`}
                firstButtonText="Odustani"
                secondButtonText="Prijavi se"
                onClick={checkIn}
            />
        </>
    )
}

export default TableRow
