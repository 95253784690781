import html2PDF from 'jspdf-html2canvas'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import TournamentClubMatchCardForPDF from '../../../../../../components/TournamentClubMatchCardForPDF'
import TournamentMatchCardForPDF from '../../../../../../components/TournamentMatchCardForPDF'
import {
    getSingleClub,
    getSingleReferee,
    getTournamentsReferees,
    getTeamTournamentParticipantsNumber,
    getTournamentParticipantsNumber,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import {
    formatDate,
    handleDrawSizeBasedOnGroupNumber,
    handleNumberOfMatchCardInRound,
    handleRoundName,
    handleRoundsNumber,
} from '../../../../../../lib/helpers'

const DrawModalForExport = ({
    show,
    onHide,
    competitionProfileData,
    drawToShow,
    printDrawSelected = false,
    setPrintDrawSelected = false,
}) => {
    const { setIsLoading } = useAppContext()
    const pdfRef = useRef(null)
    const [drawSize, setDrawSize] = useState(0)
    const [referee, setReferee] = useState('')

    const tournamentName = competitionProfileData?.attributes?.name || ''
    const tournamentUUID = competitionProfileData?.attributes?.uuid
    const tournamentCategory =
        competitionProfileData?.attributes?.tournament_category
            ?.tournament_category_name || ''
    const playersCompetition =
        competitionProfileData?.attributes?.players_competition
            ?.players_competition_name || ''
    const tournamentStartDate =
        formatDate(competitionProfileData?.attributes?.start_date) || ''

    const handleClub = async () => {
        if (!competitionProfileData?.attributes?.club_organizer_uuid) return
        const club_organizer = await getSingleClub(
            competitionProfileData.attributes.club_organizer_uuid
        )

        competitionProfileData.attributes.club_organizer = {
            ...club_organizer,
        }
    }

    const cardHeight = 50

    const handleDownload = async (print = false) => {
        let pdfdoc = document.getElementById('draw-pdf')

        let doc = await html2PDF(pdfdoc, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            autoResize: true,
            output: `${tournamentName}-${
                competitionProfileData?.attributes?.club_organizer?.attributes
                    ?.name
            }-${tournamentStartDate}-${playersCompetition}-${tournamentCategory}-${
                referee || ''
            }.pdf`,
        })

        if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
        } else {
            doc.save(
                `${tournamentName}-${
                    competitionProfileData?.attributes?.club_organizer
                        ?.attributes?.name
                }-${tournamentStartDate}-${playersCompetition}-${tournamentCategory}-${
                    referee || ''
                }.pdf`
            )
        }
    }

    const handleTournamentReferees = async () => {
        if (!tournamentUUID) return
        const tournamentReferees = await getTournamentsReferees(tournamentUUID)

        if (tournamentReferees) {
            const refereeAdminData = tournamentReferees.find(
                (referee) =>
                    referee?.attributes?.referee_type?.referee_type_name ===
                    'Vrhovni sudija'
            )
            const leaderData = tournamentReferees.find(
                (referee) =>
                    referee?.attributes?.referee_type?.referee_type_name ===
                    'Voditelj turnira'
            )

            let tournamentRefereeAdminData
            let tournamentLeaderData

            if (refereeAdminData) {
                tournamentRefereeAdminData = await getSingleReferee(
                    refereeAdminData?.attributes?.referee_uuid
                )
            }

            if (leaderData) {
                tournamentLeaderData = await getSingleReferee(
                    leaderData?.attributes?.referee_uuid
                )
            }

            if (
                competitionProfileData?.attributes?.tournament_type
                    ?.tournament_type_name === 'Ekipno'
            ) {
                let name = []
                if (tournamentLeaderData?.attributes?.person_info?.given_name) {
                    name.push(
                        tournamentLeaderData?.attributes?.person_info
                            ?.given_name
                    )
                }

                if (
                    tournamentLeaderData?.attributes?.person_info?.family_name
                ) {
                    name.push(
                        tournamentLeaderData?.attributes?.person_info
                            ?.family_name
                    )
                }
                let nameString =
                    name?.length > 0 ? name?.join(' ') : 'Nema informacije'
                setReferee(nameString)
            } else {
                let name = []
                if (
                    tournamentRefereeAdminData?.attributes?.person_info
                        ?.given_name
                ) {
                    name.push(
                        tournamentRefereeAdminData?.attributes?.person_info
                            ?.given_name
                    )
                }

                if (
                    tournamentRefereeAdminData?.attributes?.person_info
                        ?.family_name
                ) {
                    name.push(
                        tournamentRefereeAdminData?.attributes?.person_info
                            ?.family_name
                    )
                }
                let nameString =
                    name?.length > 0 ? name?.join(' ') : 'Nema informacije'
                setReferee(nameString)
            }
        }
    }

    const initFunction = async () => {
        let drawSize = 0
        if (competitionProfileData?.attributes?.tournament_have_round_roubin) {
            let total = 0
            if (
                competitionProfileData?.attributes?.tournament_type
                    ?.tournament_type_name === 'Ekipno'
            ) {
                const { total: totalParticipants } =
                    await getTeamTournamentParticipantsNumber(
                        competitionProfileData?.attributes?.uuid
                    )
                total = totalParticipants
            } else {
                const { total: totalParticipants } =
                    await getTournamentParticipantsNumber(
                        competitionProfileData?.attributes?.uuid,
                        drawToShow?.gender?.gender_name,
                        drawToShow?.is_double
                    )
                total = totalParticipants
            }

            if (!total) return
            let fullGroups = Math.floor(total / 4)
            let rest = total % 4
            if (rest > 0) {
                fullGroups += 1
            }

            drawSize = handleDrawSizeBasedOnGroupNumber(fullGroups) / 2

            // const numberOfPlayers = fullGroups * 2
            // console.log('numberOfPlayers', numberOfPlayers)
            // if (numberOfPlayers <= 2) {
            //     drawSize = 2
            // } else if (numberOfPlayers > 2 && numberOfPlayers <= 4) {
            //     drawSize = 4
            // } else if (numberOfPlayers > 4 && numberOfPlayers <= 8) {
            //     drawSize = 8
            // } else if (numberOfPlayers > 8 && numberOfPlayers <= 16) {
            //     drawSize = 16
            // } else if (numberOfPlayers > 16 && numberOfPlayers <= 32) {
            //     drawSize = 32
            // } else if (numberOfPlayers > 32 && numberOfPlayers <= 64) {
            //     drawSize = 64
            // } else if (numberOfPlayers > 64 && numberOfPlayers <= 128) {
            //     drawSize = 128
            // } else {
            //     drawSize = 2
            // }
        } else {
            if (drawToShow?.draw_type?.draw_type_name === 'Kvalifikacije') {
                drawSize =
                    +competitionProfileData?.attributes
                        ?.qualifications_participants_expected_number /
                    +competitionProfileData?.attributes?.number_of_qualified /
                    2
            } else if (
                drawToShow?.draw_type?.draw_type_name === 'Utešni turnir'
            ) {
                drawSize = drawToShow?.is_double
                    ? competitionProfileData?.attributes
                          ?.doubles_pairs_expected_number / 4
                    : competitionProfileData?.attributes
                          ?.participants_expected_number / 4
            } else if (
                drawToShow?.draw_type?.draw_type_name === 'Glavni turnir'
            ) {
                if (
                    competitionProfileData?.attributes
                        ?.participants_expected_number === 48
                ) {
                    drawSize = 32
                } else {
                    drawSize =
                        +competitionProfileData?.attributes
                            ?.participants_expected_number / 2
                }
            } else {
                if (
                    +competitionProfileData?.attributes
                        ?.participants_expected_number === 48
                ) {
                    drawSize = drawToShow?.is_double
                        ? competitionProfileData?.attributes
                              ?.doubles_pairs_expected_number / 2
                        : 64 / 2
                } else {
                    drawSize = drawToShow?.is_double
                        ? competitionProfileData?.attributes
                              ?.doubles_pairs_expected_number / 2
                        : competitionProfileData?.attributes
                              ?.participants_expected_number / 2
                }
            }
        }
        setDrawSize(drawSize)
        handleClub()
        handleTournamentReferees()
    }

    useEffect(() => {
        initFunction()
    }, [competitionProfileData, drawToShow])

    useEffect(() => {
        if (show) {
            setIsLoading(true)
        }
    }, [show])

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="add-penalties-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="d-flex">
                        {printDrawSelected ? (
                            <Button
                                type="secondary"
                                onClick={() => handleDownload(true)}
                                className="mb-3"
                            >
                                Štampaj
                            </Button>
                        ) : (
                            <Button
                                type="secondary"
                                onClick={() => handleDownload()}
                                className="mb-3 me-2"
                            >
                                Eksportuj u PDF
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col id="draw-pdf" ref={pdfRef} className="p-4">
                        <Row className="mb-4">
                            <Col>
                                <h2>
                                    {tournamentName}
                                    {' - '}
                                    {
                                        competitionProfileData?.attributes
                                            ?.club_organizer?.attributes?.name
                                    }
                                    - {tournamentStartDate} -{' '}
                                    {playersCompetition}
                                    {' - '}
                                    {tournamentCategory} - {referee || ''}
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Naziv: {tournamentName}</p>
                                <p>
                                    Organizator:{' '}
                                    {competitionProfileData?.attributes
                                        ?.club_organizer?.attributes?.name ||
                                        ''}
                                </p>
                                <p>Početak: {tournamentStartDate || ''}</p>
                            </Col>
                            <Col>
                                <p>Konkurencija: {playersCompetition || ''}</p>
                                <p>Kategorija: {tournamentCategory || ''}</p>
                                <p>
                                    {competitionProfileData?.attributes
                                        ?.tournament_type
                                        ?.tournament_type_name === 'Ekipno'
                                        ? 'Voditelj turnira'
                                        : 'Sudija'}
                                    : {referee || ''}
                                </p>
                            </Col>
                        </Row>

                        <div className="d-flex mt-4">
                            {[...Array(handleRoundsNumber(drawSize) + 2)].map(
                                (_, roundIndex) => (
                                    <div
                                        key={roundIndex}
                                        style={{
                                            width: `${
                                                100 /
                                                (handleRoundsNumber(drawSize) +
                                                    2)
                                            }%`,
                                        }}
                                        className="d-flex justify-content-center"
                                    >
                                        <h5>
                                            {handleRoundsNumber(drawSize) +
                                                1 ===
                                            roundIndex
                                                ? 'Pobednik'
                                                : handleRoundName(
                                                      handleRoundsNumber(
                                                          drawSize
                                                      ),
                                                      roundIndex
                                                  )}
                                        </h5>
                                    </div>
                                )
                            )}
                        </div>
                        <Row
                            style={{ overflowX: 'scroll' }}
                            className="gx-0 pt-4"
                        >
                            {[...Array(handleRoundsNumber(drawSize) + 2)].map(
                                (_, roundIndex) => (
                                    <div
                                        key={roundIndex}
                                        style={{
                                            width: `${
                                                100 /
                                                (handleRoundsNumber(drawSize) +
                                                    2)
                                            }%`,
                                            paddingTop: `${
                                                Math.pow(2, roundIndex) *
                                                    cardHeight -
                                                Math.pow(2, roundIndex - 1) *
                                                    cardHeight
                                            }px`,
                                        }}
                                    >
                                        {handleRoundsNumber(drawSize) + 1 !==
                                        roundIndex ? (
                                            [
                                                ...Array(
                                                    handleNumberOfMatchCardInRound(
                                                        handleRoundsNumber(
                                                            drawSize
                                                        ) - roundIndex
                                                    )
                                                ),
                                            ].map((_, cardiIndex) =>
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.tournament_type
                                                    ?.tournament_type_name ===
                                                'Ekipno' ? (
                                                    <TournamentClubMatchCardForPDF
                                                        key={cardiIndex}
                                                        competitionProfileData={
                                                            competitionProfileData
                                                        }
                                                        style={{
                                                            height: `${
                                                                Math.pow(
                                                                    2,
                                                                    roundIndex
                                                                ) * cardHeight
                                                            }px`,
                                                            marginBottom: `${
                                                                Math.pow(
                                                                    2,
                                                                    roundIndex
                                                                ) * cardHeight
                                                            }px`,
                                                        }}
                                                        matchDetails={{
                                                            round_number:
                                                                +roundIndex + 1,
                                                            pair_number:
                                                                +cardiIndex + 1,
                                                            tournament_uuid:
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.uuid,
                                                            ...drawToShow,
                                                        }}
                                                        show={show}
                                                    />
                                                ) : (
                                                    <TournamentMatchCardForPDF
                                                        key={cardiIndex}
                                                        competitionProfileData={
                                                            competitionProfileData
                                                        }
                                                        style={{
                                                            height: `${
                                                                Math.pow(
                                                                    2,
                                                                    roundIndex
                                                                ) * cardHeight
                                                            }px`,
                                                            marginBottom: `${
                                                                Math.pow(
                                                                    2,
                                                                    roundIndex
                                                                ) * cardHeight
                                                            }px`,
                                                        }}
                                                        matchDetails={{
                                                            round_number:
                                                                +roundIndex + 1,
                                                            pair_number:
                                                                +cardiIndex + 1,
                                                            tournament_uuid:
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.uuid,
                                                            ...drawToShow,
                                                        }}
                                                        show={show}
                                                    />
                                                )
                                            )
                                        ) : competitionProfileData?.attributes
                                              ?.tournament_type
                                              ?.tournament_type_name ===
                                          'Ekipno' ? (
                                            <TournamentClubMatchCardForPDF
                                                style={{
                                                    height: `${
                                                        Math.pow(
                                                            2,
                                                            roundIndex
                                                        ) * cardHeight
                                                    }px`,
                                                    marginBottom: `${
                                                        Math.pow(
                                                            2,
                                                            roundIndex
                                                        ) * cardHeight
                                                    }px`,
                                                }}
                                                competitionProfileData={
                                                    competitionProfileData
                                                }
                                                matchDetails={{
                                                    round_number: +roundIndex,
                                                    pair_number: 1,
                                                    tournament_uuid:
                                                        competitionProfileData
                                                            ?.attributes?.uuid,
                                                    ...drawToShow,
                                                }}
                                                is_winner={true}
                                                show={show}
                                            />
                                        ) : (
                                            <TournamentMatchCardForPDF
                                                style={{
                                                    height: `${
                                                        Math.pow(
                                                            2,
                                                            roundIndex
                                                        ) * cardHeight
                                                    }px`,
                                                    marginBottom: `${
                                                        Math.pow(
                                                            2,
                                                            roundIndex
                                                        ) * cardHeight
                                                    }px`,
                                                }}
                                                competitionProfileData={
                                                    competitionProfileData
                                                }
                                                matchDetails={{
                                                    round_number: +roundIndex,
                                                    pair_number: 1,
                                                    tournament_uuid:
                                                        competitionProfileData
                                                            ?.attributes?.uuid,
                                                    ...drawToShow,
                                                }}
                                                is_winner={true}
                                                show={show}
                                            />
                                        )}
                                    </div>
                                )
                            )}
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} type="secondary">
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DrawModalForExport
