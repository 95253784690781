import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import turniriPNG from '../../../assets/images/turniri.png'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import SearchInput from '../../../components/SearchInput'
import TableCardRefereeAdminCompetitions from '../../../components/TableCardRefereeAdminCompetitions'
import TableRefereeAdminCompetitionsPagination from '../../../components/TableRefereeAdminCompetitionsPagination'
import { getCodebook, getTournaments } from '../../../lib/api'
import {
    debounce,
    formatDate,
    getClubList,
    isArrayEmpty,
} from '../../../lib/helpers'
import { font } from '../../Admin/Competitions/CompetitionProfile/font'

import { useLocation } from 'react-router-dom'
import AsyncSelect from '../../../components/AsyncSelect'
import NoDataForView from '../../../components/NoDataForView'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import './styles.scss'
const searchInputID = `searchInput-competitions`

function RefereeAdminCompetitions() {
    const location = useLocation()
    const { type } = location?.state ? location?.state : false
    let isCompetitionType = type
        ? type
        : location?.pathname ===
          '/administrator-sudjenja/individualna-takmicenja'
        ? 'Individualno'
        : location?.pathname === '/administrator-sudjenja/ekipna-takmicenja'
        ? 'Ekipno'
        : ''

    //* Codebooks
    const [tournamentCategories, setTournamentCategories] = useState([])
    const [tournamentSubCategories, setTournamentSubCategories] = useState([])
    const [regions, setRegions] = useState([])

    const handleCodebooks = async () => {
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentCategories
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentSubCategories,
            true
        )
        await getCodebook('codebooks.region', setRegions)
    }

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    // Filtering
    const [showFilters, setShowFilters] = useState(false)

    const initialFilters = {
        region: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
        tournament_type: isCompetitionType,
    }

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [selectedClub, setSelectedClub] = useState(null)
    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    // Club selector in MultiSelectSearch
    const handleClubSelect = (e) => {
        const { uuid, value } = e

        setSelectedClub({ uuid: uuid, value: value })
        setFilterParams({ ...filterParams, club_organizer_uuid: uuid })
    }

    const getComingCompetitions = async (page, presetFilters) => {
        let search = document.getElementById(searchInputID)

        if (search) {
            let searchParams = { name: search.value }

            let { tournaments, pgCount, total } = await getTournaments(
                null,
                searchParams,
                presetFilters
                    ? presetFilters === 1
                        ? isCompetitionType
                            ? { tournament_type: isCompetitionType }
                            : null
                        : {
                              ...presetFilters,
                              tournament_type: isCompetitionType,
                          }
                    : { ...filterParams, tournament_type: isCompetitionType },
                page,
                false,
                false,
                rowsPerPage
            )

            if (tournaments) {
                setComingCompetitions(tournaments)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    const getAllFinishedCompetitions = async (page, presetFilters) => {
        let search = document.getElementById(searchInputID)

        if (search) {
            let searchParams = { name: search.value }

            let { tournaments, pgCount, total } = await getTournaments(
                null,
                searchParams,
                presetFilters
                    ? presetFilters === 1
                        ? isCompetitionType
                            ? { tournament_type: isCompetitionType }
                            : null
                        : {
                              ...presetFilters,
                              tournament_type: isCompetitionType,
                          }
                    : { ...filterParams, tournament_type: isCompetitionType },
                page,
                true,
                false,
                rowsPerPage
            )

            if (tournaments) {
                setComingCompetitions(tournaments)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage, 1)
            : getAllFinishedCompetitions(currentPage, 1)
        handleCodebooks()
    }, [currentPage, selectedTab, isCompetitionType, rowsPerPage])

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitions(1)
            : await getAllFinishedCompetitions(1)
    }, 1000)

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setSelectedClub(null)
        setShowFilters(false)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(1, 1)
            : getAllFinishedCompetitions(1, 1)
    }

    // Print PDF coming and finished competitions

    const handlePdfExportComing = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = 'Nadolazeća takmičenja'
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= comingCompetitions?.length; i++) {
            const compt = comingCompetitions[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
            ])
        }

        let comingCompetitionsContent = {
            startY: 50,
            head: [['Naziv', 'Organizator', 'Odjaviti do', 'Kategorija']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(comingCompetitionsContent)
        doc.save('Nadolazeća takmičenja.pdf')
        setShowFilters(false)
    }

    const handlePdfExportFinished = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = 'Završena takmičenja'
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= finishedCompetitions?.length; i++) {
            const compt = finishedCompetitions[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
            ])
        }
        let finishedCompetitionsContent = {
            startY: 50,
            head: [['Naziv', 'Organizator', 'Odjaviti do', 'Kategorija']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(finishedCompetitionsContent)
        doc.save('Završena takmičenja.pdf')
        setShowFilters(false)
    }

    const handleTabSelect = (tab) => {
        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1)
                : getAllFinishedCompetitions(1)
            return tab
        })
    }

    return (
        <Row className="referee-admin-competitions-wrapper">
            {comingCompetitions || finishedCompetitions ? (
                <>
                    <Col xs={12}>
                        <Row className="pt-3">
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex align-items-center justify-content-between"
                            >
                                <Row>
                                    <Col>
                                        <h2 className="d-none d-md-block">
                                            Takmičenja
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Takmičenja
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item>
                                                Takmičenja
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {type
                                                    ? type === 'Ekipno'
                                                        ? 'Ekipna'
                                                        : type ===
                                                          'Individualno'
                                                        ? 'Individualna'
                                                        : 'Iz kalendara TSS'
                                                    : 'Iz kalendara TSS'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>

                                <div className="d-flex align-items-center d-md-none">
                                    <Button
                                        type="text"
                                        onClick={() => setShowFilters(true)}
                                    >
                                        <OutlinedFilter />
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="offset-md-2">
                                <Row className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                        <SearchInput
                                            id={searchInputID}
                                            placeholder="Pronađi takmičenje po nazivu"
                                            onChange={(e) =>
                                                updateDebounceText(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Button
                                            type="secondary"
                                            className="ms-2 d-none d-md-block"
                                            onClick={() => setShowFilters(true)}
                                        >
                                            Filtriraj
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="pt-3 m-0">
                            <Col className="p-0 tabular-navigation">
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    onSelect={(tab) => {
                                        setCurrentPage(1)
                                        document.getElementById(
                                            searchInputID
                                        ).value = ''
                                        handleDisableFiltering()
                                        handleTabSelect(tab)
                                    }}
                                >
                                    <Tab
                                        eventKey="nadolazeca"
                                        title="Nadolazeća"
                                    >
                                        <div className="d-none d-md-block">
                                            <TableRefereeAdminCompetitionsPagination
                                                data={comingCompetitions}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            {!isArrayEmpty(
                                                comingCompetitions
                                            ) ? (
                                                comingCompetitions?.map(
                                                    (competition, index) => (
                                                        <TableCardRefereeAdminCompetitions
                                                            key={
                                                                competition?.id
                                                            }
                                                            data={competition}
                                                            id={competition?.id}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <NoDataForView
                                                    title={`Nema nadolazaćih takmičenja`}
                                                    description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                                    className="border-0"
                                                    column
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="zavrsena" title="Završena">
                                        <div className="d-none d-md-block">
                                            <TableRefereeAdminCompetitionsPagination
                                                data={finishedCompetitions}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                finished={true}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            {!isArrayEmpty(
                                                finishedCompetitions
                                            ) ? (
                                                finishedCompetitions.map(
                                                    (competition, index) => (
                                                        <TableCardRefereeAdminCompetitions
                                                            key={
                                                                competition?.id
                                                            }
                                                            data={competition}
                                                            id={competition?.id}
                                                            finished={true}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <NoDataForView
                                                    title={`Nema završenih takmičenja`}
                                                    description={`Ovde je prikazana lista završenih takmičenja.`}
                                                    className="border-0"
                                                    column
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </>
            ) : (
                <Col
                    md={12}
                    className="d-flex flex-column align-items-center justify-content-center pt-4"
                >
                    <img className="no-data-img" src={turniriPNG}></img>
                    <h2 className="mt-5">Takmičenja</h2>
                    <p className="mt-2">Nema takmičenja u kojima učestvujete</p>
                </Col>
            )}

            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12}>
                            <AsyncSelect
                                loadOptions={getClubList}
                                onChange={handleClubSelect}
                                label={'Izaberi klub'}
                                value={selectedClub}
                                placeholder={'Pretraži i izaberi klub...'}
                                noOptionsMessage={'Traženi klub ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Region'}
                                value={filterParams?.region || ''}
                                placeholder="Region"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        region: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={regions?.reduce(
                                    (accumulator, region) => [
                                        ...accumulator,
                                        region,
                                    ],
                                    filterParams?.region ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Kategorija'}
                                className="category-dropdown"
                                value={filterParams?.category || ''}
                                placeholder="Kategorija"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        category: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentCategories?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.category ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>

                        {filterParams?.category === 'III' && (
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Potkategorija'}
                                    value={filterParams?.sub_category || ''}
                                    placeholder="Potkategorija"
                                    handleSelect={(e) =>
                                        setFilterParams({
                                            ...filterParams,
                                            sub_category:
                                                e === 'Odselektuj' ? '' : e,
                                        })
                                    }
                                    dropdownLinks={tournamentSubCategories?.reduce(
                                        (accumulator, subcategory) => [
                                            ...accumulator,
                                            subcategory,
                                        ],
                                        filterParams?.sub_category
                                            ? ['Odselektuj']
                                            : []
                                    )}
                                />
                            </Col>
                        )}

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => {
                                    selectedTab === 'nadolazeca'
                                        ? getComingCompetitions(1)
                                        : getAllFinishedCompetitions(1)
                                }}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={
                                    selectedTab === 'nadolazeca'
                                        ? handlePdfExportComing
                                        : handlePdfExportFinished
                                }
                            >
                                Štampaj rezultate
                            </Button>
                            <Button
                                type="text"
                                className="w-100 mt-3"
                                onClick={() => handleDisableFiltering()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Row>
    )
}

export default RefereeAdminCompetitions
