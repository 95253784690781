import React, { useEffect, useState } from 'react'

import { Col, ProgressBar, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../assets/svg/CloseOutlined'
import Button from '../../../../components/Button'

import {
    createTournamentReport,
    getCodebook,
    getRefereeLicense,
    getSingleClub,
    getSingleReferee,
    getSingleTournament,
    getTournamentReport,
    getTournamentsReferees,
    updateTournamentReport,
    updateTournament,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import Awards from './Awards'
import Competitors from './Competitors'
import Conclusion from './Conclusion'
import CourtAndBalls from './CourtAndBalls'
import Overview from './Overview'
import TournamentOrganization from './TournamentOrganization'
import './styles.scss'

function CreateReport() {
    const { id } = useParams()
    const { pathname } = useLocation()

    const navigate = useNavigate()
    const { user, setShowCustomToast } = useAppContext()

    //* Codebooks
    const [courtTypes, setCourtTypes] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.court-type').then((result) => {
            setCourtTypes(
                result.reduce((accumulator, currentValue) => {
                    if (
                        !['Zatvoreni', 'Pod reflektorima'].includes(
                            currentValue
                        )
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    const [page, setPage] = useState(1)
    const [tournamentData, setTournamentData] = useState()
    const [referees, setReferees] = useState([])
    const [reportInfo, setReportInfo] = useState(null)

    const [progress, setProgress] = useState(0)
    const [formData, setFormData] = useState({
        competitors: [],
        awards: {
            totalAwardFond: '',
            awardsMenWomen: '',
            awardsDouble: '',
            registrationFeeQualifications: '',
            registrationFeeTournament: '',
        },
        centralCourt: {
            surface: '',
            quantity: '',
            quality: '',
            maintenance: '',
        },
        otherCourts: {
            surface: '',
            quantity: '',
            quality: '',
            maintenance: '',
        },
        reflectors: {
            used: '',
            complaint: '',
            rulebook: '',
        },
        trainingCourts: {
            surface: '',
            quantity: '',
            quality: '',
            maintenance: '',
            generalAppearance: '',
        },
        balls: {
            manufacturer: '',
            quality: '',
            rulebookITF: '',
            usedBalls: '',
            replacement: '',
            rulebookTSS: '',
        },
        physiotherapist: {
            name: '',
            rulesKnowledge: '',
            specialization: '',
            invite: '',
        },
        chiefOffice: {
            roomNumber: '',
            chiefRating: '',
            location: '',
        },
        communication: {
            internationalLine: '',
            fax: '',
            email: '',
            photocopier: '',
            walkieTalkie: '',
            communicationRating: '',
        },
        officials: {
            tournamentStaff: '',
            otherStaff: '',
            rulesKnowledge: '',
            officialsRating: '',
        },
        playersFacilities: {
            facility_1: '',
            facility_2: '',
            facility_3: '',
        },
        conclusion: {
            goodCondition: '',
            improvementCondition: '',
            tips: '',
            generalRemarks: '',
        },
    })

    const FormTitles = [
        'Nagradni fond i kotizacija',
        'Takmičari koji su bili prijavljeni ali se nisu pojavili',
        'Tereni, lopte i fizioterapeut',
        'Objekti i organizacija takmičenja',
        'Pregled i zaključak',
    ]

    const handleTournamentData = async (id) => {
        let data = await getSingleTournament(id)

        if (data) {
            if (data?.attributes?.court_type) {
                setFormData((prev) => ({
                    ...prev,
                    centralCourt: {
                        surface: data?.attributes?.court_type?.court_type_name,
                    },
                    otherCourts: {
                        surface: data?.attributes?.court_type?.court_type_name,
                    },
                    trainingCourts: {
                        surface: data?.attributes?.court_type?.court_type_name,
                    },
                }))
            }
            let club_organizer = await getSingleClub(
                data?.attributes?.club_organizer_uuid
            )

            if (club_organizer) {
                setTournamentData({ ...data?.attributes, club_organizer })
            }
        }
    }

    const handleTournamentReportData = async (tournamentUuid) => {
        try {
            const tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (tournamentReportResponse) {
                setFormData(
                    tournamentReportResponse[0]?.attributes?.report_data
                )
                setReportInfo(tournamentReportResponse[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchTournamentReferees = async (tournamentUuid) => {
        const refereesResponse = await getTournamentsReferees(tournamentUuid)

        if (refereesResponse && refereesResponse?.length > 0) {
            let refereePromises = refereesResponse?.map(
                async (singleReferee) => {
                    let refereeData = await getSingleReferee(
                        singleReferee?.attributes?.referee_uuid
                    )

                    if (refereeData) {
                        let licenses = await getRefereeLicense(
                            singleReferee?.attributes?.referee_uuid
                        )

                        if (licenses) {
                            return {
                                ...singleReferee,
                                attributes: {
                                    ...singleReferee.attributes,
                                    refereeData: {
                                        ...refereeData,
                                        licenses,
                                    },
                                },
                            }
                        }
                    }
                }
            )

            let updatedRefereesResponse = await Promise.all(refereePromises)
            setReferees(updatedRefereesResponse)
        }
    }

    useEffect(() => {
        handleTournamentData(id)
        pathname?.includes('izmeni-izvestaj') && handleTournamentReportData(id)
        fetchTournamentReferees(id)
        handleCodebooks()
    }, [id])

    const PageDisplay = () => {
        switch (page) {
            case 1:
                return (
                    <Awards
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )
            case 2:
                return (
                    <Competitors
                        tournamentData={tournamentData}
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )
            case 3:
                return (
                    <CourtAndBalls
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                        surface_type={courtTypes}
                    />
                )
            case 4:
                return (
                    <TournamentOrganization
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )
            case 5:
                return (
                    <Conclusion
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )
            case 6:
                return (
                    <Overview
                        setProgress={setProgress}
                        formData={{
                            attributes: {
                                ...tournamentData,
                                competition_report: [{ report_data: formData }],
                                referees: referees,
                            },
                        }}
                    />
                )
            default:
                break
        }
    }

    const back = () => {
        setPage((currPage) => currPage - 1)
    }

    const next = async () => {
        if (page === FormTitles.length + 1) {
            const referee_uuid = user?.data?.attributes?.uuid
            try {
                if (pathname?.includes('izmeni-izvestaj')) {
                    let updatedReport = await updateTournamentReport(
                        formData,
                        reportInfo?.attributes?.uuid
                    )

                    if (updatedReport) {
                        setShowCustomToast({
                            show: true,
                            type: 'success',
                            message: 'Izveštaj uspešno ažuriran.',
                        })
                        localStorage.setItem('competitionActiveTab', 'izvestaj')
                        navigate(`/sudije/takmicenja-iz-kalendara-TSS/${id}`)
                    }
                } else {
                    let createdReport = await createTournamentReport(
                        formData,
                        id,
                        referee_uuid
                    )

                    if (createdReport) {
                        setShowCustomToast({
                            show: true,
                            type: 'success',
                            message: 'Izveštaj uspešno kreiran.',
                        })
                        localStorage.setItem('competitionActiveTab', 'izvestaj')
                        navigate(`/sudije/takmicenja-iz-kalendara-TSS/${id}`)
                    }
                }
            } catch (error) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        } else {
            setPage((currPage) => currPage + 1)
        }
    }
    return (
        <Row className="create-report-wrapper justify-content-center">
            <Col className="report-wrapper px-0">
                <Row className="justify-content-center">
                    <ProgressBar now={progress} className="px-0" />
                    <Col className="d-flex flex-column align-items-center justify-content-end close-icon mt-2 mt-md-3 me-md-3">
                        <Row className="w-100 align-items-center justify-content-end">
                            <Link
                                to={`/sudije/takmicenja-iz-kalendara-TSS/${id}`}
                                className="d-flex justify-content-end"
                            >
                                <CloseOutlined />
                            </Link>
                        </Row>
                    </Col>
                    <Row className="report-form-wrapper justify-content-center flex-column">
                        <Col className="justify-content-center align-items-center d-flex flex-column text-center mt-3">
                            <h1>Izveštaj glavnog sudije</h1>
                        </Col>
                        <Col className="d-flex flex-column align-items-center">
                            <Row className="mt-2 w-100 justify-content-center">
                                <Col
                                    md={page > 2 ? 8 : 4}
                                    className="px-0"
                                    style={{ minWidth: '500px' }}
                                >
                                    {page >= 1 && (
                                        <Col
                                            md={12}
                                            className="mt-2 mb-5 d-flex align-items-center justify-content-center"
                                        >
                                            <p className="small-text">{`${page}/${
                                                FormTitles.length + 1
                                            }`}</p>
                                        </Col>
                                    )}
                                    {page >= 1 && page < 6 && (
                                        <Col
                                            md={12}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <h3>{FormTitles[page - 1]}</h3>
                                        </Col>
                                    )}
                                    {/* content based on component */}
                                    <Col className="mt-5 align-items-center justify-content-center">
                                        {PageDisplay()}
                                    </Col>
                                    <Col
                                        className="mt-4"
                                        style={{ minWidth: '500px' }}
                                    >
                                        <Row
                                            className={`d-flex align-items-center justify-content-end footer-buttons no-min-width w-100 m-auto`}
                                            style={{ minWidth: '500px' }}
                                        >
                                            <Col className="mb-2 mb-md-0 p-0 back-button-wrapper">
                                                <Link
                                                    to={
                                                        page === 1 &&
                                                        `/sudije/takmicenja-iz-kalendara-TSS/${id}`
                                                    }
                                                    className="link-text"
                                                    tabIndex="1"
                                                >
                                                    <Button
                                                        type="text"
                                                        className="w-100 back-button"
                                                        // disabled={page === 0}
                                                        onClick={back}
                                                    >
                                                        {page === 1
                                                            ? 'Odustani'
                                                            : 'Prethodna'}
                                                    </Button>
                                                </Link>
                                            </Col>
                                            <Col className="ps-md-0 p-0 next-button-wrapper">
                                                <Button
                                                    className="w-100 next-button"
                                                    onClick={next}
                                                >
                                                    {page ===
                                                    FormTitles.length + 1
                                                        ? 'Završi i pošalji na verifikaciju'
                                                        : 'Dalje'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default CreateReport
