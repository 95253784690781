import React, { useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { alpha2ToAlpha3 } from 'i18n-iso-countries'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AsyncSelect from '../../../../components/AsyncSelect'
import Button from '../../../../components/Button'
import FileUploadTag from '../../../../components/FileUploadTag'
import MultiSelectSearch from '../../../../components/MultiSelectSearch'
import RadioButton from '../../../../components/RadioButton'
import TextField from '../../../../components/TextField'
import UploadImage from '../../../../components/UploadImage'
import {
    addPlayerDocuments,
    getCodebook,
    getPlayerDocuments,
    getSingleCoach,
    getSinglePlayer,
    updatePlayer,
    updatePlayerDocuments,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import {
    addPhoto,
    checkFileSize,
    checkJMBG,
    convertMediaResponseToFile,
    getCoachList,
    numbersOnly,
} from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'
import './styles.scss'

function DraftPlayerEdit({ setIsEditing, isDraft = true }) {
    //* Context states
    const { user, handleRefresh, setShowCustomToast } = useAppContext()
    const disableEdit = user?.role?.type === 'competitor' ? true : false

    //* Codebooks
    const [genders, setGenders] = useState([])
    const [citizenshipTypes, setCitizenshipTypes] = useState([])
    const [playingHandTypes, setPlayingHandTypes] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.citizenship-type', setCitizenshipTypes)
        await getCodebook('codebooks.playing-hand', setPlayingHandTypes)
    }

    //* Navigation
    const navigate = useNavigate()
    //* Params
    const { id } = useParams()

    //* Form Data States
    const [formData, setFormData] = useState({})
    const [disableButton, setDisableButton] = useState(false)
    const [originalEmail, setOriginalEmail] = useState('')

    //* Profile image States
    const [previewImg, setPreviewImg] = useState(null)
    const [newImg, setNewImg] = useState(null)

    //* Cities, Countries States
    const [availableCountries, setAvailableCountries] = useState([])
    const [availableCitizenshipCountries, setAvailableCitizenshipCountries] =
        useState([])
    const [availableCities, setAvailableCities] = useState([])
    const [selectedBirthCountry, setSelectedBirthCountry] = useState(null)
    const [selectedResidentialCountry, setSelectedResidentialCountry] =
        useState(null)
    const [selectedBirthCity, setSelectedBirthCity] = useState(null)
    const [selectedResidentialCity, setSelectedResidentialCity] = useState(null)
    const [
        selectedFirstCitizenshipCountry,
        setSelectedFirstCitizenshipCountry,
    ] = useState(null)
    const [
        selectedSecondCitizenshipCountry,
        setSelectedSecondCitizenshipCountry,
    ] = useState(null)

    //* Citizenship States
    const [citizenshipImgs, setCitizenshipImgs] = useState([])
    const [isDualCitizenship, setIsDualCitizenship] = useState(false)
    const [isSrbCitizen, setIsSrbCitizen] = useState(false)
    const [checkedCitizenValue, setCheckedCitizenValue] = useState(null)
    const [isCitizenChecked, setIsCitizenChecked] = useState(false)
    const [havePreviousDocuments, setHavePreviousDocuments] = useState(false)
    const [newCitizenshipImages, setNewCitizenshipImages] = useState([])

    //* Club States
    const [selectedClub, setSelectedClub] = useState(null)

    //* Coach States
    const [coachProfile, setCoachProfile] = useState()

    //* Country & City section
    const handleCountry = (countryName) => {
        if (countryName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: countryName?.toString(),
                        featureCode: 'PCLI',
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const countryOptions = []
                    response.data?.geonames?.map((country) => {
                        countryOptions?.push({
                            value: country?.name,
                            countryCode: country?.countryCode,
                        })
                    })
                    setAvailableCountries(countryOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCountries([])
        }
    }

    const handleCitizenshipCountry = (countryName) => {
        if (countryName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: countryName?.toString(),
                        featureCode: 'PCLI',
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const countryOptions = []
                    response?.data?.geonames?.map((country) => {
                        let iso3alpha = alpha2ToAlpha3(country?.countryCode)
                        countryOptions?.push({
                            value: `${country?.name}, ${iso3alpha}`,
                            name: country?.name,
                            iso3alpha: iso3alpha,
                        })
                    })
                    setAvailableCitizenshipCountries((_) => {
                        return countryOptions
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCitizenshipCountries([])
        }
    }

    const handleSelectCitizenshipCountry = (
        selectedCountry,
        second = false
    ) => {
        if (second) {
            setFormData((_) => {
                return {
                    ..._,
                    citizenship: {
                        ..._.citizenship,
                        second_citizenship_alpha_3_code:
                            selectedCountry?.iso3alpha,
                        second_citizenship_country_name: selectedCountry?.name,
                    },
                }
            })
            setSelectedSecondCitizenshipCountry(selectedCountry)
        } else {
            setFormData((_) => {
                return {
                    ..._,
                    citizenship: {
                        ..._.citizenship,
                        citizenship_alpha_3_code: selectedCountry?.iso3alpha,
                        citizenship_country_name: selectedCountry?.name,
                    },
                }
            })
            setSelectedFirstCitizenshipCountry(selectedCountry)
        }
    }

    const handleSelectCountry = (countryCode, countryDataFormField) => {
        axios
            .get(`${process.env.REACT_APP_GEONAMES_URL}/countryInfoJSON`, {
                params: {
                    country: countryCode?.toString(),
                    username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                },
            })
            .then((response) => {
                const selectedCountry = response?.data?.geonames[0]
                const selectedCountryName = availableCountries.find(
                    (country) =>
                        (country.countryCode = selectedCountry?.countryCode)
                )?.value
                countryDataFormField === 'birth_address'
                    ? setFormData((prevState) => ({
                          ...prevState,
                          //   citizenship_alpha_3_code: selectedCountry?.isoAlpha3,
                          [countryDataFormField]: {
                              ...prevState[countryDataFormField],
                              country_code: selectedCountry?.countryCode,
                              country: selectedCountryName,
                          },
                      }))
                    : setFormData((prevState) => ({
                          ...prevState,
                          [countryDataFormField]: {
                              ...prevState[countryDataFormField],
                              country_code: selectedCountry?.countryCode,
                              country: selectedCountryName,
                          },
                      }))
                countryDataFormField === 'birth_address'
                    ? setSelectedBirthCountry((_) => {
                          return {
                              value: selectedCountryName,
                              countryCode: selectedCountry?.countryCode,
                          }
                      })
                    : setSelectedResidentialCountry((_) => {
                          return {
                              value: selectedCountryName,
                              countryCode: selectedCountry?.countryCode,
                          }
                      })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleCity = (cityName, countryDataFormField) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureCode: 'P',
                        country: formData?.[countryDataFormField]?.country_code,
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((country) => {
                        cityOptions?.push({
                            value: country?.name,
                            countryCode: country?.countryCode,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName, cityDataFormField) => {
        setFormData((prevState) => ({
            ...prevState,
            // countryCode: selectedCountry?.countryCode,
            [cityDataFormField]: {
                ...prevState[cityDataFormField],
                city: cityName.value,
            },
        }))
        cityDataFormField === 'birth_address'
            ? setSelectedBirthCity((_) => {
                  return cityName
              })
            : setSelectedResidentialCity((_) => {
                  return cityName
              })
    }

    //* Citizenship section
    const handleCitizenRadioButton = (e) => {
        if (e.target.value === 'Srpsko') {
            setIsDualCitizenship(false)
            setIsCitizenChecked(true)
            setSelectedFirstCitizenshipCountry({
                value: `Srbija, SRB`,
                name: 'Srbija',
                iso3alpha: 'SRB',
            })
            setSelectedSecondCitizenshipCountry(null)
        } else if (e.target.value === 'Strano') {
            setIsDualCitizenship(false)
            setIsCitizenChecked(true)
            setSelectedFirstCitizenshipCountry(null)
            setSelectedSecondCitizenshipCountry(null)
        } else if (e.target.value === 'Dvojno') {
            setIsDualCitizenship(true)
            setIsCitizenChecked(true)
            setSelectedFirstCitizenshipCountry(null)
            setSelectedSecondCitizenshipCountry(null)
        }
        setCheckedCitizenValue(e.target.value)

        if (e.target.value === 'Srpsko') {
            setFormData((prev) => {
                return {
                    ...prev,
                    citizenship: {
                        ...prev?.citizenship,
                        citizenship_type: {
                            citizenship_type_name: e.target.value,
                        },
                        citizenship_alpha_3_code: 'SRB',
                        citizenship_country_name: 'Srbija',
                    },
                }
            })
        } else {
            setFormData((prev) => {
                return {
                    ...prev,
                    citizenship: {
                        ...prev?.citizenship,
                        citizenship_type: {
                            citizenship_type_name: e.target.value,
                        },
                        citizenship_alpha_3_code: null,
                        citizenship_country_name: null,
                        second_citizenship_alpha_3_code: null,
                        second_citizenship_country_name: null,
                    },
                }
            })
        }
    }

    const inputRef = useRef(null)

    const addPhotoCitizenship = (e) => {
        const fileReader = new FileReader()
        const file = e.target.files[0]
        const fileSizeKilobytes = file.size / 1024
        if (checkFileSize(fileSizeKilobytes, 2048)) return
        else {
            fileReader.onload = () => {
                setNewCitizenshipImages((prevState) => {
                    return [...prevState, file]
                })
            }
            fileReader.readAsDataURL(file)
        }
    }

    const handleOpenFileInput = () => {
        inputRef.current.click()
    }

    //* Coach Section
    const handleCoachSelect = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            coach_uuid: e.uuid,
        }))
        setCoachProfile((_) => {
            return e
        })
    }

    const getCoachProfile = async (coachUuid) => {
        if (coachUuid) {
            const coach = await getSingleCoach(coachUuid)

            if (coach) {
                setCoachProfile((_) => {
                    return {
                        value: `${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`,
                        uuid: coach?.attributes?.uuid,
                    }
                })
            }
        }
    }

    //* Player Section
    const updateProfile = async () => {
        const { status, legacy_id, uuid, ...rest } = formData

        let dataToSend = removeIdFields(rest)

        if (
            user?.role?.type === 'competitor' ||
            (havePreviousDocuments ? true : newCitizenshipImages?.length > 0)
        ) {
            try {
                const handleUpdatePlayer = await updatePlayer(
                    id ? id : user?.data?.attributes?.uuid,
                    isDraft
                        ? dataToSend
                        : { ...dataToSend, status: { status_name: 'draft' } },
                    newImg
                )

                let handleAddCitizenShipDocuments
                let results

                if (havePreviousDocuments) {
                    if (newCitizenshipImages?.length > 0) {
                        handleAddCitizenShipDocuments =
                            await updatePlayerDocuments(
                                havePreviousDocuments?.uuid,
                                havePreviousDocuments?.player_documents,
                                newCitizenshipImages
                            )
                        results = await Promise.allSettled([
                            handleUpdatePlayer,
                            handleAddCitizenShipDocuments,
                        ])
                    } else {
                        results = await Promise.allSettled([handleUpdatePlayer])
                    }
                } else {
                    handleAddCitizenShipDocuments = await addPlayerDocuments(
                        id ? id : user?.data?.attributes?.uuid,
                        newCitizenshipImages
                    )
                    results = await Promise.allSettled([
                        handleUpdatePlayer,
                        handleAddCitizenShipDocuments,
                    ])
                }

                if (results) {
                    setShowCustomToast({
                        show: true,
                        type: 'success',
                        message: 'Profil uspešno ažuriran.',
                    })
                    setIsEditing
                        ? setIsEditing(false)
                        : navigate(`/takmicari/${id}`)
                    !id && handleRefresh()
                }
            } catch (error) {
                if (
                    error?.response?.data?.error?.message ===
                    'No documents attached.'
                ) {
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Niste dodali ni jedan dokument!',
                    })
                } else if (
                    error?.response?.data?.error?.message?.includes('unique')
                ) {
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Email mora biti jedinstven!',
                    })
                } else {
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške!',
                    })
                }
            }
        } else {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Niste dodali ni jedan dokument!',
            })
        }
    }

    //* Player documents section

    const getPlayerDocumentsHandler = async (playerUuid) => {
        try {
            const data = await getPlayerDocuments(playerUuid)
            if (data) {
                //* Player have documents entered
                const playerDocuments = await Promise.all(
                    data?.attributes?.player_documents?.map(
                        async (mediaResponse) => {
                            try {
                                const file = await convertMediaResponseToFile(
                                    mediaResponse?.file?.data?.attributes
                                )
                                file.previouslyUploaded = true
                                file.previousDate = mediaResponse?.date
                                file.previousCaption = mediaResponse?.caption

                                return file
                            } catch (error) {
                                return null
                            }
                        }
                    )
                )
                if (playerDocuments?.length > 0) {
                    const validPlayerDocuments = playerDocuments.filter(
                        (file) => file !== null
                    )

                    if (validPlayerDocuments.length > 0) {
                        setCitizenshipImgs(validPlayerDocuments)
                        setHavePreviousDocuments(data?.attributes)
                    }
                } else {
                    setHavePreviousDocuments(data?.attributes)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    //* Function for removing unnecessary fields from object

    function removeIdFields(obj) {
        const result = {}

        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = removeIdFields(obj[key])
            } else if (
                key !== 'id' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'locale'
            ) {
                result[key] = obj[key]
            }
        }
        return result
    }

    //* Validation Section
    const validateFields = () => {
        if (
            user?.role?.type === 'competitor' ||
            (formData?.birth_address?.country?.length > 0 &&
                formData?.person_info?.email &&
                isCitizenChecked &&
                (havePreviousDocuments
                    ? true
                    : newCitizenshipImages?.length > 0) &&
                String(formData?.person_info?.birthdate)?.length > 0 &&
                String(formData?.gender?.gender_name)?.length > 0 &&
                formData?.citizenship
                    ?.is_serbian_citizen_internationally_registered !== null &&
                checkedCitizenValue &&
                (checkedCitizenValue === 'Strano'
                    ? formData?.citizenship?.citizenship_alpha_3_code &&
                      formData?.citizenship?.citizenship_country_name
                    : checkedCitizenValue === 'Dvojno'
                    ? formData?.citizenship?.citizenship_alpha_3_code &&
                      formData?.citizenship?.citizenship_country_name &&
                      formData?.citizenship?.second_citizenship_alpha_3_code &&
                      formData?.citizenship?.second_citizenship_country_name
                    : true))
        ) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    const handleCitizenshipValue = (type) => {
        switch (type) {
            case 'Strano':
                setIsCitizenChecked(true)
                setIsDualCitizenship(false)
                setCheckedCitizenValue(type)
                break
            case 'Dvojno':
                setIsCitizenChecked(true)
                setIsDualCitizenship(true)
                setCheckedCitizenValue(type)
                break
            case 'Srpsko':
                setIsCitizenChecked(true)
                setIsDualCitizenship(false)
                setCheckedCitizenValue(type)
                break

            default:
                setIsCitizenChecked(false)
                setIsDualCitizenship(false)
                setCheckedCitizenValue('')
                break
        }
    }

    const handlePlayerData = async (playerUuid) => {
        const data = await getSinglePlayer(playerUuid)

        if (data) {
            const { attributes } = data
            const { updatedAt, createdAt, allowed_competitions, ...rest } =
                attributes
            setFormData((_) => {
                return { ...rest }
            })
            setOriginalEmail((_) => {
                return rest?.person_info?.email
            })
            attributes?.birth_address?.country &&
                setSelectedBirthCountry({
                    value: attributes?.birth_address?.country,
                    countryCode: attributes?.birth_address?.country_code,
                })
            attributes?.residential_address?.country &&
                setSelectedResidentialCountry({
                    value: attributes?.residential_address?.country,
                    countryCode: attributes?.residential_address?.country_code,
                })
            attributes?.birth_address?.city &&
                setSelectedBirthCity({
                    value: attributes?.birth_address?.city,
                    countryCode: attributes?.birth_address?.country_code,
                })
            attributes?.residential_address?.city &&
                setSelectedResidentialCity({
                    value: attributes?.residential_address?.city,
                    countryCode: attributes?.residential_address?.country_code,
                })

            attributes?.citizenship &&
                attributes?.citizenship?.citizenship_type &&
                handleCitizenshipValue(
                    attributes?.citizenship?.citizenship_type
                        ?.citizenship_type_name
                )

            attributes?.citizenship?.citizenship_alpha_3_code &&
                setSelectedFirstCitizenshipCountry({
                    value: `${attributes?.citizenship?.citizenship_country_name}, ${attributes?.citizenship?.citizenship_alpha_3_code}`,
                    name: attributes?.citizenship?.citizenship_country_name,
                    iso3alpha:
                        attributes?.citizenship?.citizenship_alpha_3_code,
                })

            attributes?.citizenship?.second_citizenship_alpha_3_code &&
                setSelectedSecondCitizenshipCountry({
                    value: `${attributes?.citizenship?.second_citizenship_country_name}, ${attributes?.citizenship?.second_citizenship_alpha_3_code}`,
                    name: attributes?.citizenship
                        ?.second_citizenship_country_name,
                    iso3alpha:
                        attributes?.citizenship
                            ?.second_citizenship_alpha_3_code,
                })

            getPlayerDocumentsHandler(attributes?.uuid)
            getCoachProfile(attributes?.coach_uuid)
        }
    }

    //* useEffect section

    useEffect(() => {
        if (user && !id) {
            // Exclude createdAt and updatedAt from user in formData object - the right way
            const { data } = user
            const { attributes } = data
            const { updatedAt, createdAt, ...rest } = attributes

            setFormData((_) => {
                return { ...rest }
            })
            setOriginalEmail((_) => {
                return rest?.person_info?.email
            })
            attributes?.birth_address?.country &&
                setSelectedBirthCountry({
                    value: attributes?.birth_address?.country,
                    countryCode: attributes?.birth_address?.country_code,
                })
            attributes?.residential_address?.country &&
                setSelectedResidentialCountry({
                    value: attributes?.residential_address?.country,
                    countryCode: attributes?.residential_address?.country_code,
                })
            attributes?.birth_address?.city &&
                setSelectedBirthCity({
                    value: attributes?.birth_address?.city,
                    countryCode: attributes?.birth_address?.country_code,
                })
            attributes?.residential_address?.city &&
                setSelectedResidentialCity({
                    value: attributes?.residential_address?.city,
                    countryCode: attributes?.residential_address?.country_code,
                })

            attributes?.citizenship &&
                attributes?.citizenship?.citizenship_type &&
                handleCitizenshipValue(
                    attributes?.citizenship?.citizenship_type
                        ?.citizenship_type_name
                )

            attributes?.citizenship?.citizenship_alpha_3_code &&
                setSelectedFirstCitizenshipCountry({
                    value: `${attributes?.citizenship?.citizenship_country_name}, ${attributes?.citizenship?.citizenship_alpha_3_code}`,
                    name: attributes?.citizenship?.citizenship_country_name,
                    iso3alpha:
                        attributes?.citizenship?.citizenship_alpha_3_code,
                })

            attributes?.citizenship?.second_citizenship_alpha_3_code &&
                setSelectedSecondCitizenshipCountry({
                    value: `${attributes?.citizenship?.second_citizenship_country_name}, ${attributes?.citizenship?.second_citizenship_alpha_3_code}`,
                    name: attributes?.citizenship
                        ?.second_citizenship_country_name,
                    iso3alpha:
                        attributes?.citizenship
                            ?.second_citizenship_alpha_3_code,
                })

            getPlayerDocumentsHandler(attributes?.uuid)
            getCoachProfile(attributes?.coach_uuid)
        } else if (id) {
            handlePlayerData(id)
        }
        handleCodebooks()
    }, [user, id])

    useEffect(() => {
        validateFields()
    }, [
        formData,
        checkedCitizenValue,
        isCitizenChecked,
        isDualCitizenship,
        selectedFirstCitizenshipCountry,
        selectedSecondCitizenshipCountry,
        selectedClub,
        havePreviousDocuments,
        newCitizenshipImages,
    ])

    return (
        <Row className="draft-player-edit-wrapper align-items-center justify-content-center">
            <Col className="d-flex flex-column draft-player-edit-wrapper-inside">
                <Row className="justify-content-center">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex flex-column align-items-center mt-2 mt-md-none"
                    >
                        <UploadImage
                            htmlFor="edit-profile-img"
                            previewData={
                                previewImg
                                    ? previewImg
                                    : formData?.person_info?.picture
                                    ? formData?.person_info?.picture
                                    : disableEdit
                                    ? generateAvatar(
                                          `${formData?.person_info?.given_name} ${formData?.person_info?.family_name}`
                                      )
                                    : null
                            }
                            imageKey="img"
                            disabled={disableEdit}
                        />
                        <input
                            type="file"
                            id="edit-profile-img"
                            name="edit-profile-img"
                            className="d-none invisible"
                            onChange={(e) =>
                                addPhoto(
                                    e,
                                    setNewImg,
                                    setPreviewImg,
                                    checkFileSize
                                )
                            }
                            accept="image/jpg, image/jpeg, image/png"
                            disabled={disableEdit}
                        />

                        {disableEdit ? null : (
                            <p className="small-text">
                                Promeni fotografiju /.jpg 2MB
                            </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="person_info.given_name"
                            name="person_info.given_name"
                            type="text"
                            value={formData?.person_info?.given_name || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState.person_info,
                                        given_name: e.target.value,
                                    },
                                }))
                            }}
                            required
                            label="Ime"
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="person_info.family_name"
                            name="person_info.family_name"
                            type="text"
                            value={formData?.person_info?.family_name || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState.person_info,
                                        family_name: e.target.value,
                                    },
                                }))
                            }}
                            required
                            label="Prezime"
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="person_info.email"
                            name="person_info.email"
                            type="email"
                            value={formData?.person_info?.email || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState.person_info,
                                        email: e.target.value,
                                    },
                                }))
                            }}
                            label="Email"
                            required
                            disabled={disableEdit || !originalEmail}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="person_info.birthdate"
                            type="date"
                            name="person_info.birthdate"
                            label="Datum rođenja"
                            value={formData?.person_info?.birthdate || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState.person_info,
                                        birthdate: e.target.value,
                                    },
                                }))
                            }}
                            required
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <MultiSelectSearch
                            label="Država rođenja"
                            placeholder={
                                formData?.birth_address?.country ||
                                'Država rođenja'
                            }
                            value={selectedBirthCountry}
                            animated
                            options={availableCountries}
                            noOptionsMessage={'Nema rezultata'}
                            onInputChange={(e) =>
                                handleCountry(e, 'birth_address')
                            }
                            onChange={(e) =>
                                handleSelectCountry(
                                    e?.countryCode,
                                    'birth_address'
                                )
                            }
                            isMulti={false}
                            required
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <MultiSelectSearch
                            label={'Grad rođenja'}
                            subLabel={'Država rođenja mora biti izabrana'}
                            placeholder={
                                formData?.birth_address?.city || 'Grad rođenja'
                            }
                            animated
                            options={availableCities}
                            noOptionsMessage={'Nema rezultata'}
                            onInputChange={(e) =>
                                handleCity(e, 'birth_address')
                            }
                            onChange={(e) =>
                                handleSelectCity(e, 'birth_address')
                            }
                            isMulti={false}
                            disabled={
                                !formData?.birth_address?.country || disableEdit
                            }
                            value={selectedBirthCity}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <RadioButton
                            name="Državljanstvo"
                            radioValues={citizenshipTypes}
                            checkedValue={checkedCitizenValue}
                            onChange={(e) => handleCitizenRadioButton(e)}
                            required
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <RadioButton
                            name="Pol"
                            radioValues={genders}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    gender: {
                                        gender_name: e.target.value,
                                    },
                                }))
                            }}
                            checkedValue={formData?.gender?.gender_name || ''}
                            required
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>
                {checkedCitizenValue ? (
                    checkedCitizenValue === 'Strano' ? (
                        <Row className="justify-content-center">
                            <Col xs={12} md={6}>
                                <MultiSelectSearch
                                    label={'Izaberi državljanstvo'}
                                    placeholder={'Izaberi državljanstvo'}
                                    value={selectedFirstCitizenshipCountry}
                                    animated
                                    options={availableCitizenshipCountries}
                                    noOptionsMessage={'Nema rezultata'}
                                    onInputChange={(e) =>
                                        handleCitizenshipCountry(e)
                                    }
                                    onChange={(e) => {
                                        handleSelectCitizenshipCountry(e)
                                    }}
                                    isMulti={false}
                                    required
                                    disabled={disableEdit}
                                />
                            </Col>
                        </Row>
                    ) : checkedCitizenValue === 'Dvojno' ? (
                        <Row>
                            <Col xs={12} md>
                                <MultiSelectSearch
                                    label={'Izaberi državljanstvo'}
                                    placeholder={'Izaberi državljanstvo'}
                                    value={selectedFirstCitizenshipCountry}
                                    animated
                                    options={availableCitizenshipCountries}
                                    noOptionsMessage={'Nema rezultata'}
                                    onInputChange={(e) =>
                                        handleCitizenshipCountry(e)
                                    }
                                    onChange={(e) => {
                                        handleSelectCitizenshipCountry(e)
                                    }}
                                    isMulti={false}
                                    required
                                    disabled={disableEdit}
                                />
                            </Col>
                            <Col xs={12} md>
                                <MultiSelectSearch
                                    label={'Izaberi drugo državljanstvo'}
                                    placeholder={'Izaberi drugo državljanstvo'}
                                    value={selectedSecondCitizenshipCountry}
                                    animated
                                    options={availableCitizenshipCountries}
                                    noOptionsMessage={'Nema rezultata'}
                                    onInputChange={(e) =>
                                        handleCitizenshipCountry(e)
                                    }
                                    onChange={(e) => {
                                        handleSelectCitizenshipCountry(
                                            e,
                                            'second'
                                        )
                                    }}
                                    isMulti={false}
                                    required
                                    disabled={disableEdit}
                                />
                            </Col>
                        </Row>
                    ) : null
                ) : null}
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <h5 className="mb-2">
                            Sliku izvoda iz matične knjige rođenih i/ili slika
                            pasoša <span className="required-asterisk">*</span>
                        </h5>
                        {citizenshipImgs.map((image, index) => (
                            <FileUploadTag key={index} fileName={image?.name} />
                        ))}
                        {newCitizenshipImages.map((image, index) => (
                            <FileUploadTag
                                key={index}
                                fileName={image?.name}
                                deleteFile={() => {
                                    setNewCitizenshipImages((prevState) => {
                                        let filtered = prevState.filter(
                                            (_, indexOfPrev) =>
                                                indexOfPrev !== index
                                        )

                                        return filtered
                                    })
                                    inputRef.current.value = null
                                }}
                            />
                        ))}
                        <label onClick={handleOpenFileInput} className="w-100">
                            <Button
                                type="secondary"
                                className="w-100"
                                disabled={disableEdit}
                            >
                                Uploaduj dokument
                            </Button>
                        </label>
                        <form>
                            <input
                                ref={inputRef}
                                type="file"
                                id="citizenshipImage"
                                name="citizenshipImage"
                                className="d-none invisible"
                                onChange={(e) => addPhotoCitizenship(e)}
                                accept="image/jpg, image/jpeg, image/png, .pdf"
                                disabled={disableEdit}
                            />
                        </form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <RadioButton
                            name="Državljanstvo ovog takmičara je u međunarodnoj evidenciji takmičara zavedeno kao (SRB)"
                            radioValues={['Da', 'Ne']}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    citizenship: {
                                        ...formData?.citizenship,
                                        is_serbian_citizen_internationally_registered:
                                            e.target.value === 'Da'
                                                ? true
                                                : false,
                                    },
                                })
                            }}
                            checkedValue={
                                formData?.citizenship
                                    ?.is_serbian_citizen_internationally_registered ===
                                true
                                    ? 'Da'
                                    : formData?.citizenship
                                          ?.is_serbian_citizen_internationally_registered ===
                                      false
                                    ? 'Ne'
                                    : null || null
                            }
                            required
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0 align-self-end">
                        <RadioButton
                            name="Ruka kojom igra"
                            radioValues={playingHandTypes}
                            onChange={(e) =>
                                setFormData((prevState) => ({
                                    ...prevState,
                                    playing_hand: {
                                        playing_hand_name: e.target.value,
                                    },
                                }))
                            }
                            checkedValue={
                                formData?.playing_hand?.playing_hand_name || ''
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="id"
                            name="father"
                            type="text"
                            value={formData?.fathers_name || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    fathers_name: e.target.value,
                                }))
                            }}
                            label="Otac"
                            placeholder="Ime Prezime"
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="id"
                            name="mother"
                            type="text"
                            value={formData?.mothers_name || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    mothers_name: e.target.value,
                                }))
                            }}
                            label="Majka"
                            placeholder="Ime Prezime"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {/* <Col xs={12} md>
                        <AsyncSelect
                            loadOptions={getClubList}
                            onChange={handleClubSelect}
                            label={'Klub'}
                            value={selectedClub}
                            placeholder={'Pretraži i izaberi klub...'}
                            noOptionsMessage={'Traženi klub ne postoji'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                        />
                    </Col> */}
                    <Col xs={12} md={6} className="">
                        <AsyncSelect
                            loadOptions={getCoachList}
                            label={'Trener'}
                            placeholder={
                                coachProfile
                                    ? coachProfile?.value
                                    : 'Pretraži i izaberi trenera...'
                            }
                            noOptionsMessage={'Traženi trener ne postoji'}
                            closeMenuOnSelect={true}
                            onChange={(e) => handleCoachSelect(e)}
                            isMulti={false}
                            value={coachProfile}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="person_info.jmbg"
                            name="person_info.jmbg"
                            type="text"
                            value={formData?.person_info?.jmbg || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                checkJMBG(e.target.value)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState?.person_info,
                                        jmbg: e.target.value,
                                    },
                                }))
                            }}
                            label="JMBG"
                            minLength={13}
                            maxLength={13}
                            placeholder="JMBG"
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="person_info.phone"
                            name="person_info.phone"
                            type="tel"
                            value={formData?.person_info?.phone || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    person_info: {
                                        ...prevState?.person_info,
                                        phone: e.target.value,
                                    },
                                }))
                            }}
                            label="Kontakt telefon"
                            placeholder="Kontakt telefon"
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="height"
                            name="height"
                            type="tel"
                            label="Visina (cm)"
                            placeholder="Visina"
                            value={formData?.height || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    height:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="weight"
                            name="weight"
                            type="tel"
                            label="Težina (kg)"
                            placeholder="Težina"
                            value={formData?.weight || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    weight:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <MultiSelectSearch
                            label="Država stanovanja"
                            placeholder={
                                formData?.residential_address?.country ||
                                'Država stanovanja'
                            }
                            value={selectedResidentialCountry}
                            animated
                            options={availableCountries}
                            noOptionsMessage={'Nema rezultata'}
                            onInputChange={(e) =>
                                handleCountry(e, 'residential_address')
                            }
                            onChange={(e) =>
                                handleSelectCountry(
                                    e?.countryCode,
                                    'residential_address'
                                )
                            }
                            isMulti={false}
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <MultiSelectSearch
                            label={'Grad stanovanja'}
                            subLabel={'Država stanovanja mora biti izabrana'}
                            placeholder={
                                formData?.residential_address?.city ||
                                'Grad stanovanja'
                            }
                            animated
                            options={availableCities}
                            noOptionsMessage={'Nema rezultata'}
                            onInputChange={(e) =>
                                handleCity(e, 'residential_address')
                            }
                            onChange={(e) =>
                                handleSelectCity(e, 'residential_address')
                            }
                            isMulti={false}
                            disabled={
                                !formData?.residential_address?.country ||
                                disableEdit
                            }
                            value={selectedResidentialCity}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md>
                        <TextField
                            id="residential_address.street"
                            name="residential_address.street"
                            type="text"
                            value={formData?.residential_address?.street || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    residential_address: {
                                        ...prevState.residential_address,
                                        street: e.target.value,
                                    },
                                }))
                            }}
                            label="Ulica i broj"
                            placeholder="Ulica i broj"
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md className="mt-4 mt-md-0">
                        <TextField
                            id="residential_address.postal_code"
                            name="residential_address.postal_code"
                            type="text"
                            value={
                                formData?.residential_address?.postal_code || ''
                            }
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    residential_address: {
                                        ...prevState.residential_address,
                                        postal_code: e.target.value,
                                    },
                                }))
                            }}
                            label="Poštanski broj"
                            placeholder="Poštanski broj"
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <TextField
                            id="eta_rank"
                            name="eta_rank"
                            type="tel"
                            label="ETA14 rang"
                            placeholder="ETA14"
                            value={formData?.eta_14_rank || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    eta_14_rank:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md={6} className="mt-4 mt-md-0">
                        <TextField
                            id="eta_rank_16"
                            name="eta_rank_16"
                            type="tel"
                            label="ETA16 rang"
                            placeholder="ETA16"
                            value={formData?.eta_16_rank || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    eta_16_rank:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={12} md={6} className="mt-4">
                        <TextField
                            id="atp_wta_rank"
                            name="atp_wta_rank"
                            type="tel"
                            label="ATP/WTA rang"
                            placeholder="ATP/WTA"
                            value={formData?.atp_wta_rank || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    atp_wta_rank:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                            disabled={disableEdit}
                        />
                    </Col>

                    <Col xs={12} md={6} className="mt-4">
                        <TextField
                            id="itf_rank"
                            name="itf_rank"
                            type="tel"
                            label="ITF rang"
                            placeholder="ITF"
                            value={formData?.itf_rank || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFormData((prevState) => ({
                                    ...prevState,
                                    itf_rank:
                                        e.target.value === ''
                                            ? null
                                            : e.target.value,
                                }))
                            }}
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>

                <div className="mx-auto">
                    <Button onClick={updateProfile} disabled={disableButton}>
                        Sačuvaj
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default DraftPlayerEdit
