import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../../../../../../../../components/Button'
import { getTournamentClubsParticipantsList } from '../../../../../../../../lib/helpers'
import AsyncSelect from '../../../../../../../../components/AsyncSelect'
import { addParticipantToGroup } from '../../../../../../../../lib/api'

const AddClubToGroupModal = ({
    show,
    onHide,
    groupDetails,
    handleGetGroupParticipants,
    drawToShow,
}) => {
    const [groupData, setGroupData] = useState({})
    const handleAddParticipantToGroup = async () => {
        try {
            const addedParticipant = await addParticipantToGroup(groupData)
            if (addedParticipant) {
                onHide()
                handleGetGroupParticipants()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const loadParticipants = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentClubsParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: groupDetails?.tournament_uuid,
                draw_type_name: drawToShow?.draw_type?.draw_type_name,
            }
        )
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={() => {
                onHide()
                drawToShow?.draw_type?.draw_type_name === 'Round robin' &&
                    setGroupData({}) // Empty entry for async select after closing
            }}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodaj klub u grupu</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <AsyncSelect
                            loadOptions={loadParticipants}
                            onChange={(e) =>
                                setGroupData((prev) => ({
                                    ...prev,
                                    participant_uuid: e.uuid,
                                    tournament_uuid:
                                        groupDetails?.tournament_uuid,
                                    group_number: +groupDetails?.group_number,
                                    is_team_tournament: true,
                                }))
                            }
                            label={'Klub'}
                            placeholder={'Izaberi klub...'}
                            noOptionsMessage={'Nema klubova'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="text"
                    onClick={() => {
                        onHide()
                        drawToShow?.draw_type?.draw_type_name ===
                            'Round robin' && setGroupData({}) // Empty entry for async select after closing
                    }}
                >
                    Odustani
                </Button>
                <Button
                    onClick={() => {
                        handleAddParticipantToGroup()
                        drawToShow?.draw_type?.draw_type_name ===
                            'Round robin' && setGroupData({}) // Empty entry for async select after closing
                    }}
                    disabled={
                        drawToShow?.draw_type?.draw_type_name ===
                            'Round robin' && !groupData?.participant_uuid
                    }
                >
                    Dodaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddClubToGroupModal
