import { Row, Col, Card } from 'react-bootstrap'
import IconInfoCard from '../../../../../../components/IconInfoCard'
import Avatar from '../../../../../../components/Avatar'
import { generateAvatar } from '../../../../../../utils/generateAvatar'
import {
    formatImgUrl,
    handleShowDetail,
    handleShowYesNo,
    formatDate,
    getPlayersNumberForTournament,
} from '../../../../../../lib/helpers'
import FilledCalendar from '../../../../../../assets/svg/FilledCalendar'
import FilledBed from '../../../../../../assets/svg/FilledBed'
import Person from '../../../../../../assets/svg/Person'
import Male from '../../../../../../assets/svg/Male'
import Female from '../../../../../../assets/svg/Female'
import GameController from '../../../../../../assets/svg/GameController'
import Accessibility from '../../../../../../assets/svg/Accessibility'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Wallet from '../../../../../../assets/svg/Wallet'
import FilledTennisBall from '../../../../../../assets/svg/FilledTennisBall'

const InformationTab = ({
    tournamentsAdditionalInformationsData,
    competitionProfileData,
    accommodationData,
    tournamentsEntryPoints,
    activeTab,
}) => {
    const [total, setTotal] = useState({
        singleParticipantsNumber: null,
        doublesParticipantsNumber: null,
    })

    const handleParticipantsNumber = async () => {
        const participantsNumber = await getPlayersNumberForTournament(
            competitionProfileData
        )

        setTotal(participantsNumber)
    }

    useEffect(() => {
        if (activeTab === 'informacije') {
            handleParticipantsNumber()
        }
    }, [competitionProfileData, activeTab])

    return (
        <Row className="mt-3">
            <Col xs={12} md>
                <IconInfoCard
                    className="mb-3"
                    icon={
                        <Avatar
                            img={
                                competitionProfileData?.attributes
                                    ?.club_organizer?.attributes?.logo?.data
                                    ? formatImgUrl(
                                          competitionProfileData?.attributes
                                              ?.club_organizer?.attributes?.logo
                                              ?.data?.attributes?.url
                                      )
                                    : generateAvatar(
                                          competitionProfileData?.attributes
                                              ?.club_organizer?.attributes?.name
                                      )
                            }
                            alt={`Logo kluba`}
                        />
                    }
                    title={
                        competitionProfileData?.attributes?.club_organizer
                            ?.attributes?.name
                    }
                    subtitle="Organizator"
                >
                    <p>
                        <b>Adresa: </b>
                        {competitionProfileData?.attributes?.club_organizer
                            ?.attributes?.address
                            ? competitionProfileData?.attributes?.club_organizer
                                  ?.attributes?.address?.street +
                              ', ' +
                              competitionProfileData?.attributes?.club_organizer
                                  ?.attributes?.address?.city
                            : 'Nema informacija'}
                    </p>
                </IconInfoCard>

                <IconInfoCard
                    className="mb-3"
                    icon={<FilledCalendar />}
                    title="Važni datumi"
                >
                    {handleShowDetail(
                        'Početak takmičenja',
                        competitionProfileData?.attributes?.start_date
                            ? formatDate(
                                  competitionProfileData?.attributes?.start_date
                              )
                            : ''
                    )}

                    {handleShowDetail(
                        'Kraj takmičenja',
                        competitionProfileData?.attributes?.end_date
                            ? formatDate(
                                  competitionProfileData?.attributes?.end_date
                              )
                            : ''
                    )}

                    {handleShowDetail(
                        'Krajnji datum za prijavu',
                        competitionProfileData?.attributes
                            ?.application_deadline_date
                            ? formatDate(
                                  competitionProfileData?.attributes
                                      ?.application_deadline_date
                              )
                            : ''
                    )}

                    {handleShowDetail(
                        'Krajnji datum za odjavu',
                        competitionProfileData?.attributes
                            ?.checkout_deadline_date
                            ? formatDate(
                                  competitionProfileData?.attributes
                                      ?.checkout_deadline_date
                              )
                            : ''
                    )}

                    {handleShowDetail(
                        'Godina turnira',
                        competitionProfileData?.attributes
                            ?.next_year_tournament_date
                            ? new Date(
                                  competitionProfileData?.attributes?.next_year_tournament_date
                              )?.getFullYear()
                            : ''
                    )}
                </IconInfoCard>

                <IconInfoCard
                    className="mb-3"
                    icon={<FilledBed />}
                    title="Smeštaj"
                >
                    {handleShowDetail(
                        'Adresa',
                        accommodationData?.attributes?.address?.street || ''
                    )}
                    {handleShowDetail(
                        'Grad',
                        accommodationData?.attributes?.address?.city || ''
                    )}
                    {handleShowDetail(
                        'Telefon',
                        accommodationData?.attributes?.phone || ''
                    )}

                    {handleShowDetail(
                        'Email',
                        accommodationData?.attributes?.email || ''
                    )}

                    {handleShowDetail(
                        'Napomena',
                        accommodationData?.attributes
                            ?.additional_informations || ''
                    )}
                </IconInfoCard>

                {tournamentsAdditionalInformationsData?.attributes
                    ?.registration_fee && (
                    <IconInfoCard
                        className="mb-3"
                        icon={<Wallet />}
                        title={
                            `${tournamentsAdditionalInformationsData?.attributes?.registration_fee} RSD` ||
                            ''
                        }
                        subtitle={'Kotizacija'}
                    ></IconInfoCard>
                )}

                <IconInfoCard
                    className="mb-3"
                    icon={<FilledTennisBall />}
                    title={
                        tournamentsAdditionalInformationsData?.attributes
                            ?.ball_brend || 'Nema informacija'
                    }
                    subtitle={'Lopte'}
                >
                    {handleShowDetail(
                        'Napomena',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.additional_details
                    )}
                </IconInfoCard>

                {competitionProfileData?.attributes?.staff?.map(
                    (person, index) =>
                        person?.given_name && (
                            <IconInfoCard
                                key={index}
                                className="mb-3"
                                icon={<Person />}
                                title={
                                    person?.given_name +
                                    ' ' +
                                    person?.family_name
                                }
                                subtitle={
                                    person?.title
                                        ? person?.title
                                        : 'Nema informacija'
                                }
                                isImage={false}
                            >
                                {handleShowDetail('Telefon', person?.phone)}

                                {handleShowDetail('Email', person?.email)}
                            </IconInfoCard>
                        )
                )}
            </Col>

            <Col xs={12} md>
                <IconInfoCard
                    className="mb-3"
                    icon={<Person />}
                    title={
                        competitionProfileData?.attributes?.tournament_type
                            ?.tournament_type_name || 'Nema informacija'
                    }
                    subtitle={'Tip takmičenja'}
                >
                    <Link
                        to={`/takmicenja/podesavanje-takmicenja/${tournamentsEntryPoints?.attributes?.uuid}`}
                        className="link"
                    >
                        {handleShowDetail(
                            'Šablon bodovanja',
                            tournamentsEntryPoints?.attributes
                                ?.entry_points_name
                        )}
                    </Link>

                    {handleShowDetail(
                        'Region',
                        competitionProfileData?.attributes?.region?.region_name
                    )}
                </IconInfoCard>

                <IconInfoCard
                    className="mb-3"
                    icon={<GameController />}
                    title={'Informacije o takmičenju'}
                    isImage={false}
                >
                    {handleShowDetail(
                        'Tip takmičenja',
                        competitionProfileData?.attributes?.tournament_type
                            ?.tournament_type_name
                    )}
                    {handleShowDetail(
                        'Podloga',
                        competitionProfileData?.attributes?.court_type
                            ?.court_type_name
                    )}
                    {handleShowDetail(
                        'Tip organizovanja takmičenja',
                        competitionProfileData?.attributes
                            ?.tournament_organisation_type
                            ?.tournament_organisation_type_name
                    )}
                    {handleShowDetail(
                        'Konkurencija',
                        competitionProfileData?.attributes?.players_competition
                            ?.players_competition_name
                    )}
                    {handleShowDetail(
                        'Takmičarska legitimacija',
                        competitionProfileData?.attributes?.players_competition
                            ?.players_competition_subcategory_name
                    )}
                    {handleShowDetail(
                        'Format',
                        competitionProfileData?.attributes?.tournament_format
                            ?.tournament_format_name
                    )}

                    {handleShowDetail(
                        'Kategorija turnira',
                        competitionProfileData?.attributes?.tournament_category
                            ?.tournament_category_name
                    )}

                    {handleShowDetail(
                        'Potkategorija turnira',
                        competitionProfileData?.attributes?.tournament_category
                            ?.tournament_subcategory_name
                    )}

                    {handleShowDetail(
                        'Očekivani broj takmičara',
                        competitionProfileData?.attributes
                            ?.participants_expected_number
                    )}
                    {handleShowDetail(
                        'Prijavljeni broj takmičara',
                        total?.singleParticipantsNumber || ''
                    )}

                    {handleShowDetail(
                        'Očekivani broj dubl parova',
                        competitionProfileData?.attributes
                            ?.doubles_pairs_expected_number
                    )}
                    {handleShowDetail(
                        'Prijavljeni broj dubl parova',
                        total?.doublesParticipantsNumber
                            ? total?.doublesParticipantsNumber
                            : ''
                    )}

                    {handleShowDetail(
                        'Broj takmičara u kvalifikacijama',
                        competitionProfileData?.attributes
                            ?.qualifications_participants_expected_number
                    )}

                    {handleShowDetail(
                        'Broj kvalifikanata',
                        competitionProfileData?.attributes?.number_of_qualified
                    )}

                    {handleShowDetail(
                        'Broj specijalnih pozivnica (WC)',
                        competitionProfileData?.attributes?.wc_number
                    )}

                    {handleShowDetail(
                        'Broj specijalnih pozivnica (WCQ)',
                        competitionProfileData?.attributes
                            ?.wc_qualification_number
                    )}
                </IconInfoCard>

                <IconInfoCard
                    className="my-3"
                    icon={<Accessibility />}
                    title="Ograničenja"
                >
                    {handleShowDetail(
                        'Stranci',
                        handleShowYesNo(
                            tournamentsAdditionalInformationsData?.attributes
                                ?.are_foreigners_allowed
                        )
                    )}

                    {handleShowDetail(
                        'Broj stranaca koji se mogu prijaviti',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.max_number_of_registered_strangers
                    )}

                    {handleShowDetail(
                        'Broj stranaca po meču',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.max_number_of_strangers_per_match
                    )}

                    {handleShowDetail(
                        'Samo iz regiona',
                        handleShowYesNo(
                            tournamentsAdditionalInformationsData?.attributes
                                ?.is_from_region_only
                        )
                    )}

                    {handleShowDetail(
                        'Broj pozajmica',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.max_number_of_loans
                    )}

                    {handleShowDetail(
                        'Broj pozajmica po meču',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.max_number_of_loans_per_match
                    )}

                    {handleShowDetail(
                        'Ne mogu učestvovati (sa TSS liste)',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.tss_rank_prohibition
                    )}

                    {handleShowDetail(
                        'Ne mogu učestvovati (sa ITF liste)',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.itf_rank_prohibition
                    )}

                    {handleShowDetail(
                        'Ne mogu učestvovati (sa ETA liste)',
                        tournamentsAdditionalInformationsData?.attributes
                            ?.eta_rank_prohibition
                    )}
                </IconInfoCard>

                {competitionProfileData?.attributes?.genders?.map(
                    (gender, index) => (
                        <IconInfoCard
                            key={index}
                            className="mb-3"
                            icon={
                                gender?.gender_name === 'Muški' ? (
                                    <Male />
                                ) : (
                                    <Female />
                                )
                            }
                            title={gender ? gender?.gender_name : 'Pol'}
                            subtitle={gender ? 'Pol' : 'Nema informacija'}
                        ></IconInfoCard>
                    )
                )}
            </Col>
        </Row>
    )
}

export default InformationTab
