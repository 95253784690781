import jsPDF from 'jspdf'
import {
    getRoundRobinDrawData,
    getSingleReferee,
    getTournamentsReferees,
} from '../../../../../../../lib/api'
import { formatDate } from '../../../../../../../lib/helpers'
import { font } from '../../../font'

const fetchTournamentAdminReferee = async (tournamentUuid) => {
    const refereesResponse = await getTournamentsReferees(tournamentUuid, {
        admin_referee: true,
    })

    if (refereesResponse && refereesResponse?.length > 0) {
        let refereeInfo = await getSingleReferee(
            refereesResponse[0]?.attributes?.referee_uuid
        )

        if (!refereeInfo) return null
        return refereeInfo
    } else {
        return null
    }
}

const addFooters = (doc, marginLeft) => {
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(
            `Datum i vreme štampanja: ${formatDate(
                new Date()
            )} ${new Date().toLocaleTimeString()}`,
            doc.internal.pageSize.width - marginLeft,
            doc.internal.pageSize.height - 10,
            { align: 'right' }
        )
    }
}

const getRoundRobinDrawInfo = async (compData, drawData) => {
    const data = await getRoundRobinDrawData({
        tournamentUuid: compData?.attributes?.uuid,
        gender: drawData?.gender?.gender_name,
    })

    return data
}

const createDoc = async (data, orientation, unit, size, font) => {
    const { competitionProfileData, drawInfo } = data

    let adminRefereeInfo = await fetchTournamentAdminReferee(
        competitionProfileData?.attributes?.uuid
    )

    const roundRobinDrawData = await getRoundRobinDrawInfo(
        competitionProfileData,
        drawInfo
    )

	const isTeamTournament =
        competitionProfileData?.attributes?.tournament_type
            ?.tournament_type_name === 'Ekipno'

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(15)

    // Za svaku grupu
    for (let i = 0; i < roundRobinDrawData?.length; i++) {
        const groupData = roundRobinDrawData[i]

        const title = `${competitionProfileData?.attributes?.name}`
        doc.text(title, marginLeft, 40)

        const textWidth = doc.getTextDimensions(
            isTeamTournament
                ? `Grupa: ${groupData?.group_info[0]?.group_number}`
                : `Pol: ${drawInfo?.gender?.gender_name} | Grupa: ${groupData?.group_info[0]?.group_number}`,
            { fontSize: 15 }
        ).w
        const pageWidth = doc.internal.pageSize.getWidth()
        const textX = pageWidth - textWidth - marginLeft
        doc.text(
            isTeamTournament
                ? `Grupa: ${groupData?.group_info[0]?.group_number}`
                : `Pol: ${drawInfo?.gender?.gender_name} | Grupa: ${groupData?.group_info[0]?.group_number}`,
            textX,
            40
        )

        let generalInfo = [
            [
                formatDate(competitionProfileData?.attributes?.start_date) ??
                    'Nema informacije',
                competitionProfileData?.attributes?.club_organizer?.attributes
                    ?.name ?? 'Nema informacije',
                competitionProfileData?.attributes?.tournament_category
                    ?.tournament_category_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.tournament_category?.tournament_category_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.tournament_category
                              ?.tournament_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                competitionProfileData?.attributes?.players_competition
                    ?.players_competition_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.players_competition?.players_competition_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.players_competition
                              ?.players_competition_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                adminRefereeInfo
                    ? `${
                          adminRefereeInfo?.attributes?.person_info
                              ?.given_name ?? ''
                      } ${
                          adminRefereeInfo?.attributes?.person_info
                              ?.family_name ?? ''
                      }`
                    : 'Nema informacije',
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [
                [
                    'Datum',
                    'Grad, klub',
                    'Kategorija',
                    'Konkurencija',
                    'Vrhovni sudija',
                ],
            ],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(generalInfoContent)

        const tableData = []

        // Za svakog takmicara u grupi
        for (let k = 0; k < groupData?.group_info?.length; k++) {
            const participantInfo = groupData?.group_info[k]

            let rowData = []

            if (isTeamTournament) {
                rowData = [`${k + 1}.`, '', '']

                rowData[1] = `${participantInfo?.club_info?.name} (${participantInfo?.club_info?.short_name})`
                rowData[2] = `${participantInfo?.club_stats?.winsNumber}`
            } else {
                rowData = [
                    `${participantInfo?.group_rank}.`,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ]

                rowData[1] = `${
                    participantInfo?.player_info?.given_name +
                    ' ' +
                    participantInfo?.player_info?.family_name
                }`
                rowData[2] = `${participantInfo?.player_stats?.winsNumber}`
                rowData[3] = `${participantInfo?.player_stats?.setRatio}`
                rowData[4] = `${participantInfo?.player_stats?.setsWonNumber}`
                rowData[5] = `${participantInfo?.player_stats?.gameRatio}`
                rowData[6] = `${participantInfo?.player_stats?.gamesWonNumber}`
            }

            // Dodavanje reda u tabelu
            tableData.push(rowData)
        }

        const numberOfRemainingCells = isTeamTournament ? 2 : 6

        // Dodavanje tabele ucesnika za tu grupu
        doc.autoTable({
            head: isTeamTournament
                ? [['Pozicija', 'Naziv kluba', 'Pobede']]
                : [
                      [
                          'Pozicija',
                          'Ime i prezime',
                          'Pobede',
                          'Set količnik',
                          'Osvojeni setovi',
                          'Gem količnik',
                          'Osvojeni gemovi',
                      ],
                  ],
            body: tableData,
            startY: doc.lastAutoTable.finalY,
            styles: {
                font: 'WorkSans',
                halign: 'center',
                fontSize: 7,
            },
            headStyles: {
                lineWidth: 0.2,
                valign: 'middle',
            },
            columnStyles: {
                0: { cellWidth: 50, valign: 'middle', lineWidth: 0.2 },
                ...[...Array(numberOfRemainingCells).keys()].reduce(
                    (acc, index) => {
                        acc[index + 1] = {
                            cellWidth:
                                (doc.internal.pageSize.width -
                                    50 -
                                    marginLeft -
                                    marginLeft) /
                                numberOfRemainingCells,
                            lineWidth: 0.2,
                            valign: 'middle',
                        }
                        return acc
                    },
                    {}
                ),
            },
            rowPageBreak: 'avoid',
        })

        const matchesData = []

        let currentY = doc.lastAutoTable.finalY

        const matchesTitle = `Mečevi`
        doc.text(matchesTitle, marginLeft, doc.lastAutoTable.finalY + 40)
        const matchesTitleHeight = doc.getTextDimensions(`Mečevi`).h
        currentY += matchesTitleHeight

        // Za svaki mec u grupi
        for (let j = 0; j < groupData?.group_matches?.length; j++) {
            const matchInfo = groupData?.group_matches[j]
            const rowData = [`${j + 1}.`, '', '', '']

            rowData[1] = !isTeamTournament
                ? `${
                      matchInfo?.side_1_player_info?.given_name +
                      ' ' +
                      matchInfo?.side_1_player_info?.family_name
                  }${matchInfo?.side_1_result?.is_wo ? ' (WO) ' : ''}${
                      matchInfo?.side_1_result?.is_ret ? ' (RET) ' : ''
                  }\nvs\n${
                      matchInfo?.side_2_player_info?.given_name +
                      ' ' +
                      matchInfo?.side_2_player_info?.family_name
                  }${matchInfo?.side_2_result?.is_wo ? ' (WO) ' : ''}${
                      matchInfo?.side_2_result?.is_ret ? ' (RET) ' : ''
                  }`
                : `${matchInfo?.side_1_club_info?.name} (${matchInfo?.side_1_club_info?.short_name})\nvs\n${matchInfo?.side_2_club_info?.name} (${matchInfo?.side_2_club_info?.short_name})`
            rowData[2] = matchInfo?.side_1_result?.result
                ?.map(
                    (res, index) =>
                        `${res}-${matchInfo?.side_2_result?.result?.[index]}`
                )
                .join(', ')
            rowData[3] = matchInfo?.loser_participant_uuid
                ? matchInfo?.loser_participant_uuid === matchInfo?.side_1_uuid
                    ? isTeamTournament
                        ? matchInfo?.side_2_club_info?.name
                        : `${
                              matchInfo?.side_2_player_info?.given_name +
                              ' ' +
                              matchInfo?.side_2_player_info?.family_name
                          }`
                    : isTeamTournament
                    ? matchInfo?.side_1_club_info?.name
                    : `${
                          matchInfo?.side_1_player_info?.given_name +
                          ' ' +
                          matchInfo?.side_1_player_info?.family_name
                      }`
                : ''

            // Dodavanje reda u tabelu
            matchesData.push(rowData)
        }

        // Dodavanje meceva u grupi
        doc.autoTable({
            head: [['', 'Meč', 'Rezultat', 'Pobednik']],
            body: matchesData,
            startY: currentY + 40,
            styles: {
                font: 'WorkSans',
                halign: 'center',
                fontSize: 7,
            },
            headStyles: {
                lineWidth: 0.2,
                valign: 'middle',
            },
            columnStyles: {
                0: { cellWidth: 50, valign: 'middle', lineWidth: 0.2 },
                ...[...Array(3).keys()].reduce((acc, index) => {
                    acc[index + 1] = {
                        cellWidth:
                            (doc.internal.pageSize.width -
                                50 -
                                marginLeft -
                                marginLeft) /
                            3,
                        lineWidth: 0.2,
                        valign: 'middle',
                    }
                    return acc
                }, {}),
            },
            rowPageBreak: 'avoid',
        })

        //Dodavanje nove stranice za svaki novi datum, osim za posljednji datum
        if (i !== roundRobinDrawData?.length - 1) {
            doc.addPage()
        }
    }

    // const title = `${competitionProfileData?.attributes?.name}`
    // doc.text(title, marginLeft, 40)

    // const textWidth = doc.getTextDimensions(`Pobednik`, { fontSize: 15 }).w
    // const pageWidth = doc.internal.pageSize.getWidth()
    // const textX = pageWidth - textWidth - marginLeft
    // doc.text(`Pobednik`, textX, 40)

    addFooters(doc, marginLeft)

    return doc
}

export const handleExportDrawRoundRobinPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    doc.save(
        `${data?.competitionProfileData?.attributes?.name}-RoundRobin-ŽREB.pdf`
    )
}

export const handlePrintDrawRoundRobinPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}
