import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchInput from '../../../components/SearchInput'
import FinishedTournaments from './Finished'
import UpcomingTournamets from './Upcoming'
import CompetitionFiltersCanvas from '../../../components/CompetitionFiltersCanvas'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import { useAppContext } from '../../../lib/contextLib'
import {
    getComingCompetitions,
    getFinishedCompetitions,
    debounce,
} from '../../../lib/helpers'

import './styles.scss'

const searchInputID = `searchInput-competitions`

const AllTournaments = () => {
    const { user, setIsLoading } = useAppContext()
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const initialFilters = {
        player_birthdate:
            user?.data?.attributes?.person_info?.birthdate ?? '1970-01-01',
        tournament_type: 'Individualno',
        genderList: user?.data?.attributes?.gender?.gender_name ?? null,
        region: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
        allowed_competitions: user?.data?.attributes?.allowed_competitions,
    }

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [filterParams, setFilterParams] = useState(initialFilters)

    const getComingCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        if (!user) return
        try {
            setIsLoading(true)

            const { updatedTournaments, pgCount, totalCount, page } =
                await getComingCompetitions(
                    currentPage,
                    {
                        ...filterParams,
                        player_birthdate:
                            user?.data?.attributes?.person_info?.birthdate ??
                            '1970-01-01',
                        tournament_type: 'Individualno',
                        genderList:
                            user?.data?.attributes?.gender?.gender_name ?? null,
                        allowed_competitions:
                            user?.data?.attributes?.allowed_competitions,
                    },
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setComingCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getFinishedCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        if (!user) return
        try {
            setIsLoading(true)
            const { updatedTournaments, pgCount, totalCount, page } =
                await getFinishedCompetitions(
                    currentPage,
                    {
                        ...filterParams,
                        player_birthdate:
                            user?.data?.attributes?.person_info?.birthdate ??
                            '1970-01-01',
                        tournament_type: 'Individualno',
                        genderList:
                            user?.data?.attributes?.gender?.gender_name ?? null,
                    },
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setFinishedCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitionsWrapper(1, {})
            : getFinishedCompetitionsWrapper(1, {})
    }

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitionsWrapper(1, filterParams)
            : await getFinishedCompetitionsWrapper(1, filterParams)
    }, 1000)

    const handleTabState = (selectedTab) => {
        switch (selectedTab) {
            case 'nadolazeca':
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            case 'zavrsena':
                return (
                    <FinishedTournaments
                        tournaments={finishedCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            default:
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
        }
    }

    const handleTabSelect = (tab) => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        setCurrentPage(1)
        setSelectedTab(tab)
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitionsWrapper(currentPage, filterParams)
            : getFinishedCompetitionsWrapper(currentPage, filterParams)
    }, [currentPage, selectedTab, rowsPerPage, user])

    return (
        <Row className="tournaments-wrapper position-relative">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Takmičenja
                                </h2>
                                <p className="d-block d-md-none">Takmičenja</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/takmicar/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičenja
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={7} className="offset-md-1">
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    id={searchInputID}
                                    placeholder="Pronađi takmičenje po imenu"
                                    onChange={(e) =>
                                        updateDebounceText(e.target.value)
                                    }
                                />
                                <CompetitionFiltersCanvas
                                    filterParams={filterParams}
                                    setFilterParams={setFilterParams}
                                    getComingCompetitions={
                                        getComingCompetitionsWrapper
                                    }
                                    getFinishedCompetitions={
                                        getFinishedCompetitionsWrapper
                                    }
                                    selectedTab={selectedTab}
                                    handleDisableFiltering={
                                        handleDisableFiltering
                                    }
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Col className="bg-white">
                            <Tabs
                                id="competition-selection"
                                defaultActiveKey={selectedTab}
                                activeKey={selectedTab}
                                onSelect={(tab) => {
                                    setCurrentPage(1)
                                    setPageCount(1)
                                    document.getElementById(
                                        searchInputID
                                    ).value = ''
                                    handleDisableFiltering()
                                    handleTabSelect(tab)
                                }}
                            >
                                <Tab
                                    key="nadolazeca"
                                    eventKey="nadolazeca"
                                    title={<p>Nadolazeća</p>}
                                    tabClassName="d-flex"
                                ></Tab>
                                <Tab
                                    key="zavrsena"
                                    eventKey="zavrsena"
                                    title={<p>Završena</p>}
                                    tabClassName="d-flex"
                                ></Tab>
                            </Tabs>
                        </Col>
                        {handleTabState(selectedTab)}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AllTournaments
