import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import AsyncSelect from '../../../../../components/AsyncSelect'
import Checkbox from '../../../../../components/Checkbox'
import Dropdown from '../../../../../components/Dropdown'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import { getSingleClub, getSingleEntryPoints } from '../../../../../lib/api'
import {
    getRefereesList,
    numbersOnly,
    handleDrawSizeBasedOnGroupNumber,
} from '../../../../../lib/helpers'

const StepOne = ({
    form,
    setForm,
    tournament_competitions,
    tournament_types,
    getClubList,
    individual_tournament_categories,
    team_tournament_competitions,
    getEntryPointsList,
    refereesForm,
    setRefeesForm,
    regions,
    courtTypes,
}) => {
    const participantsNumberArray = [4, 8, 16, 32, 48, 64, 128]

    const [selectedClub, setSelectedClub] = useState()
    const [selectedEntryPoints, setSelectedEntryPoints] = useState()
    const [tournamentHasQualifications, setTournamentHasQualifications] =
        useState(false)
    const [tournamentHasRounRobin, setTournamentHasRounRobin] = useState(false)
    const [tournamentHasGrandPrix, setTournamentHasGrandPrix] = useState(false)

    const handleSelectedClub = async () => {
        if (form?.club_organizer_uuid) {
            const club = await getSingleClub(form?.club_organizer_uuid)
            if (club) {
                setSelectedClub(club)
            }
        }
    }

    const handleSelectedEntryPoints = async () => {
        if (form?.entry_points_uuid) {
            const entryPoints = await getSingleEntryPoints(
                form?.entry_points_uuid
            )
            if (entryPoints) {
                setSelectedEntryPoints(entryPoints)
            }
        }
    }

    useEffect(() => {
        if (form?.uuid) {
            handleSelectedClub()
            handleSelectedEntryPoints()

            if (
                form?.qualifications_participants_expected_number ||
                form?.number_of_qualified ||
                form?.wc_qualification_number
            ) {
                setTournamentHasQualifications(true)
            }

            if (form?.tournament_have_grand_prix) {
                setTournamentHasGrandPrix(true)
            }

            if (form?.tournament_have_round_roubin) {
                setTournamentHasRounRobin(true)
            }
        }
    }, [form])

    return (
        <Row className="create-new-competition-form-wrapper step-one">
            <Col md={12} className="mt-2">
                <RadioButton
                    required
                    name="Tip"
                    radioValues={tournament_types}
                    className="type-radio-buttons-wrapper"
                    checkedValue={
                        form?.tournament_type?.tournament_type_name || ''
                    }
                    otherValuesDisabled={true}
                />
            </Col>

            <Col md={12} className="mt-2">
                <Dropdown
                    dropdownLinks={courtTypes}
                    placeholder="Tip"
                    value={form?.court_type?.court_type_name || ''}
                    handleSelect={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            court_type: {
                                court_type_name: e,
                            },
                        }))
                    }
                    label={'Podloga'}
                />
            </Col>
            <Col md={12} className="mt-2">
                <AsyncSelect
                    loadOptions={getRefereesList}
                    onChange={(e) =>
                        setRefeesForm((prevState) => ({
                            ...prevState,
                            referee_admin: {
                                uuid: prevState?.referee_admin?.uuid,
                                tournament_uuid: form?.uuid,
                                referee_uuid: e?.uuid,
                                referee_type: {
                                    referee_type_name: 'Vrhovni sudija',
                                },
                            },
                        }))
                    }
                    label={'Vrhovni sudija'}
                    placeholder={
                        refereesForm?.referee_admin?.referee?.person_info
                            ?.given_name
                            ? refereesForm?.referee_admin?.referee?.person_info
                                  ?.given_name +
                              ' ' +
                              refereesForm?.referee_admin?.referee?.person_info
                                  ?.family_name
                            : 'Pretraži i izaberi sudiju...'
                    }
                    noOptionsMessage={'Traženi sudija ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                />
            </Col>

            {form?.tournament_type?.tournament_type_name === 'Ekipno' && (
                <Col md={12} className="mt-2">
                    <AsyncSelect
                        loadOptions={getRefereesList}
                        onChange={(e) =>
                            setRefeesForm((prevState) => ({
                                ...prevState,
                                tournament_leader: {
                                    uuid: prevState?.tournament_leader?.uuid,
                                    tournament_uuid: form?.uuid,
                                    referee_uuid: e?.uuid,
                                    referee_type: {
                                        referee_type_name: 'Voditelj turnira',
                                    },
                                },
                            }))
                        }
                        label={'Voditelj turnira'}
                        placeholder={
                            refereesForm?.tournament_leader?.referee
                                ?.person_info?.given_name
                                ? refereesForm?.tournament_leader?.referee
                                      ?.person_info?.given_name +
                                  ' ' +
                                  refereesForm?.tournament_leader?.referee
                                      ?.person_info?.family_name
                                : 'Pretraži i izaberi sudiju...'
                        }
                        noOptionsMessage={'Traženi sudija ne postoji'}
                        closeMenuOnSelect={true}
                        isMulti={false}
                    />
                </Col>
            )}

            <Col md={12} className="mt-3">
                <TextField
                    required
                    label="Naziv takmičenja"
                    placeholder="Naziv takmičenja"
                    id="tournament-name"
                    name="name"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                        }))
                    }}
                    value={form?.name || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <Dropdown
                    label={'Region'}
                    value={form?.region?.region_name || ''}
                    placeholder="Region"
                    handleSelect={(e) =>
                        setForm((prev) => ({
                            ...prev,
                            region: {
                                region_name: e === 'Odselektuj' ? '' : e,
                            },
                        }))
                    }
                    dropdownLinks={
                        regions
                            ? regions
                                  .filter((region) => region !== 'TSS')
                                  .reduce(
                                      (accumulator, region) => [
                                          ...accumulator,
                                          region,
                                      ],
                                      form?.region?.region_name
                                          ? ['Odselektuj']
                                          : []
                                  )
                            : []
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <Dropdown
                    label={'Tip organizovanja takmičenja'}
                    placeholder="Izaberi tip organizovanja..."
                    dropdownLinks={['Međunarodni', 'Domaći', 'Regionalni']}
                    handleSelect={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            tournament_organisation_type: {
                                tournament_organisation_type_name: e,
                            },
                        }))
                    }
                    value={
                        form?.tournament_organisation_type
                            ?.tournament_organisation_type_name
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <AsyncSelect
                    loadOptions={getEntryPointsList}
                    onChange={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            entry_points_uuid: e.uuid,
                        }))
                    }
                    label={'Šablon bodovanja'}
                    placeholder={
                        selectedEntryPoints
                            ? selectedEntryPoints?.attributes?.entry_points_name
                            : 'Izaberi šablon bodovanja...'
                    }
                    noOptionsMessage={'Traženi šablon ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                />
            </Col>
            <Col md={12} className="mt-3">
                <AsyncSelect
                    loadOptions={getClubList}
                    onChange={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            club_organizer_uuid: e.uuid,
                        }))
                    }
                    label={'Klub organizator/domaćin'}
                    placeholder={
                        selectedClub
                            ? selectedClub?.attributes?.name
                            : 'Pretraži i izaberi klub...'
                    }
                    noOptionsMessage={'Traženi klub ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    required
                />
            </Col>

            <Col md={12} className="mt-3">
                <Dropdown
                    required
                    label={'Konkurencija'}
                    placeholder="Izaberi konkurenciju..."
                    dropdownLinks={
                        form?.tournament_type?.tournament_type_name ===
                        'Individualno'
                            ? tournament_competitions
                            : team_tournament_competitions
                    }
                    handleSelect={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            players_competition: {
                                players_competition_name: e,
                            },
                        }))
                    }
                    value={
                        form?.players_competition?.players_competition_name ||
                        ''
                    }
                />
            </Col>

            {form?.players_competition?.players_competition_name === '10s' && (
                <Col md={12} className="mt-3">
                    <Dropdown
                        required
                        label={'Vrsta takmičarske legitimacije'}
                        placeholder="Izaberi takmičarsku legitimaciju..."
                        dropdownLinks={['Crveni', 'Narandžasti', 'Zeleni']}
                        handleSelect={(e) =>
                            setForm((prevState) => ({
                                ...prevState,
                                players_competition: {
                                    ...prevState.players_competition,
                                    players_competition_subcategory_name: e,
                                },
                            }))
                        }
                        value={
                            form?.players_competition
                                ?.players_competition_subcategory_name || ''
                        }
                    />
                </Col>
            )}

            {form?.tournament_type?.tournament_type_name === 'Individualno' && (
                <Col md={12} className="mt-3">
                    <Dropdown
                        required
                        label={'Kategorija'}
                        placeholder="Izaberi kategoriju..."
                        dropdownLinks={individual_tournament_categories}
                        handleSelect={(e) =>
                            setForm((prevState) => ({
                                ...prevState,
                                participants_expected_number: null,
                                qualifications_participants_expected_number:
                                    null,
                                number_of_qualified: null,
                                tournament_category: {
                                    tournament_category_name: e,
                                },
                            }))
                        }
                        value={
                            form?.tournament_category
                                ?.tournament_category_name || ''
                        }
                    />
                </Col>
            )}

            {!tournamentHasRounRobin && !tournamentHasGrandPrix && (
                <Col md={12} className="mt-3">
                    <Dropdown
                        label={'Predviđeni broj učesnika glavnog turnira'}
                        placeholder="Izaberi predviđeni broj učesnika..."
                        dropdownLinks={participantsNumberArray}
                        handleSelect={(e) =>
                            setForm((prevState) => ({
                                ...prevState,
                                participants_expected_number: +e,
                            }))
                        }
                        value={form?.participants_expected_number || ''}
                    />
                </Col>
            )}

            <Col md={12} className="mt-3">
                <TextField
                    label="Broj pozivnica za glavni turnir (WC)"
                    placeholder="Broj pozivnica za glavni turnir (WC)"
                    id="number-of-wc"
                    name="number-of-wc"
                    type="tel"
                    onChange={(e) => {
                        numbersOnly(e)
                        setForm((prevState) => ({
                            ...prevState,
                            wc_number: e.target.value,
                        }))
                    }}
                    value={form?.wc_number || ''}
                />
            </Col>

            {form?.tournament_type?.tournament_type_name === 'Individualno' && (
                <Col xs={12} className="mt-3">
                    <Checkbox
                        checked={
                            tournamentHasQualifications
                                ? 'Turnir ima kvalifikacije'
                                : ''
                        }
                        onChange={() => {
                            setForm((prevState) => ({
                                ...prevState,
                                qualifications_participants_expected_number:
                                    null,
                                number_of_qualified: null,
                                wc_qualification_number: null,
                            }))
                            setTournamentHasQualifications((prev) => !prev)
                        }}
                        label={'Turnir ima kvalifikacije'}
                    />
                </Col>
            )}

            {tournamentHasQualifications ||
            form?.qualifications_participants_expected_number ||
            form?.number_of_qualified ||
            form?.wc_qualification_number ? (
                <>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={'Broj učesnika u kvalifikacijama'}
                            placeholder="Unesi broj učesnika u kvalifikacijama..."
                            dropdownLinks={participantsNumberArray}
                            handleSelect={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    qualifications_participants_expected_number:
                                        e === 'Odselektuj' ? null : +e,
                                }))
                            }
                            value={
                                form?.qualifications_participants_expected_number ||
                                ''
                            }
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={'Broj kvalifikanata'}
                            placeholder="Unesi broj kvalifikanata..."
                            dropdownLinks={
                                form?.number_of_qualified
                                    ? ['Odselektuj', 1, 2, 4, 8, 16]
                                    : [1, 2, 4, 8, 16]
                            }
                            handleSelect={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    number_of_qualified:
                                        e === 'Odselektuj' ? null : +e,
                                }))
                            }
                            value={form?.number_of_qualified || ''}
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <TextField
                            label="Broj pozivnica za kvalifikacije (WCQ)"
                            placeholder="Broj pozivnica za kvalifikacije (WCQ)"
                            id="number-of-wcq"
                            name="number-of-wcq"
                            type="tel"
                            onChange={(e) => {
                                numbersOnly(e)
                                setForm((prevState) => ({
                                    ...prevState,
                                    wc_qualification_number: e.target.value,
                                }))
                            }}
                            value={form?.wc_qualification_number || ''}
                        />
                    </Col>
                </>
            ) : null}

            <Col xs={12} className="mt-3">
                <Checkbox
                    checked={
                        tournamentHasRounRobin ? 'Turnir ima Round Robin' : ''
                    }
                    onChange={() => {
                        setTournamentHasRounRobin((prev) => !prev)
                        setForm((prevState) => ({
                            ...prevState,
                            number_of_groups: null,
                            participants_expected_number: null,
                            tournament_have_round_roubin:
                                !prevState?.tournament_have_round_roubin,
                        }))
                    }}
                    label={'Turnir ima Round Robin'}
                />
            </Col>

            {form?.tournament_type?.tournament_type_name === 'Individualno' && (
                <Col xs={12} className="mt-3">
                    <Checkbox
                        checked={tournamentHasGrandPrix}
                        onChange={() => {
                            setTournamentHasGrandPrix((prev) => !prev)
                            setForm((prevState) => ({
                                ...prevState,
                                tournament_have_grand_prix:
                                    !prevState?.tournament_have_grand_prix,
                                participants_expected_number: null,
                            }))
                        }}
                        label={'Turnir ima Srbija Gran Pri'}
                    />
                </Col>
            )}

            {tournamentHasGrandPrix && (
                <>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={'Potkategorija turnira'}
                            placeholder="Izaberi potkategriju turnira..."
                            dropdownLinks={['A', 'B', 'C']}
                            handleSelect={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    tournament_category: {
                                        ...prevState.tournament_category,
                                        tournament_subcategory_name: e,
                                    },
                                }))
                            }
                            value={
                                form?.tournament_category
                                    ?.tournament_subcategory_name
                            }
                        />
                    </Col>
                </>
            )}

            {form?.tournament_type?.tournament_type_name === 'Individualno' && (
                <Col md={12} className="mt-3">
                    <Dropdown
                        label={'Broj dubl parova'}
                        placeholder="Unesi broj dubl parova..."
                        dropdownLinks={
                            form?.doubles_pairs_expected_number
                                ? ['Odselektuj', ...participantsNumberArray]
                                : [...participantsNumberArray]
                        }
                        handleSelect={(e) =>
                            setForm((prevState) => ({
                                ...prevState,
                                doubles_pairs_expected_number:
                                    e === 'Odselektuj' ? null : +e,
                            }))
                        }
                        value={form?.doubles_pairs_expected_number}
                    />
                </Col>
            )}

            <Col md={12} className="my-3">
                <div className="d-flex">
                    <h5 className="mb-2">Pol</h5>
                    <h6 className="required ms-1">*</h6>
                </div>

                <Checkbox
                    checked={
                        form?.genders?.find((gender) =>
                            gender?.gender_name === 'Muški' ? true : false
                        ) || false
                    }
                    onChange={() => {
                        form?.genders?.find((gender) =>
                            gender?.gender_name === 'Muški' ? true : false
                        )
                            ? setForm((prevState) => ({
                                  ...prevState,
                                  genders: form?.genders?.filter(
                                      (item) => item?.gender_name !== 'Muški'
                                  ),
                              }))
                            : setForm((prevState) => ({
                                  ...prevState,
                                  genders: [
                                      ...prevState?.genders,
                                      { gender_name: 'Muški' },
                                  ],
                              }))
                    }}
                    label={'Muški'}
                />
                <Checkbox
                    checked={
                        form?.genders?.find((gender) =>
                            gender?.gender_name === 'Ženski' ? true : false
                        ) || false
                    }
                    onChange={() => {
                        form?.genders?.find((gender) =>
                            gender?.gender_name === 'Ženski' ? true : false
                        )
                            ? setForm((prevState) => ({
                                  ...prevState,
                                  genders: form?.genders?.filter(
                                      (item) => item?.gender_name !== 'Ženski'
                                  ),
                              }))
                            : setForm((prevState) => ({
                                  ...prevState,
                                  genders: [
                                      ...prevState?.genders,
                                      { gender_name: 'Ženski' },
                                  ],
                              }))
                    }}
                    label={'Ženski'}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    required
                    type="date"
                    name="start_date"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            start_date: e.target.value,
                        }))
                    }}
                    label="Početak takmičenja"
                    value={form?.start_date || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    required
                    type="date"
                    name="application_deadline_date"
                    label="Krajnji datum za prijavu"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            application_deadline_date: e.target.value,
                        }))
                    }}
                    value={form?.application_deadline_date || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    required
                    type="date"
                    name="checkout_deadline_date"
                    label="Krajnji datum za odjavu"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            checkout_deadline_date: e.target.value,
                        }))
                    }}
                    value={form?.checkout_deadline_date || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    type="number"
                    name="next_year_tournament_date"
                    label="Godina takmičenja"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            next_year_tournament_date: e.target.value,
                        }))
                    }}
                    value={form?.next_year_tournament_date || ''}
                />

                <p
                    className="mt-1"
                    style={{
                        fontSize: '12px',
                        color: 'gray',
                    }}
                >
                    Važi za turnire koji se odigravaju u trenutnoj, ali se
                    računaju za sledeċu godinu
                </p>
            </Col>
        </Row>
    )
}

export default StepOne
