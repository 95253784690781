import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import turniriPNG from '../../../assets/images/turniri.png'
import SearchInput from '../../../components/SearchInput'
import TableRefereeCompetitionsPagination from '../../../components/TableRefereeCompetitionsPagination'
import NoDataForView from '../../../components/NoDataForView'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import SingleTournamentRow from '../../../components/TableRefereeCompetitionsPagination/SingleTournamentRow'
import { useAppContext } from '../../../lib/contextLib'
import {
    debounce,
    isArrayEmpty,
    getComingCompetitions,
    getFinishedCompetitions,
} from '../../../lib/helpers'
import CompetitionFiltersCanvas from '../../../components/CompetitionFiltersCanvas'
import './styles.scss'
import PaginationWrapper from '../../../components/Pagination'

const searchInputID = `searchInput-competitions`

function RefereeCompetitions() {
    const location = useLocation()
    const { type } = location?.state ? location?.state : false
    const { user, setIsLoading } = useAppContext()
    let isCompetitionType = type
        ? type
        : location?.pathname === '/sudije/individualna-takmicenja'
        ? 'Individualno'
        : location?.pathname === '/sudije/ekipna-takmicenja'
        ? 'Ekipno'
        : ''
    const initialFilters = {
        region: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
        tournament_type: isCompetitionType,
        refereeUuid: user?.data?.attributes?.uuid,
    }

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')
    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const [filterParams, setFilterParams] = useState(initialFilters)

    const getComingCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        if (!user) return
        try {
            setIsLoading(true)

            const { updatedTournaments, pgCount, totalCount, page } =
                await getComingCompetitions(
                    currentPage,
                    {
                        ...filterParams,
                        tournament_type: isCompetitionType,
                        refereeUuid: user?.data?.attributes?.uuid,
                    },
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setComingCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getFinishedCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        if (!user) return
        try {
            setIsLoading(true)

            let filtersData = filterParams

            if (selectedTab === 'priprema' || selectedTab === 'zavrsena') {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'priprema',
                }
            }
            if (selectedTab === 'verifikovano') {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'verifikovano',
                }
            }
            if (selectedTab === 'ceka-verifikaciju') {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'ceka-verifikaciju',
                }
            }

            const { updatedTournaments, pgCount, totalCount, page } =
                await getFinishedCompetitions(
                    currentPage,
                    {
                        ...filtersData,
                        tournament_type: isCompetitionType,
                        refereeUuid: user?.data?.attributes?.uuid,
                    },
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setFinishedCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitionsWrapper(1, {
                  ...filterParams,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
            : await getFinishedCompetitionsWrapper(1, {
                  ...filterParams,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
    }, 1000)

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitionsWrapper(1, {
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
            : getFinishedCompetitionsWrapper(1, {
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
    }

    const handleTabSelect = (tab) => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        setCurrentPage(1)
        setSelectedTab(tab)
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitionsWrapper(currentPage, {
                  ...filterParams,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
            : getFinishedCompetitionsWrapper(currentPage, {
                  ...filterParams,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
    }, [currentPage, selectedTab, isCompetitionType, type, rowsPerPage, user])

    return (
        <Row className="referee-competitions-ranking-wrapper">
            {comingCompetitions || finishedCompetitions ? (
                <>
                    <Col xs={12}>
                        <Row className="pt-3">
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex align-items-center justify-content-between"
                            >
                                <Row>
                                    <Col className="">
                                        <h2 className="d-none d-md-block">
                                            Takmičenja
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Takmičenja
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: '/sudije/pocetna',
                                                }}
                                            >
                                                Početna
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {type
                                                    ? type === 'Ekipno'
                                                        ? 'Ekipna'
                                                        : type ===
                                                          'Individualno'
                                                        ? 'Individualna'
                                                        : 'Iz kalendara TSS'
                                                    : 'Iz kalendara TSS'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6} className="offset-md-2">
                                <Row className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                        <SearchInput
                                            id={searchInputID}
                                            placeholder="Pronađi takmičenje"
                                            onChange={(e) =>
                                                updateDebounceText(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <CompetitionFiltersCanvas
                                            filterParams={filterParams}
                                            setFilterParams={setFilterParams}
                                            getComingCompetitions={
                                                getComingCompetitionsWrapper
                                            }
                                            getFinishedCompetitions={
                                                getFinishedCompetitionsWrapper
                                            }
                                            selectedTab={selectedTab}
                                            handleDisableFiltering={
                                                handleDisableFiltering
                                            }
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="pt-3 m-0">
                            <Col className="p-0 tabular-navigation">
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    onSelect={(tab) => {
                                        setCurrentPage(1)
                                        document.getElementById(
                                            searchInputID
                                        ).value = ''
                                        handleDisableFiltering()
                                        handleTabSelect(tab)
                                    }}
                                >
                                    <Tab
                                        eventKey="nadolazeca"
                                        title="Nadolazeća"
                                    >
                                        <div className="d-none d-md-block">
                                            <TableRefereeCompetitionsPagination
                                                data={comingCompetitions}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            {!isArrayEmpty(
                                                comingCompetitions
                                            ) ? (
                                                <>
                                                    {comingCompetitions?.map(
                                                        (result, index) => (
                                                            <SingleTournamentRow
                                                                key={index}
                                                                result={result}
                                                                isWhite={
                                                                    index %
                                                                        2 !==
                                                                    0
                                                                        ? true
                                                                        : false
                                                                }
                                                                mobile
                                                            />
                                                        )
                                                    )}
                                                    <PaginationWrapper
                                                        currentPage={
                                                            currentPage
                                                        }
                                                        setCurrentPage={
                                                            setCurrentPage
                                                        }
                                                        pageCount={pageCount}
                                                        total={total}
                                                        rowsPerPage={
                                                            rowsPerPage
                                                        }
                                                        setRowsPerPage={
                                                            setRowsPerPage
                                                        }
                                                        showOnMobile
                                                    />
                                                </>
                                            ) : (
                                                <NoDataForView
                                                    title={`Nema nadolazećih takmičenja`}
                                                    description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                                    className="border-0"
                                                    column
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="zavrsena" title="Završena">
                                        <Row className="d-none d-md-block">
                                            <Col>
                                                <Tabs
                                                    id="uncontrolled-tab-example"
                                                    onSelect={(tab) => {
                                                        setCurrentPage(1)
                                                        document.getElementById(
                                                            searchInputID
                                                        ).value = ''
                                                        handleTabSelect(tab)
                                                    }}
                                                >
                                                    <Tab
                                                        eventKey="priprema"
                                                        title="Bez izeštaja"
                                                    >
                                                        <TableRefereeCompetitionsPagination
                                                            data={
                                                                finishedCompetitions
                                                            }
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            finished={true}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                        />
                                                    </Tab>
                                                    <Tab
                                                        eventKey="ceka-verifikaciju"
                                                        title="Čeka verifikaciju"
                                                    >
                                                        <TableRefereeCompetitionsPagination
                                                            data={
                                                                finishedCompetitions
                                                            }
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            finished={true}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                        />
                                                    </Tab>

                                                    <Tab
                                                        eventKey="verifikovano"
                                                        title="Verifikovana"
                                                    >
                                                        <TableRefereeCompetitionsPagination
                                                            data={
                                                                finishedCompetitions
                                                            }
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            finished={true}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                        />
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>

                                        <Row className="d-md-none">
                                            <Col>
                                                <Tabs
                                                    id="uncontrolled-tab-example"
                                                    onSelect={(tab) => {
                                                        setCurrentPage(1)
                                                        document.getElementById(
                                                            searchInputID
                                                        ).value = ''
                                                        handleTabSelect(tab)
                                                    }}
                                                >
                                                    <Tab
                                                        eventKey="priprema"
                                                        title="Bez izeštaja"
                                                    >
                                                        {!isArrayEmpty(
                                                            finishedCompetitions
                                                        ) ? (
                                                            <>
                                                                {finishedCompetitions.map(
                                                                    (
                                                                        result,
                                                                        index
                                                                    ) => (
                                                                        <SingleTournamentRow
                                                                            key={
                                                                                index
                                                                            }
                                                                            result={
                                                                                result
                                                                            }
                                                                            isWhite={
                                                                                index %
                                                                                    2 !==
                                                                                0
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            mobile
                                                                            finished
                                                                        />
                                                                    )
                                                                )}
                                                                <PaginationWrapper
                                                                    currentPage={
                                                                        currentPage
                                                                    }
                                                                    setCurrentPage={
                                                                        setCurrentPage
                                                                    }
                                                                    pageCount={
                                                                        pageCount
                                                                    }
                                                                    total={
                                                                        total
                                                                    }
                                                                    rowsPerPage={
                                                                        rowsPerPage
                                                                    }
                                                                    setRowsPerPage={
                                                                        setRowsPerPage
                                                                    }
                                                                    showOnMobile
                                                                />
                                                            </>
                                                        ) : (
                                                            <NoDataForView
                                                                title={`Nema završenih takmičenja`}
                                                                description={`Ovde je prikazana lista završenih takmičenja.`}
                                                                className="border-0"
                                                                column
                                                            />
                                                        )}
                                                    </Tab>
                                                    <Tab
                                                        eventKey="ceka-verifikaciju"
                                                        title="Čeka verifikaciju"
                                                    >
                                                        {!isArrayEmpty(
                                                            finishedCompetitions
                                                        ) ? (
                                                            <>
                                                                {finishedCompetitions.map(
                                                                    (
                                                                        result,
                                                                        index
                                                                    ) => (
                                                                        <SingleTournamentRow
                                                                            key={
                                                                                index
                                                                            }
                                                                            result={
                                                                                result
                                                                            }
                                                                            isWhite={
                                                                                index %
                                                                                    2 !==
                                                                                0
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            mobile
                                                                            finished
                                                                        />
                                                                    )
                                                                )}
                                                                <PaginationWrapper
                                                                    currentPage={
                                                                        currentPage
                                                                    }
                                                                    setCurrentPage={
                                                                        setCurrentPage
                                                                    }
                                                                    pageCount={
                                                                        pageCount
                                                                    }
                                                                    total={
                                                                        total
                                                                    }
                                                                    rowsPerPage={
                                                                        rowsPerPage
                                                                    }
                                                                    setRowsPerPage={
                                                                        setRowsPerPage
                                                                    }
                                                                    showOnMobile
                                                                />
                                                            </>
                                                        ) : (
                                                            <NoDataForView
                                                                title={`Nema završenih takmičenja`}
                                                                description={`Ovde je prikazana lista završenih takmičenja.`}
                                                                className="border-0"
                                                                column
                                                            />
                                                        )}
                                                    </Tab>

                                                    <Tab
                                                        eventKey="verifikovano"
                                                        title="Verifikovana"
                                                    >
                                                        {!isArrayEmpty(
                                                            finishedCompetitions
                                                        ) ? (
                                                            <>
                                                                {finishedCompetitions.map(
                                                                    (
                                                                        result,
                                                                        index
                                                                    ) => (
                                                                        <SingleTournamentRow
                                                                            key={
                                                                                index
                                                                            }
                                                                            result={
                                                                                result
                                                                            }
                                                                            isWhite={
                                                                                index %
                                                                                    2 !==
                                                                                0
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            mobile
                                                                            finished
                                                                        />
                                                                    )
                                                                )}
                                                                <PaginationWrapper
                                                                    currentPage={
                                                                        currentPage
                                                                    }
                                                                    setCurrentPage={
                                                                        setCurrentPage
                                                                    }
                                                                    pageCount={
                                                                        pageCount
                                                                    }
                                                                    total={
                                                                        total
                                                                    }
                                                                    rowsPerPage={
                                                                        rowsPerPage
                                                                    }
                                                                    setRowsPerPage={
                                                                        setRowsPerPage
                                                                    }
                                                                    showOnMobile
                                                                />
                                                            </>
                                                        ) : (
                                                            <NoDataForView
                                                                title={`Nema završenih takmičenja`}
                                                                description={`Ovde je prikazana lista završenih takmičenja.`}
                                                                className="border-0"
                                                                column
                                                            />
                                                        )}
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </>
            ) : (
                <Col
                    xs={12}
                    className="d-flex flex-column align-items-center justify-content-center pt-4"
                >
                    <img className="no-data-img" src={turniriPNG}></img>
                    <h2 className="mt-5">Takmičenja</h2>
                    <p className="mt-2">Nema takmičenja u kojima učestvujete</p>
                </Col>
            )}
        </Row>
    )
}

export default RefereeCompetitions
