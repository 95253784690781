import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Alert from '../../../../assets/svg/Alert'
import People from '../../../../assets/svg/People'
import Person from '../../../../assets/svg/Person'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import TrashOutlined from '../../../../assets/svg/TrashOutlined'
import Button from '../../../../components/Button'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import {
    deletePlayer,
    getLastMembership,
    getPlayerStatistisc,
    getPlayersClub,
    getPlayersPenalties,
    getSingleClub,
    getSinglePlayer,
    getSingleTournament,
    updatePlayer,
    getPlayersTournaments,
} from '../../../../lib/api'
import Membership from '../../../Competitor/Membership'
//tabs
import {
    competitorPillStatusHandler,
    formatDate,
    handleGenders,
    isArrayEmpty,
    isPlayerDisciplinaryPunished,
    generateCompetitionQueryString,
} from '../../../../lib/helpers'
import ProfileTab from './ProfileTab'
import PunishmentsTab from './PunishmentsTab'
import TournamentsTab from './TournamentsTab'

import Change from '../../../../assets/svg/Change'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import ProfileCard from '../../../../components/ProfileCard'
import { generateAvatar } from '../../../../utils/generateAvatar'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import MedicalHistory from '../../../../components/MedicalHistory'
import { useAppContext } from '../../../../lib/contextLib'
import { font } from '../../Competitions/CompetitionProfile/font'

import ClubHistory from '../../../../components/ClubHistory'
import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import CompetitionFiltersCanvas from '../../../../components/CompetitionFiltersCanvas'
import './styles.scss'

const CompetitorProfile = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const initialFilters = {
        gender: '',
        region: '',
        competition: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
    }
    const { user, setShowCustomToast } = useAppContext()

    const [filterParams, setFilterParams] = useState(initialFilters)

    //* Pagination for tournaments tab and pdf export
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    //* Competition info for tournaments tab and pdf export
    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const [confirmationModal, setConfirmationModal] = useState(null)
    const [showMembership, setShowMembership] = useState(false)
    const [showMedicalHistory, setShowMedicalHistory] = useState(false)
    const [showClubHistory, setShowClubHistory] = useState(false)

    const [userData, setUserData] = useState()
    const [activeTab, setActiveTab] = useState('profile')
    const customTournamentActiveTab = 'nadolazeca'
    const [tournamentsTabSelectedSubTab, setTournamentsTabSelectedSubTab] =
        useState(customTournamentActiveTab)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [isPunished, setIsPunished] = useState(false)
    const [lastMembership, setLastMembership] = useState()

    const handleLastMembership = async () => {
        const lastMembership = await getLastMembership(id)

        if (lastMembership) {
            setLastMembership(lastMembership[0])
        }
    }

    const inititalData = async () => {
        let player = await getSinglePlayer(id)

        if (player) {
            setUserData(player)
            handleLastMembership()
            setRefreshData(false)
            handleIsPunished(id)
        } else {
            navigate('/takmicari')
        }
    }

    useEffect(() => {
        inititalData()
    }, [showMembership, refreshData])

    const getComingCompetitions = async (page, haveFilters = true) => {
        let filterParamsData = filterParams
        if (!haveFilters) {
            filterParamsData = {}
        }

        let filterString = generateCompetitionQueryString({
            filterParams: filterParamsData,
            initFilterString: 'filters[$and][0][is_finished][$ne]=true&',
        })

        filterString += `pagination[pageSize]=${rowsPerPage}&pagination[page]=${page}`

        const { response, pgCount, total } = await getPlayersTournaments({
            uuid: userData?.attributes?.uuid,
            query: filterString,
        })

        if (!response) return

        setComingCompetitions(response)
        setPageCount(pgCount)
        setTotal(total)
        setCurrentPage(page)

        return response
    }

    const getAllFinishedCompetitions = async (page, haveFilters = true) => {
        let filterParamsData = filterParams
        if (!haveFilters) {
            filterParamsData = {}
        }

        let filterString = generateCompetitionQueryString({
            filterParams: filterParamsData,
            initFilterString: 'filters[$and][0][is_finished]=true&',
        })

        filterString += `pagination[pageSize]=${rowsPerPage}&pagination[page]=${page}`

        const { response, pgCount, total } = await getPlayersTournaments({
            uuid: userData?.attributes?.uuid,
            query: filterString,
        })

        if (!response) return

        setFinishedCompetitions(response)
        setPageCount(pgCount)
        setTotal(total)
        setCurrentPage(page)

        return response
    }

    const handleSelectedTab = () => {
        switch (activeTab) {
            case 'profile':
                return (
                    <ProfileTab
                        competitor={userData}
                        setShowMembership={setShowMembership}
                        setShowMedicalHistory={setShowMedicalHistory}
                        setShowClubHistory={setShowClubHistory}
                        loggedInUser={user}
                    />
                )
            case 'tournaments':
                return (
                    <TournamentsTab
                        customActiveTab={customTournamentActiveTab}
                        currentPage={currentPage}
                        pageCount={pageCount}
                        total={total}
                        comingCompetitions={comingCompetitions}
                        finishedCompetitions={finishedCompetitions}
                        setTournamentsTabSelectedSubTab={
                            setTournamentsTabSelectedSubTab
                        }
                        getAllFinishedCompetitions={getAllFinishedCompetitions}
                        getComingCompetitions={getComingCompetitions}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        competitor={userData}
                        setCompetitorData={setUserData}
                        setCurrentPage={setCurrentPage}
                        setFilterParams={setFilterParams}
                    />
                )
            case 'penalty':
                return (
                    <PunishmentsTab
                        userData={userData}
                        setRefreshData={setRefreshData}
                    />
                )
            default:
                return (
                    <ProfileTab
                        competitor={userData}
                        setShowMembership={setShowMembership}
                        setShowMedicalHistory={setShowMedicalHistory}
                        setShowClubHistory={setShowClubHistory}
                        loggedInUser={user}
                    />
                )
        }
    }

    const handleDeletePlayer = async () => {
        try {
            const deletedPlayer = await deletePlayer(id)
            if (deletedPlayer) {
                navigate('/takmicari')
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Takmičar je obrisan.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    // Function to handle delete competitor modal
    const handleDeletePlayerModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    //* Get players penalties for pdf

    const handleGetPenalties = async () => {
        const allPenalties = await getPlayersPenalties(id)

        let disciplinaryPenalties = allPenalties.filter(
            (penalty) => penalty?.attributes?.penalty_type === 'Disciplinary'
        )

        let tournamentPenaltiesFiltered = allPenalties.filter(
            (penalty) => penalty?.attributes?.penalty_type === 'Tournament'
        )

        let tournamentPromises = tournamentPenaltiesFiltered?.map(
            async (penalty) => {
                let tournamentInfo = await getSingleTournament(
                    penalty?.attributes?.competition_uuid
                )

                return {
                    ...penalty,
                    attributes: {
                        ...penalty?.attributes,
                        tournament_info: tournamentInfo,
                    },
                }
            }
        )

        let tournamentPenalties = await Promise.all(tournamentPromises)

        return { tournamentPenalties, disciplinaryPenalties }
    }

    //* Handle penalties duration
    const calculateDuration = (start_date, end_date) => {
        let startDate = new Date(start_date)
        let endDate = new Date(end_date)
        return (
            (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            (endDate.getMonth() - startDate.getMonth())
        )
    }

    const createDoc = async (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${userData?.attributes?.person_info?.given_name} ${userData?.attributes?.person_info?.family_name}`
        doc.text(title, marginLeft, 40)

        const generalInfo = [
            ['Ime', userData?.attributes?.person_info?.given_name],
            ['Prezime', userData?.attributes?.person_info?.family_name],
            [
                'Datum rođenja',
                userData?.attributes?.person_info?.birthdate
                    ? formatDate(userData?.attributes?.person_info?.birthdate)
                    : 'Nema informacije',
            ],
            [
                'Mesto rođenja',
                userData?.attributes?.birth_address?.city
                    ? userData?.attributes?.birth_address?.city
                    : 'Nema informacije',
            ],
            [
                'Adresa',
                userData?.attributes?.birth_address?.street &&
                userData?.attributes?.birth_address?.city
                    ? `${userData?.attributes?.birth_address?.street}${
                          userData?.attributes?.birth_address?.city
                              ? ', ' + userData?.attributes?.birth_address?.city
                              : ''
                      }`
                    : 'Nema informacije',
            ],
            ['Email', userData?.attributes?.person_info?.email],
            [
                'Stranac',
                userData?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !== null &&
                userData?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !==
                    undefined
                    ? userData?.attributes?.citizenship
                          ?.is_serbian_citizen_internationally_registered ===
                          true &&
                      (userData?.attributes?.citizenship
                          ?.citizenship_alpha_3_code === 'SRB' ||
                          userData?.attributes?.citizenship
                              ?.second_citizenship_alpha_3_code === 'SRB')
                        ? 'Ne'
                        : 'Da'
                    : 'Nema informacije',
            ],
            [
                'Dvojno drzavljanstvo',
                userData?.attributes?.citizenship?.citizenship_alpha_3_code &&
                userData?.attributes?.citizenship
                    ?.second_citizenship_alpha_3_code &&
                userData?.attributes?.citizenship?.citizenship_type
                    ?.citizenship_type_name === 'Dvojno'
                    ? 'Da'
                    : 'Ne',
            ],
            [
                'Pol',
                userData?.attributes?.gender?.gender_name
                    ? userData?.attributes?.gender?.gender_name
                    : 'Nema informacije',
            ],
            [
                'JMBG',
                userData?.attributes?.person_info?.jmbg
                    ? userData?.attributes?.person_info?.jmbg
                    : 'Nema informacije',
            ],
            [
                'Telefon',
                userData?.attributes?.person_info?.phone
                    ? userData?.attributes?.person_info?.phone
                    : 'Nema informacije',
            ],
            // ['Region', userData?.attributes?.region],
            [
                'Otac',
                userData?.attributes?.fathers_name
                    ? userData?.attributes?.fathers_name
                    : 'Nema informacije',
            ],
            [
                'Majka',
                userData?.attributes?.mothers_name
                    ? userData?.attributes?.mothers_name
                    : 'Nema informacije',
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [['Opšte informacije', ' ']],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(generalInfoContent)

        const { total_matches, wins, defeats } = await handlePlayerStatistics()
        const { club } = await handlePlayersClub()

        let organizationalContent = [
            [
                'Ruka kojom igra',
                userData?.attributes?.playing_hand
                    ? userData?.attributes?.playing_hand?.playing_hand_name
                    : 'Nema informacije',
            ],
            [
                'Visina',
                userData?.attributes?.height
                    ? userData?.attributes?.height
                    : 'Nema informacije',
            ],
            [
                'Težina',
                userData?.attributes?.weight
                    ? userData?.attributes?.weight
                    : 'Nema informacije',
            ],
            [
                'Datum poslednje registracije',
                lastMembership
                    ? formatDate(lastMembership?.attributes?.date_of_payment)
                    : 'Nema informacije',
            ],
            ['Ukupno mečeva', total_matches],
            ['Pobeda', wins],
            ['Poraza', defeats],
            ['Klub', club ? club?.attributes?.name : 'Nema informacije'],
        ]

        let licensesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Organizacione informacije', ' ']],
            body: organizationalContent,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(licensesContent)

        return doc
    }

    const createDocPenalty = async (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${userData?.attributes?.person_info?.given_name} ${userData?.attributes?.person_info?.family_name} - Kazne`
        doc.text(title, marginLeft, 40)

        const { tournamentPenalties, disciplinaryPenalties } =
            await handleGetPenalties()

        let formattedTournamentPenalties = []

        let tournamentPenaltiesSeparator = {
            startY: 50,
            head: [['Kazne sa takmičenja']],
            body: [],
            styles: {
                font: 'WorkSans',
                halign: 'center',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(tournamentPenaltiesSeparator)

        if (tournamentPenalties && tournamentPenalties?.length > 0) {
            tournamentPenalties.map(async (tournamentPenalty, index) => {
                formattedTournamentPenalties.push([
                    [
                        'Vrsta kazne',
                        tournamentPenalty?.attributes?.penalty_name,
                    ],
                    [
                        'Datum izricanja kazne',
                        tournamentPenalty?.attributes?.start_date
                            ? formatDate(
                                  tournamentPenalty.attributes.start_date
                              )
                            : 'Nema informacije',
                    ],
                    [
                        'Trajanje kazne',
                        tournamentPenalty?.attributes?.end_date
                            ? `${calculateDuration(
                                  tournamentPenalty?.attributes?.start_date,
                                  tournamentPenalty?.attributes?.end_date
                              )} mesec(a)`
                            : 'Nema informacije',
                    ],
                    [
                        'Opis kazne',
                        tournamentPenalty?.attributes?.description
                            ? tournamentPenalty?.attributes?.description
                            : 'Nema informacije',
                    ],
                    [
                        'Sa takmičenja',
                        tournamentPenalty?.attributes?.tournament_info
                            ?.attributes?.name
                            ? tournamentPenalty?.attributes?.tournament_info
                                  ?.attributes?.name
                            : 'Nema informacije',
                    ],
                ])
            })
        }

        if (formattedTournamentPenalties?.length > 0) {
            formattedTournamentPenalties?.map((penalty, index) => {
                let tournamentPenaltiesContent = {
                    startY:
                        index === 0
                            ? doc.lastAutoTable.finalY + 2
                            : doc.lastAutoTable.finalY + 20,
                    head: [[`${index + 1}`, ' ']],
                    body: penalty,
                    styles: {
                        font: 'WorkSans',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(tournamentPenaltiesContent)
            })
        } else {
            let tournamentPenaltiesContent = {
                startY: doc.lastAutoTable.finalY + 2,
                body: [['Nema kazni sa takmičenja']],
                styles: {
                    font: 'WorkSans',
                    halign: 'center',
                },
            }

            doc.autoTable(tournamentPenaltiesContent)
        }

        let formattedDisciplinaryPenalties = []
        if (disciplinaryPenalties && disciplinaryPenalties?.length > 0) {
            disciplinaryPenalties.map((penalty) => {
                formattedDisciplinaryPenalties.push([
                    ['Vrsta kazne', penalty?.attributes?.penalty_name],
                    [
                        'Datum izricanja kazne',
                        penalty?.attributes?.start_date
                            ? formatDate(penalty.attributes.start_date)
                            : 'Nema informacije',
                    ],
                    [
                        'Trajanje kazne',
                        penalty?.attributes?.end_date
                            ? `${calculateDuration(
                                  penalty?.attributes?.start_date,
                                  penalty?.attributes?.end_date
                              )} mesec(a)`
                            : 'Nema informacije',
                    ],

                    [
                        'Opis kazne',
                        penalty?.attributes?.description
                            ? penalty?.attributes?.description
                            : 'Nema informacije',
                    ],
                ])
            })
        }

        let disciplinaryPenaltiesSeparator = {
            startY: doc.lastAutoTable.finalY + 50,
            head: [['Disciplinske kazne']],
            body: [],
            styles: {
                font: 'WorkSans',
                halign: 'center',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(disciplinaryPenaltiesSeparator)

        if (formattedDisciplinaryPenalties?.length > 0) {
            formattedDisciplinaryPenalties?.map((penalty, index) => {
                let disciplinePenaltiesContent = {
                    startY:
                        index === 0
                            ? doc.lastAutoTable.finalY + 2
                            : doc.lastAutoTable.finalY + 20,
                    head: [[`${index + 1}`, ' ']],
                    body: penalty,
                    styles: {
                        font: 'WorkSans',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(disciplinePenaltiesContent)
            })
        } else {
            let disciplinePenaltiesContent = {
                startY: doc.lastAutoTable.finalY + 2,
                body: [['Nema disciplinskih kazni']],
                styles: {
                    font: 'WorkSans',
                    halign: 'center',
                },
            }

            doc.autoTable(disciplinePenaltiesContent)
        }

        return doc
    }

    const createTournamentsDoc = async (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const countHelper = () => {
            return `${(currentPage - 1) * rowsPerPage + 1}-${
                rowsPerPage * currentPage
            }`
        }

        const tournamentActiveTabHandler = (tab) => {
            switch (tab) {
                case 'nadolazeca':
                    return 'Nadolazeća takmičenja'
                case 'zavrsena':
                    return 'Završena takmičenja'
                default:
                    return ''
            }
        }

        const title = `${userData?.attributes?.person_info?.given_name} ${
            userData?.attributes?.person_info?.family_name
        } - ${tournamentActiveTabHandler(
            tournamentsTabSelectedSubTab
        )} - ${countHelper()}`
        doc.text(title, marginLeft, 40)

        if (tournamentsTabSelectedSubTab === 'nadolazeca') {
            if (comingCompetitions && comingCompetitions?.length > 0) {
                comingCompetitions?.map((tournament, index) => {
                    let tournamentInfo = [
                        [
                            'Naziv',
                            tournament?.attributes?.name
                                ? tournament?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Organizator',
                            tournament?.attributes?.club_organizer?.attributes
                                ?.name
                                ? tournament?.attributes?.club_organizer
                                      ?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Prijava do',
                            tournament?.attributes?.application_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.application_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Odjava do',
                            tournament?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.checkout_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Pol',
                            tournament?.attributes?.genders
                                ? handleGenders(tournament?.attributes?.genders)
                                : 'Nema informacije',
                        ],
                        [
                            'Kategorija',
                            tournament?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? tournament?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : 'Nema informacije',
                        ],
                        [
                            'Bodovi',
                            tournament?.attributes?.ranking_points
                                ? tournament?.attributes?.ranking_points
                                : 0,
                        ],
                    ]

                    let tournamentContent = {
                        startY:
                            index === 0 ? 50 : doc.lastAutoTable.finalY + 20,
                        head: [[`${index + 1}`, ' ']],
                        body: tournamentInfo,
                        styles: {
                            font: 'WorkSans',
                        },
                        columnStyles: {
                            0: { cellWidth: 250 },
                        },
                    }

                    doc.autoTable(tournamentContent)
                })
            } else {
                let noComingCompetitionSeparator = {
                    startY: 50,
                    head: [['Nadolazeća takmičenja']],
                    body: [],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(noComingCompetitionSeparator)

                let tournamentContent = {
                    startY: doc.lastAutoTable.finalY + 2,
                    body: [['Nema nadolazećih takmičenja']],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                }

                doc.autoTable(tournamentContent)
            }
        }

        if (tournamentsTabSelectedSubTab === 'zavrsena') {
            if (finishedCompetitions && finishedCompetitions?.length > 0) {
                finishedCompetitions?.map((tournament, index) => {
                    let tournamentInfo = [
                        [
                            'Naziv',
                            tournament?.attributes?.name
                                ? tournament?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Organizator',
                            tournament?.attributes?.club_organizer?.attributes
                                ?.name
                                ? tournament?.attributes?.club_organizer
                                      ?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Prijava do',
                            tournament?.attributes?.application_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.application_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Odjava do',
                            tournament?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.checkout_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Pol',
                            tournament?.attributes?.genders
                                ? handleGenders(tournament?.attributes?.genders)
                                : 'Nema informacije',
                        ],
                        [
                            'Kategorija',
                            tournament?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? tournament?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : 'Nema informacije',
                        ],
                        [
                            'Bodovi',
                            tournament?.attributes?.ranking_points
                                ? tournament?.attributes?.ranking_points
                                : 0,
                        ],
                    ]

                    let tournamentContent = {
                        startY:
                            index === 0 ? 50 : doc.lastAutoTable.finalY + 20,
                        head: [[`${index + 1}`, ' ']],
                        body: tournamentInfo,
                        styles: {
                            font: 'WorkSans',
                        },
                        columnStyles: {
                            0: { cellWidth: 250 },
                        },
                    }

                    doc.autoTable(tournamentContent)
                })
            } else {
                let noFinishedCompetitionSeparator = {
                    startY: 50,
                    head: [['Završena takmičenja']],
                    body: [],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(noFinishedCompetitionSeparator)

                let tournamentContent = {
                    startY: doc.lastAutoTable.finalY + 2,
                    body: [['Nema završenih takmičenja']],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                }

                doc.autoTable(tournamentContent)
            }
        }

        return doc
    }

    // Export PDF for Profile and Penalties
    const handlePDF = () => {
        if (activeTab === 'profile') {
            handleExportCompetitorPDF()
        } else if (activeTab === 'penalty') {
            handleExportCompetitorPenaltyPDF()
        } else if (activeTab === 'tournaments') {
            handleExportTournamentsPDF()
        }
    }

    // Export PDF for Profile, Tournaments and Penalties
    const handleExportCompetitorPDF = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = await createDoc(orientation, unit, size, font)

        doc.save(
            `Takmičar_${userData?.attributes?.person_info?.given_name}_${
                userData?.attributes?.person_info?.family_name
            }_Profil_${new Date().toLocaleTimeString()}.pdf`
        )
    }

    const handleExportCompetitorPenaltyPDF = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = await createDocPenalty(orientation, unit, size, font)

        doc.save(
            `Takmičar_${userData?.attributes?.person_info?.given_name}_${
                userData?.attributes?.person_info?.family_name
            }_Kazne_${new Date().toLocaleTimeString()}.pdf`
        )
    }

    const handleExportTournamentsPDF = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = await createTournamentsDoc(orientation, unit, size, font)

        doc.save(
            `Takmičar_${userData?.attributes?.person_info?.given_name}_${
                userData?.attributes?.person_info?.family_name
            }_Takmičenja_${new Date().toLocaleTimeString()}.pdf`
        )
    }

    const handlePrintCompetitor = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = await createDoc(orientation, unit, size, font)

        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const handlePrintCompetitorPenalty = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const doc = await createDocPenalty(orientation, unit, size, font)
        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const handlePrintTournaments = async () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = await createTournamentsDoc(orientation, unit, size, font)

        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    // Print for Profile and Penalties tab

    const handlePrint = () => {
        if (activeTab === 'profile') {
            handlePrintCompetitor()
        } else if (activeTab === 'penalty') {
            handlePrintCompetitorPenalty()
        } else if (activeTab === 'tournaments') {
            handlePrintTournaments()
        }
    }

    const handleIsPunished = async (playerUuid) => {
        let isPlayerPunished = await isPlayerDisciplinaryPunished(playerUuid)

        setIsPunished(isPlayerPunished)
    }

    const handlePlayerStatistics = async () => {
        const playerStatisticData = await getPlayerStatistisc(id)
        if (!playerStatisticData) return

        return {
            total_matches: playerStatisticData?.total_matches || 0,
            wins: playerStatisticData?.number_of_wins || '0',
            defeats: playerStatisticData?.number_of_defeats || '0',
        }
    }

    const handlePlayersClub = async () => {
        const clubData = await getPlayersClub(id)
        if (!clubData) return { club: null }

        const clubInfo = await getSingleClub(clubData?.attributes?.club_uuid)
        if (!clubInfo) return { club: null }

        return { club: clubInfo }
    }

    const checkPlayerLastMembership = async () => {
        // Set variable for confirmation modal info
        let confirmationModalInfo
        // Set Current year variable
        let currentYear = new Date().getFullYear()

        //check if player have memberships and if last membership year is the same as current year
        if (
            !isArrayEmpty(lastMembership) &&
            +lastMembership?.attributes?.date_of_payment.slice(0, 4) ===
                currentYear
        ) {
            confirmationModalInfo = {
                text: 'Jeste li sigurni da želite da aktivirate takmičara?',
                status: 'active',
            }
            setConfirmationModal((_) => {
                return confirmationModalInfo
            })
        } else {
            confirmationModalInfo = {
                text: 'Takmičar nema plaćenu članarinu za tekuću godinu. Jeste li sigurni da želite da takmičaru dodelite status neaktivan?',
                status: 'inactive',
            }

            setConfirmationModal((_) => {
                return confirmationModalInfo
            })
        }
    }

    const handlePlayerActivation = async () => {
        let statusData = {
            status: {
                status_name: confirmationModal?.status,
            },
        }

        try {
            let response = await updatePlayer(
                userData?.attributes?.uuid,
                statusData
            )

            if (response) {
                setConfirmationModal(null)
                inititalData()
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message:
                        confirmationModal?.status === 'active'
                            ? 'Takmičar uspešno aktiviran.'
                            : 'Takmičaru uspešno postavljen status na neaktivan.',
                })
            }
        } catch (error) {
            setConfirmationModal(null)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setCurrentPage(1)
        tournamentsTabSelectedSubTab === 'nadolazeca'
            ? getComingCompetitions(1, false)
            : getAllFinishedCompetitions(1, false)
    }

    useEffect(() => {
        if (userData) {
            handleIsPunished(userData?.attributes?.uuid)
        }
    }, [userData])

    return (
        <Row className="competitor-profile-wrapper h-100">
            <Col>
                {!showMembership && !showMedicalHistory && !showClubHistory && (
                    <>
                        <Col className="d-flex pt-3 justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                                <h2 className="d-none d-md-block">Takmičari</h2>
                                <p className="d-block d-md-none">Takmičari</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/takmicari' }}
                                    >
                                        Takmičari
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {
                                            userData?.attributes?.person_info
                                                ?.given_name
                                        }{' '}
                                        {
                                            userData?.attributes?.person_info
                                                ?.family_name
                                        }
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="d-none d-md-flex competitors-profile-buttons">
                                {activeTab === 'tournaments' ? (
                                    <CompetitionFiltersCanvas
                                        filterParams={filterParams}
                                        setFilterParams={setFilterParams}
                                        getComingCompetitions={
                                            getComingCompetitions
                                        }
                                        getFinishedCompetitions={
                                            getAllFinishedCompetitions
                                        }
                                        selectedTab={
                                            tournamentsTabSelectedSubTab
                                        }
                                        handleDisableFiltering={
                                            handleDisableFiltering
                                        }
                                    />
                                ) : null}

                                <Button
                                    type="text"
                                    leadingIcon={
                                        <DocumentTextOutlined height="24px" />
                                    }
                                    onClick={handlePDF}
                                >
                                    Eksportuj u PDF
                                </Button>
                                <Button
                                    type="secondary"
                                    leadingIcon={
                                        <PrintOutlined height="24px" />
                                    }
                                    onClick={handlePrint}
                                >
                                    Štampaj
                                </Button>

                                {user?.role?.type === 'admin' && (
                                    <>
                                        <Button
                                            type="secondary"
                                            leadingIcon={
                                                <Change height="24px" />
                                            }
                                            onClick={() =>
                                                navigate(
                                                    `/takmicari/${userData.attributes?.uuid}/izmeni-profil-takmicara`
                                                )
                                            }
                                        >
                                            Izmeni
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={
                                                <TrashOutlined height="24px" />
                                            }
                                            onClick={handleDeletePlayerModal}
                                        >
                                            Obriši
                                        </Button>

                                        {userData?.attributes?.status &&
                                            userData?.attributes?.status
                                                ?.status_name === 'draft' && (
                                                <Button
                                                    className="m-auto"
                                                    onClick={
                                                        checkPlayerLastMembership
                                                    }
                                                >
                                                    Potvrdi takmičara
                                                </Button>
                                            )}
                                    </>
                                )}
                            </div>
                        </Col>
                        <Row className="m-0 card-profile">
                            <ProfileCard
                                name={`${userData?.attributes?.person_info?.given_name}  ${userData?.attributes?.person_info?.family_name}`}
                                email={userData?.attributes?.person_info?.email}
                                avatar={
                                    userData?.attributes?.person_info?.picture
                                        ? userData?.attributes?.person_info
                                              ?.picture
                                        : generateAvatar(
                                              `${userData?.attributes?.person_info?.given_name} ${userData?.attributes?.person_info?.family_name}`
                                          )
                                }
                                pillContent={competitorPillStatusHandler(
                                    userData?.attributes?.status?.status_name
                                )}
                                punished={isPunished}
                            />
                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end tabular-navigation"
                            >
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    activeKey={activeTab}
                                    onSelect={(tab) => setActiveTab(tab)}
                                >
                                    <Tab
                                        eventKey="profile"
                                        title={
                                            <>
                                                <Person width="20px" />
                                                <p className="ms-2">Profil</p>
                                            </>
                                        }
                                        tabClassName="d-flex"
                                    ></Tab>
                                    <Tab
                                        eventKey="tournaments"
                                        title={
                                            <>
                                                <People width="20px" />
                                                <p className="ms-2">
                                                    Takmičenja
                                                </p>
                                            </>
                                        }
                                        tabClassName="d-flex"
                                    ></Tab>
                                    {user?.role?.type !== 'competitor' && (
                                        <Tab
                                            eventKey="penalty"
                                            title={
                                                <>
                                                    <Alert width="20px" />
                                                    <p className="ms-2">
                                                        Kazne
                                                    </p>
                                                </>
                                            }
                                            tabClassName="d-flex"
                                        ></Tab>
                                    )}
                                </Tabs>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-5 m-0">
                            <Col md={12}>{handleSelectedTab()}</Col>
                        </Row>
                    </>
                )}
                {showMembership && (
                    <Membership
                        setShowMembership={setShowMembership}
                        profileData={userData}
                    />
                )}
                {showMedicalHistory && (
                    <MedicalHistory
                        setShowMedicalHistory={setShowMedicalHistory}
                        userType="competitor"
                        profileData={userData}
                    />
                )}

                {showClubHistory && (
                    <ClubHistory
                        setShowClubHistory={setShowClubHistory}
                        profileData={userData}
                    />
                )}
            </Col>
            <ConfirmationModal
                modalText="Jeste li sigurni da želite obrisati takmičara?"
                onHide={() => setShowDeleteModal(false)}
                show={showDeleteModal}
                onClick={handleDeletePlayer}
            />
            <ConfirmationModal
                show={confirmationModal}
                modalTitle="Potvrda aktivacije"
                modalText={confirmationModal?.text}
                onHide={() => setConfirmationModal(null)}
                onClick={handlePlayerActivation}
                secondButtonText="Potvrdi"
            />
        </Row>
    )
}

export default CompetitorProfile
