import React, { useEffect, useState } from 'react'

import { Offcanvas, Row, Col } from 'react-bootstrap'
import RadioButton from '../RadioButton'
import AsyncSelect from '../AsyncSelect'
import Dropdown from '../Dropdown'
import Button from '../Button'
import TextField from '../TextField'
import { getClubList } from '../../lib/helpers'
import { getCodebook, getTournamentReport } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import OutlinedFilter from '../../assets/svg/OutlinedFilter'
import { font } from '../../containers/Admin/Competitions/CompetitionProfile/font'
import { formatDate } from '../../lib/helpers'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const CompetitionFiltersCanvas = ({
    filterParams,
    setFilterParams,
    getComingCompetitions,
    getFinishedCompetitions,
    selectedTab,
    handleDisableFiltering,
}) => {
    const { setIsLoading } = useAppContext()
    const [show, setShow] = useState(false)
    const [regions, setRegions] = useState([])
    const [genders, setGenders] = useState([])
    const [tournamentOrganisationTypes, setTournamentOrganisationTypes] =
        useState([])
    const [tournamentCategories, setTournamentCategories] = useState([])
    const [tournamentSubCategories, setTournamentSubCategories] = useState([])
    const [playersCompetition, setPlayersCompetition] = useState([])

    const [selectedClub, setSelectedClub] = useState(null)

    // Club selector in MultiSelectSearch
    const handleClubSelect = (e) => {
        const { uuid, value } = e

        setSelectedClub({ uuid: uuid, value: value })
        setFilterParams({ ...filterParams, club_organizer_uuid: uuid })
    }

    const competitorCompetitions = [
        '10s - Crveni',
        '10s - Narandžasti',
        '10s - Zeleni',
    ]

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.region', setRegions)
        await getCodebook(
            'codebooks.tournament-organisation-type',
            setTournamentOrganisationTypes
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentCategories
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentSubCategories,
            true
        )
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(
                result.reduce((accumulator, currentValue) => {
                    if (!['10', '45'].includes(currentValue)) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    const handlePdfExportComing = async () => {
        setIsLoading(true)
        const filteredTournaments = await getComingCompetitions(
            1,
            filterParams,
            true
        )

        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = 'Nadolazeća takmičenja'
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
            ])
        }

        let comingCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(comingCompetitionsContent)
        doc.save('Nadolazeća takmičenja.pdf')
        setShow(false)
        setIsLoading(false)
    }

    const handlePdfExportFinished = async () => {
        setIsLoading(true)
        const filteredTournaments = await getFinishedCompetitions(
            1,
            filterParams,
            true
        )
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Završena takmičenja, Ukupno ${filteredTournaments?.length}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            let status = false

            try {
                let tournamentReportResponse = await getTournamentReport(
                    compt?.attributes?.uuid
                )

                if (
                    tournamentReportResponse &&
                    tournamentReportResponse?.length > 0
                ) {
                    if (tournamentReportResponse[0]?.attributes?.is_verified) {
                        status = true
                    } else {
                        status = false
                    }
                } else {
                    status = false
                }
            } catch (error) {
                console.log(error)
            }

            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
                status ? 'Verifikovan' : 'Nije verifikovan',
            ])
        }
        let finishedCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                    'Status',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(finishedCompetitionsContent)
        doc.save('Završena takmičenja.pdf')
        setShow(false)
        setIsLoading(false)
    }

    useEffect(() => {
        if (!show) return

        handleCodebooks()
    }, [show])

    return (
        <React.Fragment>
            <Button
                type="text"
                className="ms-2 d-none d-md-block"
                onClick={() => setShow(true)}
            >
                Filtriraj
            </Button>

            <Button
                className="d-md-none"
                type="text"
                onClick={() => setShow(true)}
            >
                <OutlinedFilter />
            </Button>
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12} className="mt-3">
                            <RadioButton
                                name="Pol"
                                radioValues={genders}
                                onClick={(e) => {
                                    filterParams?.gender === e.target.value
                                        ? setFilterParams({
                                              ...filterParams,
                                              gender: '',
                                          })
                                        : null
                                }}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        gender: e.target.value,
                                    })
                                }}
                                checkedValue={filterParams?.gender || ''}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                className="mt-3"
                                type="date"
                                label="Početak takmičenja"
                                value={filterParams?.start_date}
                                onChange={(e) =>
                                    setFilterParams((prev) => ({
                                        ...prev,
                                        start_date: e.target.value,
                                    }))
                                }
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <AsyncSelect
                                loadOptions={getClubList}
                                onChange={handleClubSelect}
                                label={'Izaberi klub'}
                                value={selectedClub}
                                placeholder={'Pretraži i izaberi klub...'}
                                noOptionsMessage={'Traženi klub ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Region'}
                                value={filterParams?.region || ''}
                                placeholder="Region"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        region: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={
                                    regions
                                        ? regions
                                              .filter(
                                                  (region) => region !== 'TSS'
                                              )
                                              .reduce(
                                                  (accumulator, region) => [
                                                      ...accumulator,
                                                      region,
                                                  ],
                                                  filterParams?.region
                                                      ? ['Odselektuj']
                                                      : []
                                              )
                                        : []
                                }
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Konkurencija'}
                                className="yearDropdown"
                                value={filterParams?.competition || ''}
                                placeholder="Konkurencija"
                                handleSelect={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        competition:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }}
                                dropdownLinks={playersCompetition?.reduce(
                                    (accumulator, currentValue) => [
                                        ...accumulator,
                                        currentValue,
                                    ],
                                    filterParams?.competition
                                        ? [
                                              'Odselektuj',
                                              ...competitorCompetitions,
                                          ]
                                        : competitorCompetitions
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Kategorija'}
                                className="category-dropdown"
                                value={filterParams?.category || ''}
                                placeholder="Kategorija"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        category: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentCategories?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.category ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>

                        {filterParams?.category === 'III' && (
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Potkategorija'}
                                    value={filterParams?.sub_category || ''}
                                    placeholder="Potkategorija"
                                    handleSelect={(e) =>
                                        setFilterParams({
                                            ...filterParams,
                                            sub_category:
                                                e === 'Odselektuj' ? '' : e,
                                        })
                                    }
                                    dropdownLinks={tournamentSubCategories?.reduce(
                                        (accumulator, subcategory) => [
                                            ...accumulator,
                                            subcategory,
                                        ],
                                        filterParams?.sub_category
                                            ? ['Odselektuj']
                                            : []
                                    )}
                                />
                            </Col>
                        )}

                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Tip organizovanja takmičenja'}
                                placeholder="Izaberi tip organizovanja..."
                                value={
                                    filterParams?.tournament_organisation_type ||
                                    ''
                                }
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        tournament_organisation_type:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentOrganisationTypes?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.tournament_organisation_type
                                        ? ['Odselektuj']
                                        : []
                                )}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => {
                                    {
                                        if (selectedTab === 'nadolazeca') {
                                            getComingCompetitions(
                                                1,
                                                filterParams
                                            )
                                            setShow(false)
                                        } else {
                                            getFinishedCompetitions(
                                                1,
                                                filterParams
                                            )
                                            setShow(false)
                                        }
                                    }
                                }}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={
                                    selectedTab === 'nadolazeca'
                                        ? handlePdfExportComing
                                        : handlePdfExportFinished
                                }
                            >
                                Štampaj rezultate
                            </Button>
                            <Button
                                type="text"
                                className="w-100 mt-3"
                                onClick={() => {
                                    setSelectedClub(null)
                                    setShow(false)
                                    handleDisableFiltering()
                                }}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}

export default CompetitionFiltersCanvas
