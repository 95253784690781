import React from 'react'
import Table from 'react-bootstrap/Table'
import { isArrayEmpty } from '../../lib/helpers'
import NoDataForView from '../NoDataForView'
import SingleTournamentRow from './SingleTournamentRow'
import './styles.scss'
import PaginationWrapper from '../Pagination'

const TableRefereeCompetitionsPagination = ({
    data,
    finished = false,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return !isArrayEmpty(data) ? (
        <div
            className={`table-referee-competitors-pagination-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        {finished && <th></th>}
                        <th style={{ minWidth: '140px' }}>
                            <h5>Naziv</h5>
                        </th>
                        <th style={{ minWidth: '140px' }}>
                            <h5>Organizator</h5>
                        </th>
                        <th style={{ minWidth: '140px' }}>
                            <h5>Odjaviti do</h5>
                        </th>
                        <th style={{ minWidth: '140px' }}>
                            <h5>Broj takmičara</h5>
                        </th>
                        <th style={{ minWidth: '103px' }}>
                            <h5>Kategorija</h5>
                        </th>
                        {!finished ? (
                            <th style={{ minWidth: '120px' }}>
                                <h5>Dezigniranje</h5>
                            </th>
                        ) : null}
                        {finished && (
                            <th style={{ minWidth: '170px' }}>
                                <h5>Status</h5>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((result, index) => (
                        <SingleTournamentRow
                            key={index}
                            result={result}
                            finished={finished}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    ) : (
        <NoDataForView
            title={`Nema ${finished ? 'završenih' : 'nadolazećih'} takmičenja`}
            description={`Ovde je prikazana lista ${
                finished ? 'završenih' : 'nadolazećih'
            } takmičenja.`}
            className="border-0"
        />
    )
}

export default TableRefereeCompetitionsPagination
