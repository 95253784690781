import { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../assets/svg/CloseOutlined'
import AsyncSelect from '../../../../components/AsyncSelect'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import Dropdown from '../../../../components/Dropdown'
import RadioButton from '../../../../components/RadioButton'
import TextField from '../../../../components/TextField'
import {
    addRefereeOnTournament,
    addTournamentAccommodation,
    addTournamentAdditionalInformations,
    createTournament,
    getCodebook,
    getSingleReferee,
    getSingleTournament,
    getTournamentAccommodation,
    getTournamentsAdditionalInformations,
    getTournamentsReferees,
    updateRefereeOnTournament,
    updateTournament,
    updateTournamentAccommodation,
    updateTournamentAdditionalInformations,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import {
    getClubList,
    getEntryPointsList,
    getRefereesList,
    handleDisableSaveButtonOnTournamentEdit,
    numbersOnly,
} from '../../../../lib/helpers'
import ScrollToTop from '../../../../lib/hooks/ScrollToTop'
import StepFive from './EditSteps/StepFive'
import StepFour from './EditSteps/StepFour'
import StepOne from './EditSteps/StepOne'
import StepThree from './EditSteps/StepThree'
import StepTwo from './EditSteps/StepTwo'
import './styles.scss'

const CreateOrEditTournament = ({ edit = false }) => {
    const participantsNumberArray = [4, 8, 16, 32, 48, 64, 128]

    let tournamentId
    if (edit) {
        let { id } = useParams()
        tournamentId = id
    }

    // ======= Context - Start ======= //
    const { setShowCustomToast } = useAppContext()

    //* Codebooks
    const [tournamentCompetitions, setTournamentCompetitions] = useState([])
    const [individualTournamentCategories, setIndividualTournamentCategories] =
        useState([])
    const [teamTournamentCompetitions, setTeamTournamentCompetitions] =
        useState([])
    const [tournamentTypes, setTournamentTypes] = useState([])
    const [tournamentHasQualifications, setTournamentHasQualifications] =
        useState(false)
    const [tournamentHasRounRobin, setTournamentHasRounRobin] = useState(false)
    const [tournamentHasGrandPrix, setTournamentHasGrandPrix] = useState(false)
    const [regions, setRegions] = useState([])

    const [courtTypes, setCourtTypes] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.players-competition').then((result) => {
            setTeamTournamentCompetitions(
                result.reduce((accumulator, currentValue) => {
                    if (
                        ['Seniorke', 'I Liga Seniori', '14', '18'].includes(
                            currentValue
                        )
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
            setTournamentCompetitions(
                result.reduce((accumulator, currentValue) => {
                    if (
                        !['Seniorke', 'I Liga Seniori', '45', '10'].includes(
                            currentValue
                        )
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })

        await getCodebook('codebooks.court-type').then((result) => {
            setCourtTypes(
                result.reduce((accumulator, currentValue) => {
                    if (
                        !['Zatvoreni', 'Pod reflektorima'].includes(
                            currentValue
                        )
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
        await getCodebook('codebooks.tournament-type', setTournamentTypes)
        await getCodebook(
            'codebooks.tournament-category',
            setIndividualTournamentCategories
        )
        await getCodebook('codebooks.region', setRegions)
    }

    let FormTitles = [
        'Osnovne informacije',
        'Ograničenja',
        'Detalji',
        'Kontakt',
        'Smeštaj',
        'Format takmičenja',
    ]

    //========== States - Start ==========//
    const [form, setForm] = useState({
        genders: [],
        qualifications_participants_expected_number: null,
        number_of_qualified: null,
    })
    const [additionalInformationsForm, setAdditionalInformationsForm] =
        useState({
            tournament_uuid: tournamentId,
        })
    const [accommodationForm, setAccommodationForm] = useState({
        tournament_uuid: tournamentId,
    })

    const [refereesForm, setRefeesForm] = useState()

    const [formTitles, setFormTitles] = useState(FormTitles)

    const navigate = useNavigate()
    const [step, setStep] = useState(1)

    const [maxSteps, setMaxSteps] = useState(8)
    const [saveButtonDisable, setSaveButtonDisable] = useState(true)

    const initialData = async () => {
        if (edit) {
            let tournament = await getSingleTournament(tournamentId)
            let accomodation = await getTournamentAccommodation(tournamentId)
            let additionalInformations =
                await getTournamentsAdditionalInformations(tournamentId)

            if (tournament) {
                setForm({
                    ...tournament?.attributes,
                    next_year_tournament_date: tournament?.attributes
                        ?.next_year_tournament_date
                        ? new Date(
                              tournament?.attributes?.next_year_tournament_date
                          )?.getFullYear()
                        : null,
                })
            }
            if (accomodation) {
                setAccommodationForm({
                    ...accomodation?.attributes,
                })
            } else {
                setAccommodationForm({
                    tournament_uuid: tournamentId,
                })
            }
            if (additionalInformations) {
                setAdditionalInformationsForm({
                    ...additionalInformations?.attributes,
                })
            } else {
                setAccommodationForm({
                    tournament_uuid: tournamentId,
                })
            }
        }
    }

    const handleNextOrSave = async () => {
        let tournamentData
        if (edit) {
            if (step === maxSteps) {
                try {
                    tournamentData = await updateTournament(tournamentId, {
                        ...form,
                        next_year_tournament_date:
                            form?.next_year_tournament_date
                                ? `${form?.next_year_tournament_date}-01-01`
                                : null,
                    })

                    if (refereesForm?.referee_admin?.uuid) {
                        try {
                            const referee_uuid =
                                refereesForm.referee_admin.referee_uuid
                            await updateRefereeOnTournament(
                                refereesForm?.referee_admin?.uuid,
                                {
                                    referee_uuid: referee_uuid,
                                }
                            )
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom ažuriranja vrhovnog sudije.',
                            })
                        }
                    } else {
                        try {
                            if (refereesForm?.referee_admin?.referee_uuid) {
                                await addRefereeOnTournament(
                                    refereesForm?.referee_admin
                                )
                            }
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom dodavanja vrhovnog sudije.',
                            })
                        }
                    }

                    if (refereesForm?.tournament_leader?.uuid) {
                        try {
                            const referee_uuid =
                                refereesForm.tournament_leader.referee_uuid
                            await updateRefereeOnTournament(
                                refereesForm?.tournament_leader?.uuid,
                                {
                                    referee_uuid: referee_uuid,
                                }
                            )
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom ažuriranja voditelja turnira.',
                            })
                        }
                    } else {
                        try {
                            if (refereesForm?.tournament_leader?.referee_uuid) {
                                await addRefereeOnTournament(
                                    refereesForm?.tournament_leader
                                )
                            }
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom dodavanja voditelja turnira.',
                            })
                        }
                    }

                    if (additionalInformationsForm?.uuid) {
                        try {
                            await updateTournamentAdditionalInformations(
                                additionalInformationsForm,
                                additionalInformationsForm?.uuid
                            )
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom ažuriranja dodatnih informacija.',
                            })
                        }
                    } else {
                        try {
                            await addTournamentAdditionalInformations(
                                additionalInformationsForm
                            )
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom dodavanja dodatnih informacija.',
                            })
                        }
                    }

                    if (accommodationForm?.uuid) {
                        try {
                            await updateTournamentAccommodation(
                                accommodationForm,
                                accommodationForm?.uuid
                            )
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom ažuriranja smeštaja.',
                            })
                        }
                    } else {
                        try {
                            await addTournamentAccommodation(accommodationForm)
                        } catch (error) {
                            console.error(error)
                            setShowCustomToast({
                                show: true,
                                type: 'error',
                                message:
                                    'Došlo je do greške prilikom dodavanja smeštaja.',
                            })
                        }
                    }
                } catch (error) {
                    console.error(error)
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške prilikom ažuriranja.',
                    })
                }
            } else {
                setStep((prev) => prev + 1)
            }
        } else {
            try {
                tournamentData = await createTournament({
                    ...form,
                    next_year_tournament_date: form?.next_year_tournament_date
                        ? `${form?.next_year_tournament_date}-01-01`
                        : null,
                })

                if (!tournamentData) return

                if (refereesForm?.referee_admin?.referee_uuid) {
                    try {
                        await addRefereeOnTournament({
                            ...refereesForm?.referee_admin,
                            tournament_uuid: tournamentData?.attributes?.uuid,
                        })
                    } catch (error) {
                        console.error(error)
                        setShowCustomToast({
                            show: true,
                            type: 'error',
                            message:
                                'Došlo je do greške prilikom dodavanja vrhovnog sudije.',
                        })
                    }
                }

                if (refereesForm?.tournament_leader?.referee_uuid) {
                    try {
                        await addRefereeOnTournament({
                            ...refereesForm?.tournament_leader,
                            tournament_uuid: tournamentData?.attributes?.uuid,
                        })
                    } catch (error) {
                        console.error(error)
                        setShowCustomToast({
                            show: true,
                            type: 'error',
                            message:
                                'Došlo je do greške prilikom dodavanja voditelja turnira.',
                        })
                    }
                }
            } catch (error) {
                console.error(error)
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Došlo je do greške prilikom kreiranja takmičenja.',
                })
            }
        }

        if (tournamentData) {
            if (tournamentId) {
                navigate(`/takmicenja/${tournamentId}`)
            } else {
                navigate('/takmicenja')
            }
            setShowCustomToast({
                show: true,
                type: 'success',
                message: edit
                    ? 'Takmičenje uspešno ažurirano.'
                    : 'Takmičenje uspešno kreirano.',
            })
        }
    }

    const handleBackOrCancel = () => {
        if (edit) {
            step === 1 ? exitForm() : setStep((prev) => step - 1)
        } else exitForm()
    }

    const exitForm = () => {
        navigate(-1)
    }

    useEffect(() => {
        //This is here for resetting form titles if format is changed and some sub selections changes
        setFormTitles([
            'Osnovne informacije',
            'Ograničenja',
            'Detalji',
            'Kontakt',
            'Smeštaj',
            'Format takmičenja',
        ])
        //This is here for resetting form titles if format is changed and some sub selections changes
        setMaxSteps(5)
    }, [form?.tournament_type?.tournament_type_name])

    useEffect(() => {
        handleDisableSaveButtonOnTournamentEdit(form, setSaveButtonDisable)
    }, [form])

    //========== Get Initial Data - Start ========== //
    useEffect(() => {
        initialData()
        handleCodebooks()
    }, [])

    const handleTournamentReferees = async () => {
        let tournamentRefereesCollectionUuid = form?.uuid
            ? form?.uuid
            : tournamentId
            ? tournamentId
            : null
        if (!tournamentRefereesCollectionUuid) return
        const tournamentReferees = await getTournamentsReferees(
            tournamentRefereesCollectionUuid
        )

        if (tournamentReferees) {
            const refereeAdminData = tournamentReferees.find(
                (referee) =>
                    referee?.attributes?.referee_type?.referee_type_name ===
                    'Vrhovni sudija'
            )
            const leaderData = tournamentReferees.find(
                (referee) =>
                    referee?.attributes?.referee_type?.referee_type_name ===
                    'Voditelj turnira'
            )

            let tournamentRefereeAdminData
            let tournamentLeaderData

            if (refereeAdminData) {
                tournamentRefereeAdminData = await getSingleReferee(
                    refereeAdminData?.attributes?.referee_uuid
                )
            }

            if (leaderData) {
                tournamentLeaderData = await getSingleReferee(
                    leaderData?.attributes?.referee_uuid
                )
            }

            if (edit) {
                setRefeesForm({
                    referee_admin: {
                        ...refereeAdminData?.attributes,
                        referee: {
                            ...tournamentRefereeAdminData?.attributes,
                        },
                    },
                    tournament_leader: {
                        ...leaderData?.attributes,
                        referee: {
                            ...tournamentLeaderData?.attributes,
                        },
                    },
                })
            } else {
                setRefeesForm({
                    referee_admin: {
                        ...refereeAdminData?.attributes,
                    },
                    tournament_leader: {
                        ...leaderData?.attributes,
                    },
                })
            }
        }
    }

    useEffect(() => {
        handleTournamentReferees()
    }, [])

    const CreateForm = () => (
        <Row className="create-new-competition-form-wrapper">
            <Col md={12} className="mt-2">
                <RadioButton
                    required
                    name="Tip"
                    radioValues={tournamentTypes}
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            tournament_type: {
                                tournament_type_name: e.target.value,
                            },
                        }))
                    }}
                    className="type-radio-buttons-wrapper"
                    checkedValue={form?.tournament_type?.tournament_type_name}
                />
            </Col>

            <Col md={12} className="mt-2">
                <Dropdown
                    dropdownLinks={courtTypes}
                    placeholder="Tip"
                    value={form?.court_type?.court_type_name || ''}
                    handleSelect={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            court_type: {
                                court_type_name: e,
                            },
                        }))
                    }
                    label={'Podloga'}
                />
            </Col>
            <Col md={12} className="mt-2">
                <AsyncSelect
                    loadOptions={getRefereesList}
                    onChange={(e) =>
                        setRefeesForm((prevState) => ({
                            ...prevState,
                            referee_admin: {
                                ...prevState?.referee_admin,
                                referee_uuid: e?.uuid,
                                referee_type: {
                                    referee_type_name: 'Vrhovni sudija',
                                },
                            },
                        }))
                    }
                    label={'Vrhovni sudija'}
                    placeholder={
                        refereesForm?.referee_admin?.referee?.person_info
                            ?.given_name
                            ? refereesForm?.referee_admin?.referee?.person_info
                                  ?.given_name +
                              ' ' +
                              refereesForm?.referee_admin?.referee?.person_info
                                  ?.family_name
                            : 'Pretraži i izaberi sudiju...'
                    }
                    noOptionsMessage={'Traženi sudija ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                />
            </Col>

            {form?.tournament_type?.tournament_type_name === 'Ekipno' && (
                <Col md={12} className="mt-2">
                    <AsyncSelect
                        loadOptions={getRefereesList}
                        onChange={(e) =>
                            setRefeesForm((prevState) => ({
                                ...prevState,
                                tournament_leader: {
                                    ...prevState?.tournament_leader,
                                    referee_uuid: e?.uuid,
                                    referee_type: {
                                        referee_type_name: 'Voditelj turnira',
                                    },
                                },
                            }))
                        }
                        label={'Voditelj turnira'}
                        placeholder={
                            refereesForm?.tournament_leader?.referee
                                ?.person_info?.given_name
                                ? refereesForm?.tournament_leader?.referee
                                      ?.person_info?.given_name +
                                  ' ' +
                                  refereesForm?.tournament_leader?.referee
                                      ?.person_info?.family_name
                                : 'Pretraži i izaberi sudiju...'
                        }
                        noOptionsMessage={'Traženi sudija ne postoji'}
                        closeMenuOnSelect={true}
                        isMulti={false}
                    />
                </Col>
            )}

            <Col md={12} className="mt-3">
                <AsyncSelect
                    loadOptions={getEntryPointsList}
                    onChange={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            entry_points_uuid: e.uuid,
                        }))
                    }
                    label={'Šablon bodovanja'}
                    placeholder={'Izaberi šablon bodovanja...'}
                    noOptionsMessage={'Traženi šablon ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    required
                    label="Naziv takmičenja"
                    placeholder="Naziv takmičenja"
                    id="tournament-name"
                    name="name"
                    onChange={(e) => {
                        setForm((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                        }))
                    }}
                    value={form?.name || ''}
                />
            </Col>

            <Col md={12} className="mt-3">
                <Dropdown
                    label={'Region'}
                    value={form?.region?.region_name || ''}
                    placeholder="Region"
                    handleSelect={(e) =>
                        setForm((prev) => ({
                            ...prev,
                            region: {
                                region_name: e === 'Odselektuj' ? '' : e,
                            },
                        }))
                    }
                    dropdownLinks={
                        regions
                            ? regions
                                  .filter((region) => region !== 'TSS')
                                  .reduce(
                                      (accumulator, region) => [
                                          ...accumulator,
                                          region,
                                      ],
                                      form?.region?.region_name
                                          ? ['Odselektuj']
                                          : []
                                  )
                            : []
                    }
                />
            </Col>

            <Col md={12} className="mt-3">
                <Dropdown
                    label={'Tip organizovanja takmičenja'}
                    placeholder="Izaberi tip organizovanja..."
                    dropdownLinks={['Međunarodni', 'Domaći', 'Regionalni']}
                    handleSelect={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            tournament_organisation_type: {
                                tournament_organisation_type_name: e,
                            },
                        }))
                    }
                    value={
                        form?.tournament_organisation_type
                            ?.tournament_organisation_type_name
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <AsyncSelect
                    loadOptions={getClubList}
                    onChange={(e) =>
                        setForm((prevState) => ({
                            ...prevState,
                            club_organizer_uuid: e.uuid,
                        }))
                    }
                    label={'Klub organizator/domaćin'}
                    placeholder={'Pretraži i izaberi klub...'}
                    noOptionsMessage={'Traženi klub ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    required
                />
            </Col>
            {form?.tournament_type?.tournament_type_name && (
                <>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            required
                            label={'Konkurencija'}
                            placeholder="Izaberi konkurenciju..."
                            dropdownLinks={
                                form?.tournament_type?.tournament_type_name ===
                                'Individualno'
                                    ? tournamentCompetitions
                                    : teamTournamentCompetitions
                            }
                            handleSelect={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    players_competition: {
                                        players_competition_name: e,
                                    },
                                }))
                            }
                            value={
                                form?.players_competition
                                    ?.players_competition_name
                            }
                        />
                    </Col>
                    {form?.players_competition?.players_competition_name ===
                        '10s' && (
                        <Col md={12} className="mt-3">
                            <Dropdown
                                required
                                label={'Vrsta takmičarske legitimacije'}
                                placeholder="Izaberi takmičarsku legitimaciju..."
                                dropdownLinks={[
                                    'Crveni',
                                    'Narandžasti',
                                    'Zeleni',
                                ]}
                                handleSelect={(e) =>
                                    setForm((prevState) => ({
                                        ...prevState,
                                        players_competition: {
                                            ...prevState.players_competition,
                                            players_competition_subcategory_name:
                                                e,
                                        },
                                    }))
                                }
                                value={
                                    form?.players_competition
                                        ?.players_competition_subcategory_name
                                }
                            />
                        </Col>
                    )}
                    {form?.tournament_type?.tournament_type_name ===
                        'Individualno' && (
                        <Col md={12} className="mt-3">
                            <Dropdown
                                required
                                label={'Kategorija turnira'}
                                placeholder="Izaberi kategoriju..."
                                dropdownLinks={individualTournamentCategories}
                                handleSelect={(e) =>
                                    setForm((prevState) => ({
                                        ...prevState,
                                        doubles_pairs_expected_number: null,
                                        participants_expected_number: null,
                                        qualifications_participants_expected_number:
                                            null,
                                        number_of_qualified: null,
                                        tournament_category: {
                                            tournament_category_name: e,
                                        },
                                    }))
                                }
                                value={
                                    form?.tournament_category
                                        ?.tournament_category_name
                                }
                            />
                        </Col>
                    )}

                    {!tournamentHasRounRobin && !tournamentHasGrandPrix && (
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={
                                    'Predviđeni broj učesnika glavnog turnira'
                                }
                                placeholder="Izaberi predviđeni broj učesnika..."
                                dropdownLinks={participantsNumberArray}
                                handleSelect={(e) =>
                                    setForm((prevState) => ({
                                        ...prevState,
                                        participants_expected_number: +e,
                                    }))
                                }
                                value={form?.participants_expected_number}
                            />
                        </Col>
                    )}

                    <Col md={12} className="mt-3">
                        <TextField
                            label="Broj pozivnica za glavni turnir (WC)"
                            placeholder="Broj pozivnica za glavni turnir (WC)"
                            id="number-of-wc"
                            name="number-of-wc"
                            type="tel"
                            onChange={(e) => {
                                numbersOnly(e)
                                setForm((prevState) => ({
                                    ...prevState,
                                    wc_number: e.target.value,
                                }))
                            }}
                            value={form?.wc_number || ''}
                        />
                    </Col>

                    {form?.tournament_type?.tournament_type_name ===
                        'Individualno' && (
                        <Col xs={12} className="mt-3">
                            <Checkbox
                                checked={
                                    tournamentHasQualifications
                                        ? 'Turnir ima kvalifikacije'
                                        : ''
                                }
                                onChange={() => {
                                    setTournamentHasQualifications(
                                        (prev) => !prev
                                    )
                                    setForm((prevState) => ({
                                        ...prevState,
                                        qualifications_participants_expected_number:
                                            null,
                                        number_of_qualified: null,
                                        wc_qualification_number: null,
                                    }))
                                }}
                                label={'Turnir ima kvalifikacije'}
                            />
                        </Col>
                    )}
                    {tournamentHasQualifications && (
                        <>
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Broj učesnika u kvalifikacijama'}
                                    placeholder="Unesi broj učesnika u kvalifikacijama..."
                                    dropdownLinks={participantsNumberArray}
                                    handleSelect={(e) =>
                                        setForm((prevState) => ({
                                            ...prevState,
                                            qualifications_participants_expected_number:
                                                e === 'Odselektuj' ? null : +e,
                                        }))
                                    }
                                    value={
                                        form?.qualifications_participants_expected_number
                                    }
                                />
                            </Col>
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Broj kvalifikanata'}
                                    placeholder="Unesi broj kvalifikanata..."
                                    dropdownLinks={
                                        form?.number_of_qualified
                                            ? ['Odselektuj', 1, 2, 4, 8, 16]
                                            : [1, 2, 4, 8, 16]
                                    }
                                    handleSelect={(e) =>
                                        setForm((prevState) => ({
                                            ...prevState,
                                            number_of_qualified:
                                                e === 'Odselektuj' ? null : +e,
                                        }))
                                    }
                                    value={form?.number_of_qualified}
                                />
                            </Col>

                            <Col md={12} className="mt-3">
                                <TextField
                                    label="Broj pozivnica za kvalifikacije (WCQ)"
                                    placeholder="Broj pozivnica za kvalifikacije (WCQ)"
                                    id="number-of-wcq"
                                    name="number-of-wcq"
                                    type="tel"
                                    onChange={(e) => {
                                        numbersOnly(e)
                                        setForm((prevState) => ({
                                            ...prevState,
                                            wc_qualification_number:
                                                e.target.value,
                                        }))
                                    }}
                                    value={form?.wc_qualification_number || ''}
                                />
                            </Col>
                        </>
                    )}

                    <Col xs={12} className="mt-3">
                        <Checkbox
                            checked={
                                tournamentHasRounRobin
                                    ? 'Turnir ima Round Robin'
                                    : ''
                            }
                            onChange={() => {
                                setTournamentHasRounRobin((prev) => !prev)
                                setForm((prevState) => ({
                                    ...prevState,
                                    number_of_groups: null,
                                    participants_expected_number: null,
                                    tournament_have_round_roubin:
                                        !prevState?.tournament_have_round_roubin,
                                }))
                            }}
                            label={'Turnir ima Round Robin'}
                        />
                    </Col>

                    {form?.tournament_type?.tournament_type_name ===
                        'Individualno' && (
                        <Col xs={12} className="mt-3">
                            <Checkbox
                                checked={tournamentHasGrandPrix}
                                onChange={() => {
                                    setTournamentHasGrandPrix((prev) => !prev)
                                    setForm((prevState) => ({
                                        ...prevState,
                                        tournament_have_grand_prix:
                                            !prevState?.tournament_have_grand_prix,
                                        participants_expected_number: null,
                                    }))
                                }}
                                label={'Turnir ima Srbija Gran Pri'}
                            />
                        </Col>
                    )}

                    {tournamentHasGrandPrix && (
                        <>
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Potkategorija turnira'}
                                    placeholder="Izaberi potkategriju turnira..."
                                    dropdownLinks={['A', 'B', 'C']}
                                    handleSelect={(e) =>
                                        setForm((prevState) => ({
                                            ...prevState,
                                            tournament_category: {
                                                ...prevState.tournament_category,
                                                tournament_subcategory_name: e,
                                            },
                                        }))
                                    }
                                    value={
                                        form?.tournament_category
                                            ?.tournament_subcategory_name
                                    }
                                />
                            </Col>
                        </>
                    )}

                    {form?.tournament_type?.tournament_type_name ===
                        'Individualno' && (
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Broj dubl parova'}
                                placeholder="Unesi broj dubl parova..."
                                dropdownLinks={
                                    form?.doubles_pairs_expected_number
                                        ? [
                                              'Odselektuj',
                                              ...participantsNumberArray,
                                          ]
                                        : [...participantsNumberArray]
                                }
                                handleSelect={(e) =>
                                    setForm((prevState) => ({
                                        ...prevState,
                                        doubles_pairs_expected_number:
                                            e === 'Odselektuj' ? null : +e,
                                    }))
                                }
                                value={form?.doubles_pairs_expected_number}
                            />
                        </Col>
                    )}

                    <Col md={12} className="my-3">
                        <div className="d-flex">
                            <h5 className="mb-2">Pol</h5>
                            <h6 className="required ms-1">*</h6>
                        </div>

                        <Checkbox
                            checked={
                                form?.genders?.find((gender) =>
                                    gender?.gender_name === 'Muški'
                                        ? true
                                        : false
                                ) || false
                            }
                            onChange={() => {
                                form?.genders?.find((gender) =>
                                    gender?.gender_name === 'Muški'
                                        ? true
                                        : false
                                )
                                    ? setForm((prevState) => ({
                                          ...prevState,
                                          genders: form?.genders?.filter(
                                              (item) =>
                                                  item?.gender_name !== 'Muški'
                                          ),
                                      }))
                                    : setForm((prevState) => ({
                                          ...prevState,
                                          genders: [
                                              ...prevState?.genders,
                                              { gender_name: 'Muški' },
                                          ],
                                      }))
                            }}
                            label={'Muški'}
                        />
                        <Checkbox
                            checked={
                                form?.genders?.find((gender) =>
                                    gender?.gender_name === 'Ženski'
                                        ? true
                                        : false
                                ) || false
                            }
                            onChange={() => {
                                form?.genders?.find((gender) =>
                                    gender?.gender_name === 'Ženski'
                                        ? true
                                        : false
                                )
                                    ? setForm((prevState) => ({
                                          ...prevState,
                                          genders: form?.genders?.filter(
                                              (item) =>
                                                  item?.gender_name !== 'Ženski'
                                          ),
                                      }))
                                    : setForm((prevState) => ({
                                          ...prevState,
                                          genders: [
                                              ...prevState?.genders,
                                              { gender_name: 'Ženski' },
                                          ],
                                      }))
                            }}
                            label={'Ženski'}
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            required
                            type="date"
                            name="start_date"
                            onChange={(e) => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    start_date: e.target.value,
                                }))
                            }}
                            label="Početak takmičenja"
                            value={form?.start_date || ''}
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            required
                            type="date"
                            name="application_deadline_date"
                            label="Krajnji datum za prijavu"
                            onChange={(e) => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    application_deadline_date: e.target.value,
                                }))
                            }}
                            value={form?.application_deadline_date || ''}
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            required
                            type="date"
                            name="checkout_deadline_date"
                            label="Krajnji datum za odjavu"
                            onChange={(e) => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    checkout_deadline_date: e.target.value,
                                }))
                            }}
                            value={form?.checkout_deadline_date || ''}
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <TextField
                            type="number"
                            name="next_year_tournament_date"
                            label="Godina takmičenja"
                            onChange={(e) => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    next_year_tournament_date: e.target.value,
                                }))
                            }}
                            value={form?.next_year_tournament_date || ''}
                        />

                        <p
                            className="mt-1"
                            style={{
                                fontSize: '12px',
                                color: 'gray',
                            }}
                        >
                            Važi za turnire koji se odigravaju u trenutnoj, ali
                            se računaju za sledeċu godinu
                        </p>
                    </Col>
                </>
            )}
        </Row>
    )

    const FormHandler = () => {
        if (edit) {
            switch (step) {
                case 1:
                    return (
                        <StepOne
                            tournament_types={tournamentTypes}
                            getClubList={getClubList}
                            tournament_competitions={tournamentCompetitions}
                            form={form}
                            setForm={setForm}
                            team_tournament_competitions={
                                teamTournamentCompetitions
                            }
                            individual_tournament_categories={
                                individualTournamentCategories
                            }
                            getEntryPointsList={getEntryPointsList}
                            refereesForm={refereesForm}
                            setRefeesForm={setRefeesForm}
                            regions={regions}
                            courtTypes={courtTypes}
                        />
                    )
                case 2:
                    return (
                        <StepTwo
                            form={form}
                            setForm={setForm}
                            additionalInformationsForm={
                                additionalInformationsForm
                            }
                            setAdditionalInformationsForm={
                                setAdditionalInformationsForm
                            }
                        />
                    )
                case 3:
                    return (
                        <StepThree
                            additionalInformationsForm={
                                additionalInformationsForm
                            }
                            setAdditionalInformationsForm={
                                setAdditionalInformationsForm
                            }
                        />
                    )
                case 4:
                    return <StepFour form={form} setForm={setForm} />
                case 5:
                    return (
                        <StepFive
                            accommodationForm={accommodationForm}
                            setAccommodationForm={setAccommodationForm}
                        />
                    )
                default:
                    return (
                        <StepOne
                            tournament_types={tournamentTypes}
                            getClubList={getClubList}
                            tournament_competitions={tournamentCompetitions}
                            form={form}
                            setForm={setForm}
                            setStep={setStep}
                            updateForm={() => {}}
                            filteredCategories={individualTournamentCategories}
                            refereesForm={refereesForm}
                            setRefeesForm={setRefeesForm}
                            regions={regions}
                        />
                    )
            }
        } else {
            return CreateForm()
        }
    }

    const secondaryButtonText = () =>
        edit ? (step === 1 ? 'Odustani' : 'Prethodna') : 'Odustani'
    const primaryButtonText = () =>
        edit ? (step !== maxSteps ? 'Sledeća' : 'Sačuvaj') : 'Sačuvaj'
    const titleHandler = () =>
        edit ? formTitles[step - 1] : 'Kreiranje takmičenja'

    return (
        <Row className="create-new-competition-wrapper justify-content-center">
            <Col className="create-competition-wrapper px-0">
                <Row className="justify-content-center">
                    {edit ? (
                        <ProgressBar
                            now={(step / maxSteps) * 100}
                            className="px-0"
                        />
                    ) : null}
                    <Col className="d-flex flex-column align-items-center justify-content-end close-icon mt-2 mt-md-3 me-md-3">
                        <Row className="w-100 align-items-center justify-content-end">
                            <div
                                className="d-flex justify-content-end"
                                onClick={() => navigate(-1)}
                            >
                                <CloseOutlined />
                            </div>
                        </Row>
                    </Col>
                    <Row className="competition-form-wrapper justify-content-center">
                        <Col className="form-columns p-md-0">
                            <Col className="mt-3">
                                <Col
                                    md={12}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <h1 className="text-center">
                                        {titleHandler()}
                                    </h1>
                                </Col>
                                {edit ? (
                                    <Col
                                        md={12}
                                        className="mt-2 d-flex align-items-center justify-content-center"
                                    >
                                        <p className="small-text">
                                            {`${step}/${maxSteps}`}
                                        </p>
                                    </Col>
                                ) : null}
                                {/* content based on component */}
                                <Col className="mt-3 align-items-center justify-content-center">
                                    <ScrollToTop step={step} />
                                    {FormHandler()}
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-3 d-flex flex-md-row flex-column align-items-center justify-content-end form-footer-buttons"
                                >
                                    <Button
                                        type="text"
                                        className="footer-button"
                                        onClick={handleBackOrCancel}
                                    >
                                        {secondaryButtonText()}
                                    </Button>
                                    <Button
                                        className="footer-button"
                                        onClick={handleNextOrSave}
                                        disabled={saveButtonDisable}
                                    >
                                        {primaryButtonText()}
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default CreateOrEditTournament
