import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import { getPlayersTournaments } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import FinishedTournaments from '../AllTournaments/Finished'
import UpcomingTournamets from '../AllTournaments/Upcoming'

const Tournaments = () => {
    const { user } = useAppContext()

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const getComingCompetitions = async (page) => {
        const { response, pgCount, total } = await getPlayersTournaments({
            uuid: user?.data?.attributes?.uuid,
            query: `filters[$and][0][is_finished][$ne]=true&pagination[pageSize]=${rowsPerPage}&pagination[page]=${page}`,
        })

        if (!response) return

        setComingCompetitions(response)
        setPageCount(pgCount)
        setTotal(total)
        setCurrentPage(page)
    }

    const getAllFinishedCompetitions = async (page) => {
        const { response, pgCount, total } = await getPlayersTournaments({
            uuid: user?.data?.attributes?.uuid,
            query: `filters[$and][0][is_finished]=true&pagination[pageSize]=${rowsPerPage}&pagination[page]=${page}`,
        })

        if (!response) return

        setFinishedCompetitions(response)
        setPageCount(pgCount)
        setTotal(total)
        setCurrentPage(page)
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
    }, [currentPage, selectedTab, user, rowsPerPage])

    const handleTabSelect = (tab) => {
        setCurrentPage(1)
        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1)
                : getAllFinishedCompetitions(1)
            return tab
        })
    }

    const handleTabState = (selectedTab) => {
        switch (selectedTab) {
            case 'nadolazeca':
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        profilePage
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            case 'zavrsena':
                return (
                    <FinishedTournaments
                        tournaments={finishedCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        profilePage
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            default:
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
        }
    }

    return (
        <Col className="competitor-tournaments">
            <Tabs
                id="competition-selection"
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                onSelect={(tab) => {
                    handleTabSelect(tab)
                }}
            >
                <Tab
                    key="nadolazeca"
                    eventKey="nadolazeca"
                    title={<p>Nadolazeća</p>}
                    tabClassName="d-flex"
                ></Tab>
                <Tab
                    key="zavrsena"
                    eventKey="zavrsena"
                    title={<p>Završena</p>}
                    tabClassName="d-flex"
                ></Tab>
            </Tabs>
            <Row className="m-0  pt-3">{handleTabState(selectedTab)}</Row>
        </Col>
    )
}

export default Tournaments
