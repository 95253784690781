import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accessibility from '../../../assets/svg/Accessibility'
import Female from '../../../assets/svg/Female'
import FilledBed from '../../../assets/svg/FilledBed'
import FilledCalendar from '../../../assets/svg/FilledCalendar'
import FilledTennisBall from '../../../assets/svg/FilledTennisBall'
import GameController from '../../../assets/svg/GameController'
import InformationCircle from '../../../assets/svg/InformationCircle'
import Male from '../../../assets/svg/Male'
import People from '../../../assets/svg/People'
import Person from '../../../assets/svg/Person'
import PrintOutlined from '../../../assets/svg/PrintOutlined'
import Wallet from '../../../assets/svg/Wallet'
import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import CompetitionClubsCardParticipants from '../../../components/CompetitionClubsCardParticipants'
import CompetitionClubsParticipants from '../../../components/CompetitionClubsParticipants'
import CompetitionsParticipantsTable from '../../../components/CompetitionParticipantsTable'
import CompetitionsParticipantsCard from '../../../components/CompetitionsTableCards/CompetitionsParticipantsCard'
import IconInfoCard from '../../../components/IconInfoCard'
import NoDataForView from '../../../components/NoDataForView'
import { getSingleTournament } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import { generateAvatar } from '../../../utils/generateAvatar'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import RefereeCard from '../../../components/RefereeCard'
import { font } from '../../Admin/Competitions/CompetitionProfile/font'
import './styles.scss'

import MaleFemale from '../../../assets/svg/MaleFemale'
import {
    formatDate,
    formatImgUrl,
    handleArrayDetail,
    handleShowDetail,
    handleShowYesNo,
    isArrayEmpty,
    isObjectEmpty,
} from '../../../lib/helpers'
import PaginationWrapper from '../../../components/Pagination'

function RefereeAdminSingleCompetition({ type = false }) {
    const { id } = useParams()
    const { user } = useAppContext()
    const navigate = useNavigate()
    const [competitionProfileData, setCompetitionProfileData] = useState()
    const [selectedTab, setSelectedTab] = useState('informacije')
    const [mainReferee, setMainReferee] = useState({})
    const [otherReferees, setOtherReferees] = useState([])

    const handleInitCompetitionData = async () => {
        const data = await getSingleTournament(id)

        if (data) {
            setCompetitionProfileData(data)

            if (!isObjectEmpty(data?.attributes?.main_referee_id)) {
                setMainReferee(data?.attributes?.main_referee_id)
            }
            if (!isArrayEmpty(data?.attributes?.other_referees)) {
                setOtherReferees(data?.attributes?.other_referees)
            }
        }
    }

    useEffect(() => {
        handleInitCompetitionData()
    }, [])

    // Exporting PDFs functions
    const createDocInfo = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = competitionProfileData?.attributes?.name
        doc.text(title, marginLeft, 40)

        const organizerData = [
            [
                'Naziv',
                competitionProfileData?.attributes?.club_organizer_id?.data
                    ?.attributes?.name,
            ],
            [
                'Adresa',
                competitionProfileData?.attributes?.club_organizer_id?.data
                    ?.attributes?.address,
            ],
            [
                'Grad',
                competitionProfileData?.attributes?.club_organizer_id?.data
                    ?.attributes?.city,
            ],
        ]

        let organizerContent = {
            startY: 50,
            head: [['Organizator', ' ']],
            body: organizerData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(organizerContent)

        ///////////////////////////////////////////

        let importantDatesData = [
            [
                'Početak takmičenja',
                formatDate(competitionProfileData?.attributes?.start_date),
            ],
            [
                'Krajnji datum za prijavu',
                formatDate(
                    competitionProfileData?.attributes?.application_last_date
                ),
            ],
            [
                'Krajnji datum za odjavu',
                formatDate(
                    competitionProfileData?.attributes?.checkout_last_date
                ),
            ],
        ]

        let importantDatesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Važni datumi', ' ']],
            body: importantDatesData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(importantDatesContent)

        ///////////////////////////////////////////

        let tournamentData = [
            ['Pol', competitionProfileData?.attributes?.gender],
            [
                'Kotizacija',
                competitionProfileData?.attributes?.competition_info?.details
                    ?.registration_fee
                    ? competitionProfileData?.attributes?.competition_info
                          ?.details?.registration_fee + ' RSD'
                    : '',
            ],
            ['Tip takmičenja', competitionProfileData?.attributes?.type],
            [
                'Format takmičenja',
                competitionProfileData?.attributes?.competition_info?.format,
            ],
            [
                'Kvalifikacije',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.qualifications
                ),
            ],
            ['Kategorija', competitionProfileData?.attributes?.category],
            [
                'Dubl',

                competitionProfileData?.attributes?.competition_info?.double ===
                false
                    ? 'NE'
                    : 'DA',
            ],
            ['Region', competitionProfileData?.attributes?.region],

            [
                'Marka lopti',
                competitionProfileData?.attributes?.competition_info?.details
                    ?.ball_brand,
            ],
            [
                'Promena lopti',
                competitionProfileData?.attributes?.competition_info?.details
                    ?.ball_change,
            ],
            [
                'Dodatne informacije',
                competitionProfileData?.attributes?.competition_info?.details
                    ?.more_details,
            ],
        ]

        let tournamentContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Informacije o takmičenju', ' ']],
            body: tournamentData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(tournamentContent)

        ///////////////////////////////////////////

        let contactPersonData = [
            [
                'Ime i prezime',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.contact_person,
            ],
            [
                'Telefon',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.contact_person_phone,
            ],
            [
                'Email',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.contact_person_email,
            ],
        ]

        let contactPersonContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Kontakt osoba', ' ']],
            body: contactPersonData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.competition_info?.contact
            ?.contact_person && doc.autoTable(contactPersonContent)
        ////////////////////////////////////
        let leaderData = [
            [
                'Ime i prezime',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.tournament_leader,
            ],
            [
                'Telefon',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.tournament_leader_phone,
            ],
            [
                'Email',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.tournament_leader_email,
            ],
        ]

        let leaderDataContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Voditelj takmičenja', ' ']],
            body: leaderData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.competition_info?.contact
            ?.tournament_leader && doc.autoTable(leaderDataContent)

        ///////////////////////////////////////////

        let tournamentDirectorData = [
            [
                'Ime i prezime',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.director,
            ],
            [
                'Telefon',
                competitionProfileData?.attributes?.competition_info?.contact
                    ?.director_phone,
            ],
            [
                'Email',
                competitionProfileData?.competition_info?.contact
                    ?.director_email,
            ],
        ]

        let tournamentDirectorContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Direktor takmičenja', ' ']],
            body: tournamentDirectorData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.competition_info?.contact
            ?.director && doc.autoTable(tournamentDirectorContent)

        ///////////////////////////////////////////
        let accommodationData = [
            [
                'Adresa',
                competitionProfileData?.attributes?.competition_info
                    ?.accommodation?.address,
            ],
            [
                'Grad',
                competitionProfileData?.attributes?.competition_info
                    ?.accommodation?.city,
            ],
            [
                'Telefon',
                competitionProfileData?.attributes?.competition_info
                    ?.accommodation?.phone,
            ],
            [
                'Email',
                competitionProfileData?.attributes?.competition_info
                    ?.accommodation?.email,
            ],
            [
                'Dodatne informacije',
                competitionProfileData?.attributes?.competition_info
                    ?.accommodation?.more_info,
            ],
        ]

        let accommodationContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Smeštaj', ' ']],
            body: accommodationData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(accommodationContent)

        return doc
    }

    // Export PDF for Profile
    const handleExportCompetitionPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDocInfo(orientation, unit, size, font)

        doc.save('Takmičenje.pdf')
    }

    // Print for Profile
    const handlePrintCompetition = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const doc = createDocInfo(orientation, unit, size, font)
        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const createDocCompetitors = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${competitionProfileData?.attributes?.name} - Status`
        doc.text(title, marginLeft, 40)

        if (competitionProfileData?.attributes?.type === 'Individualno') {
            const rows = []

            const competitors =
                competitionProfileData?.attributes?.competitors?.data
            const competitorStatuses =
                competitionProfileData?.attributes?.competitor_statuses

            for (let i = 0; i < competitors.length; i++) {
                const competitor = competitors[i]
                const competitorCode = competitor?.attributes?.code
                const competitorStatus =
                    competitorStatuses.find(
                        (status) => status.competitor_id === competitor.id
                    )?.status || ''
                const competitorName = `${competitor?.attributes?.name} ${competitor?.attributes?.surname}`
                const competitorBirthdate = competitor.attributes.birthdate
                // const competitorRang = competitor?.attributes?.tss_rank
                //     ? competitor.attributes.tss_rank
                //     : '/'
                const competitorKlubName =
                    competitor?.attributes?.club_id?.data?.attributes?.name

                const rowData = [
                    competitorName,
                    competitorCode,
                    competitorKlubName,
                    formatDate(competitorBirthdate),
                    competitorRang,
                    competitorStatus,
                ]
                rows.push(rowData)
            }

            let competitorsContent = {
                startY: 50,
                head: [
                    [
                        'Takmičar',
                        'Šifra',
                        'Klub',
                        'Datum rođenja',
                        // 'TSS rang',
                        'Status',
                    ],
                ],
                body: rows,
                styles: {
                    font: 'WorkSans',
                },
            }

            doc.autoTable(competitorsContent)
        } else {
            const rows = []

            const club = competitionProfileData?.attributes?.clubs?.data
            if (club) {
                for (let i = 0; i < club.length; i++) {
                    const clubs = club[i]
                    const clubName = clubs?.attributes?.name
                    const clubRegion = clubs?.attributes?.region
                    const clubCity = clubs?.attributes?.city
                    const clubStatus = clubs?.attributes?.status

                    const rowData = [clubName, clubRegion, clubCity, clubStatus]
                    rows.push(rowData)
                }
            }

            let clubsContent = {
                startY: 50,
                head: [['Klub', 'Region', 'Sedište', 'Status']],
                body: rows,
                styles: {
                    font: 'WorkSans',
                },
            }

            doc.autoTable(clubsContent)
        }

        return doc
    }

    // Export PDF for Competitors
    const handleExportCompetitorsPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDocCompetitors(orientation, unit, size, font)

        doc.save('Status.pdf')
    }

    // Print for Competitors

    const handlePrintCompetitors = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const doc = createDocCompetitors(orientation, unit, size, font)
        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const createDocReferees = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${competitionProfileData?.attributes?.name} - Sudjenje`
        doc.text(title, marginLeft, 40)

        // Check if name and surname of main referee is defined
        const mainRefereeName =
            competitionProfileData?.attributes?.main_referee_id?.name
        const mainRefereeSurname =
            competitionProfileData?.attributes?.main_referee_id?.surname

        const rows = []

        if (mainRefereeName && mainRefereeSurname) {
            rows.push(['Sudija', `${mainRefereeName} ${mainRefereeSurname}`])
        } else {
            rows.push(['', ''])
        }

        let refereeContent = {
            startY: 50,
            head: [['Glavni sudija', '']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.main_referee_id &&
            doc.autoTable(refereeContent)

        const otherRefereesData =
            competitionProfileData?.attributes?.other_referees?.map(
                (referee) => ['Sudija', `${referee.name} ${referee.surname}`]
            )
        let otherRefereeContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Ostale sudije', '']],
            body: otherRefereesData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.other_referees &&
            doc.autoTable(otherRefereeContent)

        return doc
    }

    // Export PDF for Competitors
    const handleExportRefereesPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDocReferees(orientation, unit, size, font)

        doc.save('Sudjenje.pdf')
    }

    // Print for Referee
    const handlePrintReferees = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const doc = createDocReferees(orientation, unit, size, font)
        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const createDocDescription = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Opis takmičenja: ${competitionProfileData?.attributes?.name}`
        doc.text(title, marginLeft, 40)

        const participantsData = [
            [
                'Broj učesnika',
                competitionProfileData?.attributes?.competition_info
                    ?.participants?.number_of_participants,
            ],
            [
                'Broj specijalnih pozivnica za kvalifikacije (TSS)',
                competitionProfileData?.attributes?.competition_info
                    ?.participants?.number_of_special_invitations_tss,
            ],
            [
                'Broj specijalnih pozivnica za kvalifikacije (Organizator)',
                competitionProfileData?.attributes?.competition_info
                    ?.participants?.number_of_special_invitations_organizer,
            ],
        ]

        let participantsContent = {
            startY: 50,
            head: [['Status', ' ']],
            body: participantsData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info?.participants &&
            doc.autoTable(participantsContent)
        /////////////////////////

        const teamCriteria = [
            [
                'Broj ekipa koji može učestvovati',
                competitionProfileData?.attributes?.competition_info
                    ?.team_criteria?.max_number_of_teams,
            ],
            [
                'Maksimalan broj članova ekipe',
                competitionProfileData?.attributes?.competition_info
                    ?.team_criteria?.max_number_of_team_members,
            ],
            [
                'Minimalan broj članova ekipe',
                competitionProfileData?.attributes?.competition_info
                    ?.team_criteria?.min_number_of_teams,
            ],

            [
                'Šablon za bodovanje',
                competitionProfileData?.attributes?.competition_info
                    ?.team_criteria?.scoring_template,
            ],
        ]

        let teamCriteriaContent = {
            startY: 50,
            head: [['Kriterijumi za ekipe', ' ']],
            body: teamCriteria,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info?.team_criteria &&
            doc.autoTable(teamCriteriaContent)

        //////////////////
        const mainTournamentData = [
            [
                'Šablon za bodovanje',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.scoring_template,
            ],
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.main_event?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.main_event?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.main_event?.no_ad_rule
                ),
            ],
            [
                'Dodatne informacije',
                competitionProfileData?.attributes?.competition_info?.main_event
                    ?.additional_info,
            ],
        ]

        let mainTournamentContent = {
            startY: 50,
            head: [['Glavni turnir', ' ']],
            body: mainTournamentData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.competition_info?.main_event &&
            doc.autoTable(mainTournamentContent)

        //////////////////////////////////////

        const groupData = [
            [
                'Šablon za bodovanje',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.scoring_template,
            ],
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info?.groups
                        ?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info?.groups
                        ?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info?.groups
                        ?.no_ad_rule
                ),
            ],
            [
                'Napomena',
                competitionProfileData?.attributes?.competition_info?.groups
                    ?.additional_info,
            ],
        ]

        let groupContent = {
            startY: 50,
            head: [['Grupa', ' ']],
            body: groupData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        competitionProfileData?.attributes?.competition_info?.groups &&
            doc.autoTable(groupContent)

        ///////////////////////////////////////////////////

        const singlesData = [
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.single_matches?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.single_matches?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.single_matches?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.single_matches?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.single_matches?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.single_matches?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.single_matches?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.single_matches?.no_ad_rule
                ),
            ],
        ]

        let singlesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Singl mečevi', ' ']],
            body: singlesData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info?.matches_format
            ?.single_matches && doc.autoTable(singlesContent)

        ///////////////////////
        const doublesData = [
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.double_matches?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.double_matches?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.double_matches?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.double_matches?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.double_matches?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info
                    ?.matches_format?.double_matches?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.double_matches?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.matches_format?.double_matches?.no_ad_rule
                ),
            ],
        ]

        let doublesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Dubl mečevi', ' ']],
            body: doublesData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info?.matches_format
            ?.double_matches && doc.autoTable(doublesContent)

        //////////

        const qualificationsData = [
            [
                'Šablon za bodovanje',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.scoring_template,
            ],
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.qualifications_info?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info
                    ?.qualifications_info?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.qualifications_info?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.qualifications_info?.no_ad_rule
                ),
            ],
        ]

        let qualificationsContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Kvalifikacije', ' ']],
            body: qualificationsData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info
            ?.qualifications_info && doc.autoTable(qualificationsContent)

        /////////////////////////////////////////

        const consolationTournamentData = [
            [
                'Šablon za bodovanje',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.scoring_template,
            ],
            [
                'Broj dobijenih setova',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.number_of_sets_won,
            ],
            [
                'Broj gemova',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.number_of_games,
            ],
            [
                'Tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.consolation_tournament?.tie_break
                ),
            ],
            [
                'Pri kom rezultatu',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.tie_break_result,
            ],
            [
                'Do koliko poena',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.tie_break_points,
            ],
            [
                'Zlatni poen / Dva razlike',
                competitionProfileData?.attributes?.competition_info
                    ?.consolation_tournament?.tie_break_golden_point,
            ],
            [
                'Match tie-break',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.consolation_tournament?.match_tie_break
                ),
            ],

            [
                'No Ad pravilo',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.consolation_tournament?.no_ad_rule
                ),
            ],
        ]

        let consolationTournamentContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Utešni turnir', ' ']],
            body: consolationTournamentData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info
            ?.consolation_tournament &&
            doc.autoTable(consolationTournamentContent)

        ///////////////////////////
        const limitationsData = [
            [
                'Stranci',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.restrictions?.strangers
                ),
            ],
            [
                'Broj stranaca koji se mogu prijaviti',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.max_number_of_strangers_registration,
            ],
            [
                'Broj stranaca po meču',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.max_number_of_strangers_per_match,
            ],
            [
                'Samo iz regiona',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.restrictions?.only_from_region
                ),
            ],
            [
                'Pozajmice dopuštene',
                handleShowYesNo(
                    competitionProfileData?.attributes?.competition_info
                        ?.restrictions?.loans_allowed
                ),
            ],
            [
                'Broj pozajmica',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.max_number_of_loans,
            ],
            [
                'Broj pozajmica po meču',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.max_number_of_loans_per_match,
            ],
            [
                'Ne mogu učestvovati (sa TSS liste)',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.no_entries_tss,
            ],
            [
                'Ne mogu učestvovati (sa ITF liste)',
                competitionProfileData?.attributes?.competition_info
                    ?.restrictions?.no_entries_itf,
            ],
        ]

        let limitationsContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Ograničenja', ' ']],
            body: limitationsData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }
        competitionProfileData?.attributes?.competition_info?.restrictions &&
            doc.autoTable(limitationsContent)

        /////////////////////////////////////////
        const generalInformationsData = [
            ['Tip takmičenja', competitionProfileData?.attributes?.type],
            [
                'Format',
                competitionProfileData?.attributes?.competition_info?.format,
            ],

            [
                'Rang lista za sortiranje takmičara',
                handleArrayDetail(
                    competitionProfileData?.attributes?.competition_info
                        ?.tournament_sorting_ranking_list
                ),
            ],
        ]

        let generalInformationsContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Opšte informacije', ' ']],
            body: generalInformationsData,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(generalInformationsContent)

        return doc
    }

    // Export PDF for Description
    const handleExportDescriptionPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDocDescription(orientation, unit, size, font)

        doc.save('Opis_takmičenja.pdf')
    }
    // Print for Description

    const handlePrintDescription = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const doc = createDocDescription(orientation, unit, size, font)
        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    // Handler for export PDF
    const handlePDF = () => {
        if (selectedTab === 'informacije') {
            handleExportCompetitionPDF()
        } else if (selectedTab === 'ucesnici') {
            handleExportCompetitorsPDF()
        } else if (selectedTab === 'opis-turnira') {
            handleExportDescriptionPDF()
        } else if (selectedTab === 'sudjenja') {
            handleExportRefereesPDF()
        }
    }

    // Handler for print
    const handlePrint = () => {
        if (selectedTab === 'informacije') {
            handlePrintCompetition()
        } else if (selectedTab === 'ucesnici') {
            handlePrintCompetitors()
        } else if (selectedTab === 'opis-turnira') {
            handlePrintDescription()
        } else if (selectedTab === 'sudjenja') {
            handlePrintReferees()
        }
    }

    return (
        <Row className="referee-admin-single-competition h-100">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    {competitionProfileData?.attributes?.name}
                                </h2>
                                <p className="d-block d-md-none">
                                    {competitionProfileData?.attributes?.name}
                                </p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to: '/administrator-sudjenja/pocetna',
                                        }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to: type
                                                ? type === 'Ekipno'
                                                    ? '/administrator-sudjenja/ekipna-takmicenja'
                                                    : type === 'Individualno'
                                                    ? '/administrator-sudjenja/individualna-takmicenja'
                                                    : '/administrator-sudjenja/takmicenja-iz-kalendara-TSS'
                                                : '/administrator-sudjenja/takmicenja-iz-kalendara-TSS',
                                            state: { type: type ? type : null },
                                        }}
                                    >
                                        {type
                                            ? type === 'Ekipno'
                                                ? 'Ekipna'
                                                : type === 'Individualno'
                                                ? 'Individualna'
                                                : 'Iz kalendara TSS'
                                            : 'Iz kalendara TSS'}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {
                                            competitionProfileData?.attributes
                                                ?.name
                                        }
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={8} className="d-none d-md-block">
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <Button
                                    type="text"
                                    className="me-2"
                                    onClick={handlePDF}
                                >
                                    Eksportuj u PDF
                                </Button>
                                <Button
                                    leadingIcon={<PrintOutlined />}
                                    type="secondary"
                                    onClick={handlePrint}
                                >
                                    Štampaj
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            id="uncontrolled-tab-example"
                            onSelect={(e) => setSelectedTab(e)}
                        >
                            <Tab eventKey="informacije" title="Informacije">
                                <Row>
                                    <Col xs={12} md>
                                        <IconInfoCard
                                            className="my-3"
                                            icon={
                                                <Avatar
                                                    img={
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.club_organizer_id
                                                            ?.data?.attributes
                                                            ?.club_img?.data
                                                            ? formatImgUrl(
                                                                  competitionProfileData
                                                                      ?.attributes
                                                                      ?.club_organizer_id
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.club_img
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.url
                                                              )
                                                            : generateAvatar(
                                                                  competitionProfileData
                                                                      ?.attributes
                                                                      ?.club_organizer_id
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.name
                                                                      ? competitionProfileData
                                                                            ?.attributes
                                                                            ?.club_organizer_id
                                                                            ?.data
                                                                            ?.attributes
                                                                            ?.name
                                                                      : ''
                                                              )
                                                    }
                                                />
                                            }
                                            title={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.club_organizer_id?.data
                                                    ?.attributes?.name
                                            }
                                            subtitle="Organizator"
                                        >
                                            <p>
                                                <b>Adresa: </b>
                                                {competitionProfileData
                                                    ?.attributes
                                                    ?.club_organizer_id?.data
                                                    ?.attributes?.address
                                                    ? competitionProfileData
                                                          ?.attributes
                                                          ?.club_organizer_id
                                                          ?.data?.attributes
                                                          ?.address
                                                    : 'Nema informacija'}
                                            </p>
                                        </IconInfoCard>

                                        <IconInfoCard
                                            className="mb-3"
                                            icon={<FilledCalendar />}
                                            title="Važni datumi"
                                        >
                                            {handleShowDetail(
                                                'Početak takmičenja',
                                                competitionProfileData
                                                    ?.attributes?.start_date
                                                    ? formatDate(
                                                          competitionProfileData
                                                              ?.attributes
                                                              ?.start_date
                                                      )
                                                    : ''
                                            )}

                                            {handleShowDetail(
                                                'Krajnji datum za prijavu',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.application_last_date
                                                    ? formatDate(
                                                          competitionProfileData
                                                              ?.attributes
                                                              ?.application_last_date
                                                      )
                                                    : ''
                                            )}

                                            {handleShowDetail(
                                                'Krajnji datum za odjavu',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.checkout_last_date
                                                    ? formatDate(
                                                          competitionProfileData
                                                              ?.attributes
                                                              ?.checkout_last_date
                                                      )
                                                    : ''
                                            )}
                                        </IconInfoCard>

                                        <IconInfoCard
                                            className="mb-3"
                                            icon={<FilledBed />}
                                            title="Smeštaj"
                                            subtitle={
                                                !competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.city &&
                                                !competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.address
                                                    ? 'Nema informacija'
                                                    : ''
                                            }
                                        >
                                            {handleShowDetail(
                                                'Adresa',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.city &&
                                                    competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.accommodation?.address
                                                    ? `${competitionProfileData?.attributes?.competition_info?.accommodation?.address}, ${competitionProfileData?.attributes?.competition_info?.accommodation?.city}`
                                                    : ''
                                            )}

                                            {handleShowDetail(
                                                'Telefon',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.phone
                                                    ? competitionProfileData
                                                          ?.attributes
                                                          ?.competition_info
                                                          ?.accommodation?.phone
                                                    : ''
                                            )}

                                            {handleShowDetail(
                                                'Email',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.email
                                            )}

                                            {handleShowDetail(
                                                'Napomena',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.accommodation?.more_info
                                            )}
                                        </IconInfoCard>

                                        <Row className="mb-3">
                                            <Col>
                                                <IconInfoCard
                                                    icon={<FilledTennisBall />}
                                                    title={
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.details
                                                            ?.ball_brand
                                                            ? competitionProfileData
                                                                  ?.attributes
                                                                  ?.competition_info
                                                                  ?.details
                                                                  ?.ball_brand
                                                            : 'Lopte'
                                                    }
                                                    subtitle={
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.details
                                                            ?.ball_brand
                                                            ? 'Lopte'
                                                            : 'Nema informacija'
                                                    }
                                                >
                                                    {handleShowDetail(
                                                        'Zamena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.details
                                                            ?.ball_change
                                                    )}

                                                    {handleShowDetail(
                                                        'Napomena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.details
                                                            ?.more_details
                                                    )}
                                                </IconInfoCard>
                                            </Col>
                                        </Row>

                                        <IconInfoCard
                                            icon={<Person />}
                                            title={`${
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.director
                                                    ? competitionProfileData
                                                          ?.attributes
                                                          ?.competition_info
                                                          ?.contact?.director
                                                    : 'Direktor takmičenja'
                                            }`}
                                            subtitle={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.director
                                                    ? 'Direktor takmičenja'
                                                    : 'Nema informacija'
                                            }
                                            isImage={false}
                                        >
                                            {handleShowDetail(
                                                'Telefon',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.director_phone
                                            )}

                                            {handleShowDetail(
                                                'Email',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.director_email
                                            )}
                                        </IconInfoCard>
                                    </Col>

                                    <Col xs={12} md>
                                        <IconInfoCard
                                            className="my-3"
                                            icon={<Person />}
                                            title={
                                                competitionProfileData
                                                    ?.attributes?.type
                                            }
                                            subtitle="Tip takmičenja"
                                        ></IconInfoCard>

                                        <Row className="mb-3">
                                            <Col>
                                                <Card
                                                    className={
                                                        'competition-info-card'
                                                    }
                                                >
                                                    <Card.Body className="p-0">
                                                        {handleShowDetail(
                                                            'Tip takmičenja',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.type
                                                                ? competitionProfileData
                                                                      ?.attributes
                                                                      ?.type
                                                                : 'Nema informacija'
                                                        )}
                                                        {handleShowDetail(
                                                            'Konkurencija',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition
                                                                ? competitionProfileData
                                                                      ?.attributes
                                                                      ?.competition
                                                                : 'Nema informacija'
                                                        )}
                                                        {handleShowDetail(
                                                            'Konkurencija',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition
                                                                ? competitionProfileData
                                                                      ?.attributes
                                                                      ?.competition
                                                                : 'Nema informacija'
                                                        )}

                                                        {handleShowDetail(
                                                            'Format',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.format
                                                        )}

                                                        {handleShowDetail(
                                                            'Kategorija',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.category
                                                                ? competitionProfileData
                                                                      ?.attributes
                                                                      ?.category
                                                                : 'Nema informacija'
                                                        )}

                                                        {handleShowDetail(
                                                            'Dubl',
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.double ===
                                                                false
                                                                ? 'NE'
                                                                : 'DA'
                                                        )}

                                                        {handleShowDetail(
                                                            'Kvalifikacije',
                                                            handleShowYesNo(
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications
                                                            )
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Card className="competiton-region-card">
                                                    <Card.Body>
                                                        <h2 className="mb-2">
                                                            {competitionProfileData
                                                                ?.attributes
                                                                ?.region
                                                                ? competitionProfileData
                                                                      ?.attributes
                                                                      ?.region
                                                                : 'Region'}
                                                        </h2>
                                                        <p>
                                                            {competitionProfileData
                                                                ?.attributes
                                                                ?.region
                                                                ? 'Region'
                                                                : 'Nema informacija'}
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <IconInfoCard
                                            className="mb-3"
                                            icon={
                                                competitionProfileData
                                                    ?.attributes?.gender ===
                                                'Muškarci' ? (
                                                    <Male />
                                                ) : competitionProfileData
                                                      ?.attributes?.gender ===
                                                  'Žene' ? (
                                                    <Female />
                                                ) : (
                                                    <MaleFemale />
                                                )
                                            }
                                            title={
                                                competitionProfileData
                                                    ?.attributes?.gender
                                                    ? competitionProfileData
                                                          ?.attributes?.gender
                                                    : 'Pol'
                                            }
                                            subtitle={
                                                competitionProfileData
                                                    ?.attributes?.gender
                                                    ? 'Pol'
                                                    : 'Nema informacija'
                                            }
                                        ></IconInfoCard>

                                        <IconInfoCard
                                            className="mb-3"
                                            icon={<Wallet />}
                                            title={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.details
                                                    ?.registration_fee
                                                    ? competitionProfileData
                                                          ?.attributes
                                                          ?.competition_info
                                                          ?.details
                                                          ?.registration_fee +
                                                      ' RSD'
                                                    : 'Kotizacija'
                                            }
                                            subtitle={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.details
                                                    ?.registration_fee
                                                    ? 'Kotizacija'
                                                    : 'Nema informacija'
                                            }
                                        ></IconInfoCard>
                                        <IconInfoCard
                                            className="mb-3"
                                            icon={<Person />}
                                            title={`${
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.tournament_leader
                                                    ? competitionProfileData
                                                          ?.attributes
                                                          ?.competition_info
                                                          ?.contact
                                                          ?.tournament_leader
                                                    : 'Voditelj takmičenja'
                                            }`}
                                            subtitle={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.tournament_leader
                                                    ? 'Voditelj takmičenja'
                                                    : 'Nema informacija'
                                            }
                                            isImage={false}
                                        >
                                            {handleShowDetail(
                                                'Telefon',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.tournament_leader_phone
                                            )}

                                            {handleShowDetail(
                                                'Email',
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info?.contact
                                                    ?.tournament_leader_email
                                            )}
                                        </IconInfoCard>
                                        {competitionProfileData?.attributes
                                            ?.competition_info?.contact
                                            ?.contact_person && (
                                            <IconInfoCard
                                                className="mb-3"
                                                icon={<Person />}
                                                title={`${
                                                    competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.contact
                                                        ?.contact_person
                                                        ? competitionProfileData
                                                              ?.attributes
                                                              ?.competition_info
                                                              ?.contact
                                                              ?.contact_person
                                                        : ''
                                                }`}
                                                subtitle="Kontakt osoba"
                                                isImage={false}
                                            >
                                                {handleShowDetail(
                                                    'Telefon',
                                                    competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.contact
                                                        ?.contact_person_phone
                                                )}

                                                {handleShowDetail(
                                                    'Email',
                                                    competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.contact
                                                        ?.contact_person_email
                                                )}
                                            </IconInfoCard>
                                        )}
                                        <IconInfoCard
                                            icon={<People />}
                                            title={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.competition_info
                                                    ?.basic_info
                                                    ?.consolation_tournament
                                                    ? 'Ima'
                                                    : 'Nema'
                                            }
                                            subtitle="Utešni turnir"
                                        ></IconInfoCard>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="ucesnici" title="Status">
                                {competitionProfileData?.attributes?.type ===
                                'Individualno' ? (
                                    <>
                                        {competitionProfileData?.attributes
                                            ?.competitors?.data?.length > 0 ? (
                                            <>
                                                <Row className="d-none d-md-block mt-3">
                                                    <Col>
                                                        <CompetitionsParticipantsTable
                                                            data={
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competitors
                                                                    ?.data
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="d-block d-md-none mt-3">
                                                    <Col>
                                                        {competitionProfileData?.attributes?.competitors?.data?.map(
                                                            (
                                                                competitor,
                                                                index
                                                            ) => (
                                                                <CompetitionsParticipantsCard
                                                                    key={index}
                                                                    competitor={
                                                                        competitor
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    isWhite={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                        {competitionProfileData
                                                            ?.attributes
                                                            ?.competitors?.data
                                                            ?.length > 0 && (
                                                            <div className="d-md-none">
                                                                <PaginationWrapper
                                                                    currentPage={
                                                                        currentPage
                                                                    }
                                                                    setCurrentPage={
                                                                        setCurrentPage
                                                                    }
                                                                    pageCount={
                                                                        pageCount
                                                                    }
                                                                    total={
                                                                        total
                                                                    }
                                                                    rowsPerPage={
                                                                        rowsPerPage
                                                                    }
                                                                    setRowsPerPage={
                                                                        setRowsPerPage
                                                                    }
                                                                    showOnMobile
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <Card className="mt-3 ps-md-3 no-info-card">
                                                <Card.Body>
                                                    <p>
                                                        Trenutno ne postoje
                                                        učesnici za ovo
                                                        takmičenje.
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </>
                                ) : (
                                    <Row>
                                        <Col className="pt-3">
                                            <Card>
                                                <Card.Body>
                                                    <h3 className="mb-4">
                                                        Klubovi koji se takmiče
                                                    </h3>
                                                    <Row>
                                                        <Col>
                                                            {competitionProfileData
                                                                ?.attributes
                                                                ?.clubs?.data
                                                                ?.length > 0 ? (
                                                                <>
                                                                    <CompetitionClubsParticipants
                                                                        clubs={
                                                                            competitionProfileData
                                                                                ?.attributes
                                                                                ?.clubs
                                                                                ?.data
                                                                        }
                                                                        competitionProfileData={
                                                                            competitionProfileData
                                                                        }
                                                                        handleInitCompetitionData={
                                                                            handleInitCompetitionData
                                                                        }
                                                                        className="d-none d-md-block"
                                                                    />
                                                                    {competitionProfileData?.attributes?.clubs?.data.map(
                                                                        (
                                                                            clubsData,
                                                                            index,
                                                                            {
                                                                                id,
                                                                            }
                                                                        ) => (
                                                                            <CompetitionClubsCardParticipants
                                                                                key={
                                                                                    index
                                                                                }
                                                                                id={
                                                                                    id
                                                                                }
                                                                                clubsData={
                                                                                    clubsData
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                isWhite={
                                                                                    index %
                                                                                        2 !==
                                                                                    0
                                                                                }
                                                                                competitionProfileData={
                                                                                    competitionProfileData
                                                                                }
                                                                                handleInitCompetitionData={
                                                                                    handleInitCompetitionData
                                                                                }
                                                                                className="d-block d-md-none"
                                                                            />
                                                                        )
                                                                    )}
                                                                    {competitionProfileData
                                                                        ?.attributes
                                                                        ?.clubs
                                                                        ?.data
                                                                        ?.length >
                                                                        0 && (
                                                                        <div className="d-md-none">
                                                                            <PaginationWrapper
                                                                                currentPage={
                                                                                    currentPage
                                                                                }
                                                                                setCurrentPage={
                                                                                    setCurrentPage
                                                                                }
                                                                                pageCount={
                                                                                    pageCount
                                                                                }
                                                                                total={
                                                                                    total
                                                                                }
                                                                                rowsPerPage={
                                                                                    rowsPerPage
                                                                                }
                                                                                setRowsPerPage={
                                                                                    setRowsPerPage
                                                                                }
                                                                                showOnMobile
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <p>
                                                                    Trenutno ne
                                                                    postoje
                                                                    učesnici za
                                                                    ovo
                                                                    takmičenje.
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    {user?.role?.type ===
                                                        'admin' && (
                                                        <Row className="mt-4">
                                                            <Col className="d-flex flex-column align-items-end">
                                                                <Button
                                                                    onClick={() =>
                                                                        setShowClubsModal(
                                                                            true
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        competitionProfileData
                                                                            ?.attributes
                                                                            ?.competitors
                                                                            ?.data
                                                                            ?.length >
                                                                        0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    Izmeni listu
                                                                    klubova
                                                                </Button>
                                                                {competitionProfileData
                                                                    ?.attributes
                                                                    ?.competitors
                                                                    ?.data
                                                                    ?.length >
                                                                    0 && (
                                                                    <p className="small-text mt-2">
                                                                        Nije
                                                                        moguće
                                                                        menjati
                                                                        listu
                                                                        klubova
                                                                        ako
                                                                        postoje
                                                                        već
                                                                        prijavljeni
                                                                        takmičari
                                                                        za neki
                                                                        klub.
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </Tab>
                            <Tab
                                className="tournament-description-wrapper"
                                eventKey="opis-turnira"
                                title="Opis takmičenja"
                            >
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <IconInfoCard
                                                    className="my-3"
                                                    icon={<GameController />}
                                                    title="O takmičenju"
                                                >
                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.participants && (
                                                        <h5 className="mb-2">
                                                            Učesnici
                                                        </h5>
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj učesnika',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.participants
                                                            ?.number_of_participants
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj specijalnih pozivnica za kvalifikacije (TSS)',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.participants
                                                            ?.number_of_special_invitations_tss
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj specijalnih pozivnica za kvalifikacije (Organizator)',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.participants
                                                            ?.number_of_special_invitations_organizer
                                                    )}

                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.main_event && (
                                                        <h5 className="mb-2">
                                                            Glavni turnir
                                                        </h5>
                                                    )}

                                                    {handleShowDetail(
                                                        'Šablon za bodovanje',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.scoring_template
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj dobijenih setova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.number_of_sets_won
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.number_of_games
                                                    )}

                                                    {handleShowDetail(
                                                        'Tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.main_event
                                                                ?.tie_break
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Pri kom rezultatu',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.tie_break_result
                                                    )}

                                                    {handleShowDetail(
                                                        'Do koliko poena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.tie_break_points
                                                    )}

                                                    {handleShowDetail(
                                                        'Zlatni poen / Dva razlike',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.tie_break_golden_point
                                                    )}

                                                    {handleShowDetail(
                                                        'Match tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.main_event
                                                                ?.match_tie_break
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'No Ad pravilo',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.main_event
                                                                ?.no_ad_rule
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Dodatne informacije',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.main_event
                                                            ?.additional_info
                                                    )}

                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.groups && (
                                                        <h5 className="mb-2">
                                                            Grupe
                                                        </h5>
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj grupa',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.number_of_groups
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj takmičara koji prolaze u žreb',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.number_of_participants_in_draw
                                                    )}

                                                    {handleShowDetail(
                                                        'Bodovi za rezultat u grupi',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.result_points_in_group
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj dobijenih setova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.number_of_sets_won
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.number_of_games
                                                    )}

                                                    {handleShowDetail(
                                                        'Tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.groups
                                                                ?.tie_break
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Pri kom rezultatu',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.tie_break_result
                                                    )}

                                                    {handleShowDetail(
                                                        'Do koliko poena',

                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.tie_break_points
                                                    )}

                                                    {handleShowDetail(
                                                        'Zlatni poen / Dva razlike',

                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.tie_break_golden_point
                                                    )}

                                                    {handleShowDetail(
                                                        'No Ad pravilo',

                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.groups
                                                                ?.no_ad_rule
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Dodatne informacije',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.groups
                                                            ?.additional_info
                                                    )}

                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.matches_format
                                                        ?.single_matches && (
                                                        <h5 className="mb-2">
                                                            Singl mečevi
                                                        </h5>
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj mečeva',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.number_of_matches
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj dobijenih setova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.number_of_sets_won
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.number_of_games
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.matches_format
                                                                ?.single_matches
                                                                ?.tie_break
                                                        )
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break pri kom rezultatu',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.tie_break_result
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break do koliko poena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.tie_break_points
                                                    )}
                                                    {handleShowDetail(
                                                        'Zlatni poen / dva razlike',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.single_matches
                                                            ?.tie_break_golden_point
                                                    )}
                                                    {handleShowDetail(
                                                        'Match tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.matches_format
                                                                ?.single_matches
                                                                ?.match_tie_break
                                                        )
                                                    )}
                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.matches_format
                                                        ?.double_matches && (
                                                        <h5 className="mb-2 mt-4">
                                                            Dubl mečevi
                                                        </h5>
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj mečeva',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.number_of_matches
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj dobijenih setova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.number_of_sets_won
                                                    )}
                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.number_of_games
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.matches_format
                                                                ?.double_matches
                                                                ?.tie_break
                                                        )
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break pri kom rezultatu',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.tie_break_result
                                                    )}
                                                    {handleShowDetail(
                                                        'Tie-break do koliko poena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.tie_break_points
                                                    )}
                                                    {handleShowDetail(
                                                        'Zlatni poen / Dva razlike',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.matches_format
                                                            ?.double_matches
                                                            ?.tie_break_golden_point
                                                    )}
                                                    {handleShowDetail(
                                                        'Match tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.matches_format
                                                                ?.double_matches
                                                                ?.match_tie_break
                                                        )
                                                    )}

                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.qualifications && (
                                                        <>
                                                            <h5 className="mb-2 mt-4">
                                                                Kvalifikacije
                                                            </h5>
                                                            {handleShowDetail(
                                                                'Broj dobijenih setova',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.number_of_sets_won
                                                            )}

                                                            {handleShowDetail(
                                                                'Broj gemova',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.number_of_games
                                                            )}

                                                            {handleShowDetail(
                                                                'Broj gemova',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.number_of_games
                                                            )}

                                                            {handleShowDetail(
                                                                'Tie-break',
                                                                handleShowYesNo(
                                                                    competitionProfileData
                                                                        ?.attributes
                                                                        ?.competition_info
                                                                        ?.qualifications_info
                                                                        ?.tie_break
                                                                )
                                                            )}

                                                            {handleShowDetail(
                                                                'Pri kom rezultatu',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.tie_break_result
                                                            )}

                                                            {handleShowDetail(
                                                                'Do koliko poena',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.tie_break_points
                                                            )}

                                                            {handleShowDetail(
                                                                'Zlatni poen / Dva razlike',
                                                                competitionProfileData
                                                                    ?.attributes
                                                                    ?.competition_info
                                                                    ?.qualifications_info
                                                                    ?.tie_break_golden_point
                                                            )}

                                                            {handleShowDetail(
                                                                'Match tie-break',
                                                                handleShowYesNo(
                                                                    competitionProfileData
                                                                        ?.attributes
                                                                        ?.competition_info
                                                                        ?.qualifications_info
                                                                        ?.match_tie_break
                                                                )
                                                            )}

                                                            {handleShowDetail(
                                                                'Match tie-break',
                                                                handleShowYesNo(
                                                                    competitionProfileData
                                                                        ?.attributes
                                                                        ?.competition_info
                                                                        ?.qualifications_info
                                                                        ?.no_ad_rule
                                                                )
                                                            )}

                                                            {handleShowDetail(
                                                                'Dodatne informacije',
                                                                handleShowYesNo(
                                                                    competitionProfileData
                                                                        ?.attributes
                                                                        ?.competition_info
                                                                        ?.qualifications_info
                                                                        ?.qualifications_more_info
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {competitionProfileData
                                                        ?.attributes
                                                        ?.competition_info
                                                        ?.consolation_tournament && (
                                                        <h5 className="mb-2 mt-4">
                                                            Utešni turnir
                                                        </h5>
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj dobijenih setova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.number_of_sets_won
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.number_of_games
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj gemova',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.number_of_games
                                                    )}

                                                    {handleShowDetail(
                                                        'Tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.consolation_tournament
                                                                ?.tie_break
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Pri kom rezultatu',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.tie_break_result
                                                    )}

                                                    {handleShowDetail(
                                                        'Do koliko poena',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.tie_break_points
                                                    )}

                                                    {handleShowDetail(
                                                        'Zlatni poen / Dva razlike',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.consolation_tournament
                                                            ?.tie_break_golden_point
                                                    )}

                                                    {handleShowDetail(
                                                        'Match tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.consolation_tournament
                                                                ?.match_tie_break
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Match tie-break',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.consolation_tournament
                                                                ?.no_ad_rule
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Dodatne informacije',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.consolation_tournament
                                                                ?.qualifications_more_info
                                                        )
                                                    )}
                                                </IconInfoCard>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <IconInfoCard
                                                    className="my-3"
                                                    icon={<InformationCircle />}
                                                    title="Opšte informacije"
                                                >
                                                    {handleShowDetail(
                                                        'Tip takmičenja',
                                                        competitionProfileData
                                                            ?.attributes?.type
                                                    )}

                                                    {handleShowDetail(
                                                        'Format',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.format
                                                    )}

                                                    {handleShowDetail(
                                                        'Rang lista za sortiranje takmičara',
                                                        handleArrayDetail(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.tournament_sorting_ranking_list
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Kvalifikacije',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.qualifications
                                                        )
                                                    )}
                                                </IconInfoCard>

                                                <IconInfoCard
                                                    className="my-3"
                                                    icon={<Accessibility />}
                                                    title="Ograničenja"
                                                >
                                                    {handleShowDetail(
                                                        'Stranci',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.restrictions
                                                                ?.strangers
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj stranaca koji se mogu prijaviti',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.max_number_of_strangers_registration
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj stranaca po meču',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.max_number_of_strangers_per_match
                                                    )}

                                                    {handleShowDetail(
                                                        'Samo iz regiona',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.restrictions
                                                                ?.only_from_region
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Pozajmice dopuštene',
                                                        handleShowYesNo(
                                                            competitionProfileData
                                                                ?.attributes
                                                                ?.competition_info
                                                                ?.restrictions
                                                                ?.loans_allowed
                                                        )
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj pozajmica',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.max_number_of_loans
                                                    )}

                                                    {handleShowDetail(
                                                        'Broj pozajmica po meču',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.max_number_of_loans_per_match
                                                    )}

                                                    {handleShowDetail(
                                                        'Ne mogu učestvovati (sa TSS liste)',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.no_entries_tss
                                                    )}

                                                    {handleShowDetail(
                                                        'Ne mogu učestvovati (sa ITF liste)',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.no_entries_itf
                                                    )}

                                                    {handleShowDetail(
                                                        'Ne mogu učestvovati (sa ETA liste)',
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competition_info
                                                            ?.restrictions
                                                            ?.no_entries_eta
                                                    )}
                                                </IconInfoCard>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                            {isObjectEmpty(mainReferee) &&
                            isArrayEmpty(otherReferees) ? (
                                <Tab eventKey="sudjenja" title="Sudjenje">
                                    <Row>
                                        <Col>
                                            <NoDataForView
                                                title="Sudjenje"
                                                description="Još uvek nisu dezignirane sudije takmičenja."
                                                buttonText="Dezigniraj"
                                                onClick={() =>
                                                    navigate(
                                                        `/administrator-sudjenja/takmicenja/${id}/dodaj-sudiju`
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                            ) : (
                                <Tab eventKey="sudjenja" title="Sudjenje">
                                    <Row className="d-flex justify-content-center">
                                        <Col
                                            md={12}
                                            className="d-flex flex-column flex-md-row justify-content-center py-3"
                                        >
                                            <RefereeCard
                                                referee={mainReferee}
                                                isMainReferee={true}
                                            />
                                            {otherReferees.map(
                                                (referee, index) => (
                                                    <RefereeCard
                                                        key={index}
                                                        referee={referee}
                                                    />
                                                )
                                            )}
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default RefereeAdminSingleCompetition
