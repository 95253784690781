import React, { useEffect, useState } from 'react'

import { Row, Col, Form } from 'react-bootstrap'
import { useAppContext } from '../../../../../../lib/contextLib'
import { updateTournament } from '../../../../../../lib/api'
const SetDrawPublicSwitch = ({ competitionProfileData }) => {
    const { user, setIsLoading, setShowCustomToast } = useAppContext()

    const [isDrawPublic, setIsDrawPublic] = useState(false)
    const handleSetDrawPublic = async () => {
        try {
            const updatedTournament = await updateTournament(
                competitionProfileData?.attributes?.uuid,
                {
                    is_draw_public: !isDrawPublic,
                }
            )

            if (!updatedTournament) return
            setShowCustomToast({
                show: true,
                type: 'success',
                message: 'Uspešno ažuriranje.',
            })
            setIsDrawPublic((prev) => !prev)
        } catch (error) {
            console.error(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!competitionProfileData) return
        setIsDrawPublic(
            competitionProfileData?.attributes?.is_draw_public === false
                ? false
                : true
        )
    }, [competitionProfileData])
    return (
        <Row>
            <Col xs={12}>
                <Form.Switch
                    name="competition-finished-switch"
                    className="competition-finished-switch p-0"
                    label={
                        <h4 className="competition-finished-switch-label pb-2">
                            Žreb je objavljen
                        </h4>
                    }
                    checked={isDrawPublic === false ? false : true}
                    onChange={handleSetDrawPublic}
                    disabled={user?.role?.type === 'competitor' ? true : false}
                />
            </Col>
        </Row>
    )
}

export default SetDrawPublicSwitch
