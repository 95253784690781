import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { generateAvatar } from '../../utils/generateAvatar'
import './styles.scss'
import PaginationWrapper from '../Pagination'

const TableRefereeCard = ({
    referees,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <>
            {referees?.map((referee, index) => (
                <Row
                    className={`table-referee-card-wrapper g-3 ${className}`}
                    key={`referee-${index}-card`}
                >
                    <Col xs={5} className="mt-3 d-flex align-items-center">
                        <h5>Sudija</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 mt-3">
                        <img
                            src={
                                referee?.attributes?.person_info?.picture
                                    ? referee?.attributes?.person_info?.picture
                                    : generateAvatar(
                                          `${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`
                                      )
                            }
                            alt={`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}
                            className="me-2 table-referee-card-ref-avatar"
                        />
                        <Link to={`/sudije/${referee?.attributes?.uuid}`}>
                            <p className="link">
                                {`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}
                            </p>
                        </Link>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0">
                        <h5>Region</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {referee?.attributes?.region?.region_name
                                ? referee?.attributes?.region?.region_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0">
                        <h5>Licenca</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {referee?.attributes?.referees_licenses?.attributes
                                ?.core_license
                                ? referee?.attributes?.referees_licenses
                                      ?.attributes?.core_license
                                      ?.core_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0">
                        <h5>Tip licence</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {referee?.attributes?.referees_licenses?.attributes
                                ?.national_license_type
                                ?.national_license_type_name
                                ? referee?.attributes?.referees_licenses
                                      ?.attributes?.national_license_type
                                      ?.national_license_type_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0">
                        <h5>Bedž (sudija u stolici)</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 m-0">
                        {referee?.attributes?.referees_licenses?.attributes
                            ?.international_chair_badge
                            ?.international_chair_badge_name
                            ? referee?.attributes?.referees_licenses?.attributes
                                  ?.international_chair_badge
                                  ?.international_chair_badge_name
                            : '-'}
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0" style={{ alignSelf: 'center' }}>
                        <h5>Bedž (glavni sudija)</h5>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center p-0 m-0">
                        {referee?.attributes?.referees_licenses?.attributes
                            ?.international_main_badge
                            ?.international_main_badge_name
                            ? referee?.attributes?.referees_licenses?.attributes
                                  ?.international_main_badge
                                  ?.international_main_badge_name
                            : '-'}
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0 pb-3">
                        <Link
                            to={`/sudije/${referee?.attributes?.uuid}/izmeni-profil-sudije`}
                        >
                            <p className="link">Izmeni </p>
                        </Link>
                    </Col>
                </Row>
            ))}
            <div className="d-md-none">
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    total={total}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    showOnMobile
                />
            </div>
        </>
    )
}

export default TableRefereeCard
