import { RowsPerPageOptions } from '../components/RowsPerPageDropdown'
import axiosInstance from './axiosInstance'
import {
    containsWords,
    createOrFiltersQueryBySameProperty,
    handleApiGender,
    handleFieldsQuery,
    generateCompetitionQueryString,
    rangleOfYearsBasedOnPlayersCompetition,
    removeWordAndJoin,
} from './helpers'

export const getLoggedInUserInfo = async () => {
    try {
        const { data } = await axiosInstance.get(`api/users/me?populate=role`)
        return data
    } catch (error) {
        console.error(error)
    }
}

export const registerUser = async (auth, user_data, profileImg) => {
    try {
        let formData = new FormData()
        formData.append('auth', JSON.stringify(auth))
        formData.append('user_data', JSON.stringify(user_data))

        if (profileImg?.length > 0) {
            profileImg.map((document) => formData.append('img', document))
        } else {
            formData.append('img', profileImg)
        }

        const { data } = await axiosInstance.post(
            'api/auth/local/register',
            formData
        )
        return data
    } catch (error) {
        throw { ...error }
    }
}

export const getUserInfo = async (userData, navigate, setUser, pathname) => {
    try {
        if (userData) {
            if (userData.role.type === 'competitor') {
                setUser(userData)
                return navigate(pathname ? pathname : '/takmicar/pocetna')
            } else if (userData.role.type === 'referee') {
                if (userData.data.attributes.is_administrator === true) {
                    setUser(userData)
                    return navigate(
                        pathname ? pathname : '/administrator-sudjenja/pocetna'
                    )
                } else {
                    setUser(userData)
                    return navigate(pathname ? pathname : '/sudije/pocetna')
                }
            } else if (userData.role.type === 'admin') {
                setUser(userData)
                return navigate(pathname ? pathname : '/pocetna')
            } else {
                return navigate('/')
            }
        }
    } catch (error) {
        console.error(error)
    }
}

// Fields is array of fields to get in response
export const getClubs = async (
    fields,
    searchParams,
    filterParams,
    page,
    allEntries = false,
    rowsPerPage = false
) => {
    let query = fields && handleFieldsQuery(fields)

    if (page) {
        page = page
    } else {
        page = 1
    }

    let searchFilters = `filters[$or][0][name][$containsi]=${searchParams?.name}`

    let filterString = ''
    let counter = 0

    if (filterParams?.region) {
        filterParams?.region?.map(({ value }, index) => {
            let newString = `filters[$and][${counter}][region][region_name][$in][${index}]=${value}&`
            filterString = filterString.concat(newString)
        })
        counter++
    }

    if (filterParams?.is_active) {
        let newString = `filters[$and][${counter}][is_active][$eq]=${filterParams?.is_active}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.is_inactive) {
        let newString = `filters[$and][${counter}][is_inactive][$eq]=${filterParams?.is_inactive}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.is_resting) {
        let newString = `filters[$and][${counter}][is_resting][$eq]=${filterParams?.is_resting}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_clay_courts) {
        let newString = `filters[$and][${counter}][courts][number_of_clay_courts][$gte]=${filterParams?.number_of_clay_courts}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_hard_courts) {
        let newString = `filters[$and][${counter}][courts][number_of_hard_courts][$gte]=${filterParams?.number_of_hard_courts}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_carpet_courts) {
        let newString = `filters[$and][${counter}][courts][number_of_carpet_courts][$gte]=${filterParams?.number_of_carpet_courts}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_indoor_courts) {
        let newString = `filters[$and][${counter}][courts][number_of_indoor_courts][$gte]=${filterParams?.number_of_indoor_courts}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_reflector_courts) {
        let newString = `filters[$and][${counter}][courts][number_of_reflector_courts][$gte]=${filterParams?.number_of_reflector_courts}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_players_from) {
        let newString = `filters[$and][${counter}][number_of_players_from]=${filterParams?.number_of_players_from}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_players_to) {
        let newString = `filters[$and][${counter}][number_of_players_to]=${filterParams?.number_of_players_to}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_coaches_from) {
        let newString = `filters[$and][${counter}][number_of_coaches_from]=${filterParams?.number_of_coaches_from}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.number_of_coaches_to) {
        let newString = `filters[$and][${counter}][number_of_coaches_to]=${filterParams?.number_of_coaches_to}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.alreadySelectedClubs) {
        // let count = 0
        filterParams?.alreadySelectedClubs?.map((entry, index) => {
            let newString = `filters[$and][${counter}][uuid][$notIn][${index}]=${entry}&`
            filterString = filterString.concat(newString)
        })
        counter++
    }

    if (filterParams?.period_from) {
        let newString = `period_from=${filterParams?.period_from}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.period_to) {
        let newString = `period_to=${filterParams?.period_to}&`
        filterString = filterString.concat(newString)
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/clubs?${filterParams ? filterString : ''}${
                searchParams ? searchFilters : ''
            }&populate=*${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=name`
        )
        let clubs = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { clubs, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getClubsCoaches = async (clubUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/coachs-clubs?filters[club_uuid]=${clubUuid}&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )

        let coaches = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { coaches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

// Fields is array of fields to get in response
export const getCoaches = async (
    fields,
    searchParams,
    filterParams,
    page,
    allEntries = false,
    rowsPerPage = false
) => {
    let query = fields ? handleFieldsQuery(fields) : ''

    if (page) {
        page = page
    } else {
        page = 1
    }

    let searchFilters = `filters[$or][0][person_info][given_name][$containsi]=${
        searchParams?.given_name
    }&filters[${
        searchParams?.family_name ? '$and' : '$or'
    }][1][person_info][family_name][$containsi]=${
        searchParams?.family_name
            ? searchParams?.family_name
            : searchParams?.given_name
    }`

    // let filterFields = []
    let filterString = ''

    if (filterParams?.gender) {
        // filterFields.push({ name: 'gender', value: filterParams?.gender })
        let newString = `filters[$and][0][gender][gender_name][$eq]=${handleApiGender(
            filterParams?.gender
        )}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.gptca_license) {
        let newString = `gptca_license=${filterParams?.gptca_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.itf_license) {
        let newString = `itf_license=${filterParams?.itf_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.lta_license) {
        let newString = `lta_license=${filterParams?.lta_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.national_license) {
        let newString = `national_license=${filterParams?.national_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.ptr_license) {
        let newString = `ptr_license=${filterParams?.ptr_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.uspta_license) {
        let newString = `uspta_license=${filterParams?.uspta_license}&`
        filterString = filterString.concat(newString)
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/coaches?${filterParams ? filterString : ''}${
                searchParams ? searchFilters : ''
            }&populate[0]=person_info.picture&populate[1]=gender${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=person_info.given_name&sort[1]=person_info.family_name`
        )
        let allCoaches = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total

        return { allCoaches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const createCoachsClub = async (coachData) => {
    try {
        const { data } = await axiosInstance.post('api/coachs-clubs', {
            data: {
                ...coachData,
            },
        })
        return data.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getCoachsClub = async (coachUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/coachs-clubs?filters[coach_uuid]=${coachUuid}&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )
        return data.data[0]
    } catch (e) {
        throw new Error(e)
    }
}

export const updateCoachsClub = async (uuuid, coachsClubData) => {
    try {
        const { data } = await axiosInstance.put(`api/coachs-clubs/${uuuid}`, {
            data: {
                ...coachsClubData,
            },
        })

        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Create coach
export const createCoach = async (coachData, coachImg) => {
    try {
        let formData = new FormData()
        formData.append('person_info', JSON.stringify(coachData))
        coachImg && formData.append('picture', coachImg)

        const { data } = await axiosInstance.post('api/coaches', formData)
        return data.data
    } catch (e) {
        throw new Error(e)
    }
}

// Get single club
export const getSingleClub = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/clubs/${uuid}?populate[gallery][populate][0]=photo&populate[staff][populate][1]=person_info&populate[address][populate]&populate[courts][populate]&populate[region][populate]&populate[logo][populate]`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getSingleClubWithQuery = async (uuid, query = '') => {
    try {
        const { data } = await axiosInstance.get(`api/clubs/${uuid}?${query}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get single coach
export const getSingleCoach = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/coaches/${uuid}?populate[0]=person_info.picture&populate[1]=gender`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateRefereeProfile = async (
    refereeUuid,
    refereeData,
    refereeImg
) => {
    try {
        let formData = new FormData()
        formData.append('person_info', JSON.stringify(refereeData))
        refereeImg && formData.append('picture', refereeImg)

        const { data } = await axiosInstance.put(
            `api/referees/${refereeUuid}`,
            formData
        )

        return data?.data
    } catch (error) {
        throw { ...error }
    }
}

export const createReferee = async (refereeData, refereeImg) => {
    try {
        let formData = new FormData()
        formData.append('person_info', JSON.stringify(refereeData))
        formData.append('picture', refereeImg)

        const data = await axiosInstance.post('api/referees', formData)
        return data?.data
    } catch (e) {
        throw e
    }
}

// Fields is array of fields to get in response
export const getReferees = async (
    fields,
    searchParams,
    filterParams,
    page,
    allEntries,
    rowsPerPage = false
) => {
    let query = fields ? handleFieldsQuery(fields) : ''

    if (page) {
        page = page
    } else {
        page = 1
    }

    let searchFilters = `filters[$or][0][person_info][given_name][$containsi]=${
        searchParams?.given_name
    }&filters[${
        searchParams?.family_name ? '$and' : '$or'
    }][1][person_info][family_name][$containsi]=${
        searchParams?.family_name
            ? searchParams?.family_name
            : searchParams?.given_name
    }`

    let filterString = ''

    if (filterParams?.gender) {
        let newString = `filters[$and][0][gender][gender_name][$eq]=${handleApiGender(
            filterParams?.gender
        )}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.region) {
        let newString = `filters[$and][0][region][region_name][$eq]=${filterParams?.region}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.core_license) {
        let newString = `core_license=${filterParams?.core_license}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.international_chair_badge) {
        let newString = `international_chair_badge=${filterParams?.international_chair_badge}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.international_main_badge) {
        let newString = `international_main_badge=${filterParams?.international_main_badge}&`
        filterString = filterString.concat(newString)
    }
    if (filterParams?.national_license_type) {
        let newString = `national_license_type=${filterParams?.national_license_type}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.alreadySelectedReferees) {
        let count = 0
        filterParams?.alreadySelectedReferees?.map((entry, index) => {
            let newString = `filters[$and][${count}][uuid][$notIn][${index}]=${entry}&`
            filterString = filterString.concat(newString)
        })
        count += 1
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/referees?${filterParams ? filterString : ''}${
                searchParams ? searchFilters : ''
            }&populate[0]=person_info.picture&populate[1]=region${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=person_info.given_name&sort[1]=person_info.family_name`
        )
        let allReferees = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { allReferees, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const createRefereeTest = async (testData) => {
    try {
        const { data } = await axiosInstance.post(`api/referee-tests`, {
            data: {
                ...testData,
            },
        })

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateRefereeTest = async (testUuid, testData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/referee-tests/${testUuid}`,
            {
                data: {
                    ...testData,
                },
            }
        )

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getRefereeTest = async (refereeUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/referee-tests?filters[referee_uuid]=${refereeUuid}&populate=*&sort=test_date:desc&pagination[page]=1&pagination[pageSize]=1`
        )

        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const createRefereeLicenses = async (licenseData) => {
    try {
        const { data } = await axiosInstance.post(`api/referee-licenses`, {
            data: {
                ...licenseData,
            },
        })

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateRefereeLicenses = async (licenseUuid, licenseData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/referee-licenses/${licenseUuid}`,
            {
                data: {
                    ...licenseData,
                },
            }
        )

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getRefereeLicense = async (refereeUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/referee-licenses?filters[referee_uuid]=${refereeUuid}&populate=*`
        )

        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

// Get single referee
export const getSingleReferee = async (id) => {
    try {
        const { data } = await axiosInstance.get(
            `api/referees/${id}?populate[0]=person_info.picture&populate[1]=residential_address&populate[2]=gender&populate[3]=region&populate[4]=birth_address`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get all medicals
export const getMedicals = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/medical-certificates?populate=attachment&filters[member_uuid]=${memberUuid}&sort=start_date:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get single medical
export const getSingleMedical = async (id) => {
    try {
        const { data } = await axiosInstance.get(
            `api/medical-certificates/${id}?populate=*`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Create new Medical Certificate
export const createMedical = async (medicalData, medicalImg) => {
    try {
        const { data } = await axiosInstance.post(`api/medical-certificates`, {
            data: {
                ...medicalData,
                attachment: [],
            },
        })

        let certificateData = data.data

        let formData = new FormData()
        formData.append('files', medicalImg)
        formData.append('ref', 'api::medical-certificate.medical-certificate')
        formData.append('refId', certificateData.id)
        formData.append('field', 'attachment')
        const { data: imgData } = await axiosInstance.post(
            `api/upload`,
            formData
        )
        let medicalImageData = imgData[0]
        return { certificateData, medicalImageData }
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteMedical = async (medicalUUID) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/medical-certificates/${medicalUUID}`
        )
        return data?.data
    } catch (error) {
        throw new Error(error)
    }
}

// Update existing Medical Certificate
export const updateMedical = async (id, medicalData, formData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/medical-certificates/${id}`,
            {
                data: medicalData,
            }
        )
        const { data: medicalImgData } = await axiosInstance.post(
            `api/upload`,
            formData
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Link Coach to medical certificate
export const linkCoachToMedical = async (
    coachId,
    medicalId,
    medical_history
) => {
    try {
        const { data } = await axiosInstance.put(`api/coaches/${coachId}`, {
            data: {
                medical_id: medicalId,
                medical_history: medical_history,
            },
        })

        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Link Referee to medical certificate
export const linkRefereeToMedical = async (
    refereeId,
    medicalId,
    medical_history
) => {
    try {
        const { data } = await axiosInstance.put(`api/referees/${refereeId}`, {
            data: {
                medical_id: medicalId,
                medical_history: medical_history,
            },
        })

        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Create club
export const createClub = async (clubData, clubImg) => {
    try {
        let formData = new FormData()
        formData.append('club_data', JSON.stringify(clubData))
        clubImg && formData.append('logo', clubImg)

        const { data } = await axiosInstance.post('api/clubs', formData)
        return data.data
    } catch (e) {
        throw new Error(e)
    }
}

// Add club membership
export const createClubMembership = async (clubId, membershipData) => {
    try {
        const { data } = await axiosInstance.put(`api/clubs/${clubId}`, {
            data: membershipData,
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Add club photos
export const addClubPhotos = async (clubId, clubImgs) => {
    try {
        if (clubImgs?.length > 0) {
            let formData = new FormData()
            clubImgs.forEach((img) => {
                formData.append('files', img)
            })

            formData.append('ref', 'api::club.club')
            formData.append('refId', clubId)
            formData.append('field', 'club_imgs')
            const { data } = await axiosInstance.post(`api/upload`, formData)

            return data
        }
    } catch (error) {
        console.error(error)
    }
}

// Get competitors ranking lists
export const getCompetitorsRankingLists = async (page, rowsPerPage = false) => {
    const pageSize = rowsPerPage ? rowsPerPage : RowsPerPageOptions[0]
    try {
        const { data } = await axiosInstance.get(
            `api/competitors-ranking-lists?filters[region][region_name][$null]=true&populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=createdAt:desc`
        )
        let rankingLists = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { rankingLists, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getRegionCompetitorsRankingLists = async (
    page,
    rowsPerPage = false
) => {
    const pageSize = rowsPerPage ? rowsPerPage : RowsPerPageOptions[0]
    try {
        const { data } = await axiosInstance.get(
            `api/competitors-ranking-lists?filters[region][region_name][$notNull]=true&populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=createdAt:desc`
        )
        let rankingLists = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { rankingLists, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getSingleCompetitorsRankingList = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/competitors-ranking-lists/${uuid}?populate=*`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const deleteCompetitorsRankingList = async (uuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/competitors-ranking-lists/${uuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getClubsRankingList = async (
    page = 1,
    isCurrentYear,
    allEntries = false,
    rowsPerPage = false
) => {
    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]
    try {
        const { data } = await axiosInstance.get(
            `api/club-ratings?filters[is_current_year]=${isCurrentYear}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=total_points:desc`
        )
        let clubs = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { clubs, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getClubsAssemblyRankingList = async (
    page = 1,
    allEntries = false,
    rowsPerPage = false
) => {
    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]
    try {
        const { data } = await axiosInstance.get(
            `api/club-assembly-rankings?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=total_votes:desc`
        )
        let clubs = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { clubs, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersForRankingList = async (
    rankingListUuid,
    page = 1,
    isCurrentYear,
    allEntries = false,
    rowsPerPage = false,
    filterParams = {}
) => {
    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    let filterString = ''
    if (filterParams?.region) {
        filterParams?.region?.map(({ value }) => {
            let newString = `region=${value}&`
            filterString = filterString.concat(newString)
        })
    }

    try {
        const { data } = await axiosInstance.get(
            `api/player-ratings?filters[$and][0][ranking_list_uuid]=${rankingListUuid}&filters[$and][1][is_current_year]=${isCurrentYear}&${filterString}populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=ranking_number:asc`
        )
        let players = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { players, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const filterPlayersInRankingList = async (
    rankingListUUID,
    filterParams,
    page,
    pageSize
) => {
    let queryString = ''

    if (filterParams?.region) {
        filterParams?.region?.map((region) => {
            queryString += `&region=${region?.value}`
        })
    }

    if (filterParams?.period_from) {
        queryString += `&period_from=${filterParams?.period_from}`
    }

    if (filterParams?.period_to) {
        queryString += `&period_to=${filterParams?.period_to}`
    }

    if (filterParams?.regional_only) {
        queryString += `&regional_only=${filterParams?.regional_only}`
    }

    try {
        const { data } = await axiosInstance.get(
            `api/filter-player-rank?ranking_list_uuid=${rankingListUUID}${queryString}&page=${page}&pageSize=${pageSize}`
        )

        let players = data?.data || []
        let pgCount = data?.pageCount || 0
        let total = data?.total || 0
        return { players, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const updatePlayersRanking = async (uuid, newData) => {
    try {
        const { data } = await axiosInstance.put(`api/player-ratings/${uuid}`, {
            data: {
                ...newData,
            },
        })
        return data?.data
    } catch (error) {
        console.error(error)
    }
}

export const calculateRank = async () => {
    try {
        const { data } = await axiosInstance.get(`api/rank-calculations`)
        return data.message
    } catch (error) {
        console.error(error)
    }
}

export const calculateSeed = async (seedData) => {
    try {
        const { data } = await axiosInstance.get(
            `api/seed-calculations?tournament_uuid=${seedData?.tournament_uuid}&gender=${seedData?.gender}&is_double=false`
        )
        return data.message
    } catch (error) {
        console.error(error)
    }
}

export const calculateClubRank = async () => {
    try {
        const { data } = await axiosInstance.get(`api/club-rank-calculations`)
        return data.message
    } catch (error) {
        console.error(error)
    }
}

export const calculateGeneralClubRank = async () => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-general-rank-calculations`
        )
        return data.message
    } catch (error) {
        console.error(error)
    }
}

export const calculateAssemblyClubRank = async () => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-assembly-rank-calculations`
        )
        return data.message
    } catch (error) {
        console.error(error)
    }
}

// Create competitors ranking list
export const createCompetitorsRankingList = async (listData) => {
    try {
        const { data } = await axiosInstance.post(
            'api/competitors-ranking-lists',
            {
                data: { ...listData },
            }
        )

        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get competitors categories
export const getCompetitorsCategories = async () => {
    try {
        const { data } = await axiosInstance.get(
            'api/competitor-categories?sort[0]=id'
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Create Tournament - start
export const createTournament = async (tournament) => {
    try {
        const { data } = await axiosInstance.post(`api/tournaments`, {
            data: {
                ...tournament,
            },
        })
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

// Update Tournament - start
export const updateTournament = async (tournamentId, tournamentData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournaments/${tournamentId}`,
            {
                data: tournamentData,
            }
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

// Delete Single Referee
export const deleteReferee = async (refereeUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/referees/${refereeUuid}`
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

// Delete Single Coach
export const deleteCoach = async (coachUuid) => {
    try {
        const { data } = await axiosInstance.delete(`api/coaches/${coachUuid}`)
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

// Delete Single Competitor
export const deletePlayer = async (playerUuid) => {
    try {
        const data = await axiosInstance.delete(`api/players/${playerUuid}`)

        return data?.data
    } catch (error) {
        throw new Error(error)
    }
}

export const createSpecialPlayer = async (tournamentUuid, playerData) => {
    try {
        const { data } = await axiosInstance.post(`api/players`, {
            data: {
                ...playerData,
            },
        })

        if (data?.data) {
            const tournamentParticipant = await addPlayerOnTurnament({
                player_1_uuid: data?.data?.attributes?.uuid,
                tournament_uuid: tournamentUuid,
                gender: playerData?.gender,
            })

            return { player: data?.data, participant: tournamentParticipant }
        }
    } catch (error) {
        throw new Error(error)
    }
}

// Delete competition
export const deleteTournament = async (id) => {
    try {
        const { data } = await axiosInstance.delete(`api/tournaments/${id}`)
        return data.message
    } catch (error) {
        throw { ...error }
    }
}

// Delete single club
export const deleteClub = async (clubId) => {
    try {
        const { data } = await axiosInstance.delete(`api/clubs/${clubId}`)
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

// Update single club - start

export const updateClubProfile = async (clubUuid, clubData, newImage) => {
    try {
        let formData = new FormData()
        formData.append('data', JSON.stringify(clubData))
        newImage && formData.append(`files.logo`, newImage, newImage.name)

        const { data } = await axiosInstance.put(
            `api/clubs/${clubUuid}`,
            formData
        )

        return data?.data
    } catch (error) {
        throw new Error(error)
    }
}
// Update single club - end

// Update coach profile - start
export const updateCoachProfile = async (coachesId, updatedData, newImage) => {
    try {
        const { data } = await axiosInstance.put(
            `api/coaches/${coachesId}`,
            updatedData
        )

        let returnData = data.data

        if (newImage) {
            let formData = new FormData()
            formData.append('files', newImage)
            formData.append('ref', 'api::coach.coach')
            formData.append('refId', coachesId)
            formData.append('field', 'img')
            const { data: imgData } = await axiosInstance.post(
                `api/upload`,
                formData
            )
            let coachNewImage = imgData[0]
            return { returnData, coachNewImage }
        }

        return returnData
    } catch (error) {
        console.error(error)
    }
}

// Create New Scoring Template - start
export const createEntryPoints = async (entryPointsName, entryPointsUuid) => {
    try {
        const { data } = await axiosInstance.post(`api/entry-points`, {
            data: {
                entryPointsName: entryPointsName,
                entryPointsUuid: entryPointsUuid,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get Entry Points - start
export const getEntryPoints = async (
    fields,
    page,
    searchParams,
    rowsPerPage = false
) => {
    if (page) {
        page = page
    } else {
        page = 1
    }

    const pageSize = rowsPerPage ? rowsPerPage : RowsPerPageOptions[0]

    let searchFilters = `filters[$or][0][entry_points_name][$containsi]=${searchParams?.entry_points_name}`

    let sort = 'id:ASC'
    try {
        if (fields) {
            let query = fields && handleFieldsQuery(fields).slice(1)
            const { data } = await axiosInstance.get(
                `api/entry-points?${fields ? query : ''}&${
                    searchParams ? searchFilters : ''
                }&populate=entry_points_status&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=${sort}`
            )
            let entryPoints = data?.data
            let pgCount = data?.meta?.pagination?.pageCount
            let total = data?.meta?.pagination?.total
            return { entryPoints, pgCount, total }
        } else {
            const { data } = await axiosInstance.get(`api/entry-points`)
            return data.data
        }
    } catch (error) {
        console.error(error)
    }
}

// Get Single Entry Points - start
export const getSingleEntryPoints = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/entry-points/${uuid}?populate=*`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}
// Update Entry Points - start
export const updateEntryPoints = async (uuid, newEntryPointsData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/entry-points/${uuid}?populate=*`,
            {
                data: newEntryPointsData,
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPenalties = async (params = {}) => {
    const { query = '' } = params
    try {
        const { data } = await axiosInstance.get(`api/penalties?${query}`)
        return data?.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayerPenaltyPoints = async (playerUUID) => {
    try {
        const { data } = await axiosInstance.get(
            `api/player-penalty-points/${playerUUID}`
        )

        return data?.data?.total_penalty_points
    } catch (error) {
        console.error(error)
    }
}

// Create competition report - start
export const createTournamentReport = async (
    report_data,
    tournament_uuid,
    referee_uuid
) => {
    try {
        const { data } = await axiosInstance.post(`api/tournament-reports`, {
            data: {
                report_data,
                tournament_uuid,
                referee_uuid,
            },
        })
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

// Add competition field - start
export const createField = async (fieldData) => {
    try {
        const { data } = await axiosInstance.post(`api/fields`, {
            data: {
                ...fieldData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentsFields = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/fields?filters[tournament_uuid]=${tournamentUuid}&sort=createdAt`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Edit competition field - start
export const editCompetitionField = async (fieldId, field) => {
    try {
        const { data } = await axiosInstance.put(`api/fields/${fieldId}`, {
            data: {
                ...field,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Delete competition field - start
export const deleteTournamentField = async (id) => {
    try {
        const { data } = await axiosInstance.delete(`api/fields/${id}`)
        return data.data
    } catch (error) {
        console.error(error)
        throw new Error('Error deleting field')
    }
}

export const updateCompetitorStatus = async (statusId, status) => {
    try {
        const { data } = await axiosInstance.put(
            `api/competitor-statuses/${statusId}`,
            {
                data: {
                    status: status,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const createMatch = async (matchData) => {
    try {
        const { data } = await axiosInstance.post(`api/matches`, {
            data: {
                ...matchData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getMatches = async (query = '') => {
    try {
        const { data } = await axiosInstance.get(`api/matches?${query}`)

        const matches = data?.data || []
        const pgCount = data?.meta?.pagination?.pageCount
        const total = data?.meta?.pagination?.total

        return { matches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getMatchesBetweenClubs = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][club_match_uuid]=${matchDetails?.club_match_uuid}&filters[$and][1][gender][gender_name]=${matchDetails?.gender}&sort=table_number&populate[0]=side_1_result&populate[1]=side_2_result`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateMatch = async (matchId, matchData) => {
    try {
        const { data } = await axiosInstance.put(`api/matches/${matchId}`, {
            data: {
                ...matchData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getLegacyMatches = async (tournamentUuid, roundNumber) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][tournament_uuid][$eq]=${tournamentUuid}&filters[$and][1][round_number][$eq]=${roundNumber}`
        )

        let matches = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { matches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const deleteMatch = async (matchUuid) => {
    try {
        const { data } = await axiosInstance.delete(`api/matches/${matchUuid}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const addResultToMatch = async (matchId, resultData) => {
    try {
        const { data } = await axiosInstance.put(`api/matches/${matchId}`, {
            data: {
                ...resultData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getAllParticipantsWinsFromTournament = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][$or][0][side_1_uuid]=${matchDetails?.participant_uuid}&filters[$and][0][$or][1][side_2_uuid]=${matchDetails?.participant_uuid}&filters[$and][1][tournament_uuid]=${matchDetails?.tournament_uuid}&filters[$and][2][loser_participant_uuid][$notNull]=true&filters[$and][3][loser_participant_uuid][$ne]=${matchDetails?.participant_uuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getNumberOfPlayerWinsInRoundRobin = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][$or][0][side_1_uuid]=${
                matchDetails?.participant_uuid
            }&filters[$and][0][$or][1][side_2_uuid]=${
                matchDetails?.participant_uuid
            }&filters[$and][1][tournament_uuid]=${
                matchDetails?.tournament_uuid
            }&filters[$and][2][loser_participant_uuid][$notNull]=true&filters[$and][3][loser_participant_uuid][$ne]=${
                matchDetails?.participant_uuid
            }&filters[$and][4][draw_type][draw_type_name]=${'Round robin'}`
        )
        return data.data.length
    } catch (error) {
        console.error(error)
    }
}

export const createSchedule = async (scheduleData) => {
    try {
        const { data } = await axiosInstance.post(`api/schedules`, {
            data: {
                ...scheduleData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const editSchedule = async (scheduleUuid, scheduleData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/schedules/${scheduleUuid}`,
            {
                data: {
                    ...scheduleData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const removeSchedule = async (uuid) => {
    try {
        const { data } = await axiosInstance.delete(`api/schedules/${uuid}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getMatchsSchedule = async (matchUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/schedules?filters[match_uuid]=${matchUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getSingleSchedule = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(`api/schedules/${uuid}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const createGroup = async (groupData) => {
    try {
        const { data } = await axiosInstance.post(`api/groups`, {
            data: {
                ...groupData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateGroup = async (groupId, groupData) => {
    try {
        const { data } = await axiosInstance.put(`api/groups/${groupId}`, {
            data: {
                ...groupData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const createClubMatch = async (matchData) => {
    try {
        const { data } = await axiosInstance.post(`api/club-matches`, {
            data: {
                ...matchData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getClubsMatchesFromTournament = async (
    tournamentUuid,
    participantUuid
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[$and][0][tournament_uuid]=${tournamentUuid}&filters[$and][1][$or][0][side_1_uuid]=${participantUuid}&filters[$and][1][$or][1][side_2_uuid]=${participantUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getSingleClubMatch = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[$and][0][tournament_uuid]=${matchDetails?.tournament_uuid}&filters[$and][1][round_number]=${matchDetails?.round_number}&filters[$and][2][pair_number]=${matchDetails?.pair_number}&filters[$and][3][draw_type][draw_type_name]=${matchDetails?.draw_type?.draw_type_name}`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const getSingleClubPrevMatch = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[$and][0][tournament_uuid]=${matchDetails?.tournament_uuid}&filters[$and][1][round_number]=${matchDetails?.round_number}&filters[$and][2][draw_type][draw_type_name]=${matchDetails?.draw_type?.draw_type_name}&filters[$and][3][$or][0][side_1_uuid]=${matchDetails?.participant_uuid}&filters[$and][3][$or][1][side_2_uuid]=${matchDetails?.participant_uuid}`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const getGroupClubMatches = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[$and][0][tournament_uuid]=${matchDetails?.tournament_uuid}&filters[$and][1][draw_type][draw_type_name]=${matchDetails?.draw_type?.draw_type_name}&filters[$and][2][round_number]=${matchDetails?.round_number}`
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const deleteClubMatch = async (uuid) => {
    try {
        const { data } = await axiosInstance.delete(`api/club-matches/${uuid}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateClubMatch = async (matchUuid, resultData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/club-matches/${matchUuid}`,
            {
                data: {
                    ...resultData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const sendForgotPassword = async (email) => {
    try {
        const data = await axiosInstance.post(`api/auth/forgot-password`, {
            email: email,
        })
        return data
    } catch (error) {
        console.error(error)
    }
}

export const changePassword = async (newPasswordData) => {
    try {
        const data = await axiosInstance.post(`api/auth/reset-password`, {
            ...newPasswordData,
        })
        return data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayers = async (
    fields,
    searchParams,
    filterParams,
    page,
    allEntries = false,
    rowsPerPage = false
) => {
    let query = fields && handleFieldsQuery(fields)

    let count = 0

    if (page) {
        page = page
    } else {
        page = 1
    }

    let searchFilters = `filters[$or][0][person_info][given_name][$containsi]=${
        searchParams?.given_name
    }&filters[${
        searchParams?.family_name ? '$and' : '$or'
    }][1][person_info][family_name][$containsi]=${
        searchParams?.family_name
            ? searchParams?.family_name
            : searchParams?.given_name
    }`

    let filterString = ''

    if (filterParams?.gender) {
        let newString = `filters[$and][${count}][gender][gender_name][$eq]=${filterParams?.gender}&`
        filterString = filterString.concat(newString)
        count += 1
    }

    if (filterParams?.birth_country) {
        let newString = `filters[$and][${count}][birth_address][country_code][$eq]=${filterParams?.birth_country}&`
        filterString = filterString.concat(newString)
        count += 1
    }

    if (filterParams?.birth_city) {
        let newString = `filters[$and][${count}][birth_address][city][$eq]=${filterParams?.birth_city}&`
        filterString = filterString.concat(newString)
        count += 1
    }

    if (filterParams?.status) {
        let newString
        if (filterParams?.searchDraft) {
            newString = `filters[$and][${count}][status][status_name][$eq]=draft&`
        } else {
            newString =
                filterParams?.status === 'draft'
                    ? ``
                    : `filters[$and][${count}][status][status_name][$eq]=${filterParams?.status}&`
        }
        filterString = filterString.concat(newString)
        count += 1
    }

    if (filterParams?.players_competition) {
        const { startDate, endDate } = rangleOfYearsBasedOnPlayersCompetition(
            filterParams?.players_competition
        )

        if (startDate) {
            let filterGte = `filters[$and][${count}][person_info][birthdate][$gte]=${startDate}&`
            filterString = filterString.concat(filterGte)
            count += 1
        }

        if (endDate) {
            let filterLte = `filters[$and][${count}][person_info][birthdate][$lt]=${endDate}&`
            filterString = filterString.concat(filterLte)
            count += 1
        }
    }

    if (filterParams?.region) {
        let newString = `region=${filterParams?.region}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.club_uuid) {
        let newString = `club_uuid=${filterParams?.club_uuid}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.punished) {
        let newString = `punished=${filterParams?.punished}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.number_of_tournaments_from) {
        let newString = `number_of_tournaments_from=${filterParams?.number_of_tournaments_from}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.number_of_tournaments_to) {
        let newString = `number_of_tournaments_to=${filterParams?.number_of_tournaments_to}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.number_of_matches_from) {
        let newString = `number_of_matches_from=${filterParams?.number_of_matches_from}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.number_of_matches_to) {
        let newString = `number_of_matches_to=${filterParams?.number_of_matches_to}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.period_from) {
        let newString = `period_from=${filterParams?.period_from}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.period_to) {
        let newString = `period_to=${filterParams?.period_to}&`
        filterString = filterString.concat(newString)
    }

    if (filterParams?.alreadySelectedPlayers) {
        filterParams?.alreadySelectedPlayers?.map((entry, index) => {
            let newString = `filters[$and][${count}][uuid][$notIn][${index}]=${entry}&`
            filterString = filterString.concat(newString)
        })
        count += 1
    }

    // Do not bring filters from below this comment up before other filters

    if (filterParams?.birthdate) {
        let startDate = `${filterParams?.birthdate}-01-01`
        let endDate = `${filterParams?.birthdate}-12-31`

        let filterGte = `filters[$and][${count}][person_info][birthdate][$gte]=${startDate}&`
        count += 1
        let filterLte = `filters[$and][${count}][person_info][birthdate][$lte]=${endDate}&`
        filterString = filterString.concat(filterGte, filterLte)
        count += 1
    }

    if (filterParams?.competition) {
        let currentYear = new Date().getFullYear()
        let competitionYearStart
        let competitionYearEnd

        switch (filterParams?.competition) {
            case '10s - Crveni': // od 6 do  8 godina, ne racunajuci 8 godina
                competitionYearStart = currentYear - 7
                competitionYearEnd = currentYear - 6
                break
            case '10s - Narandžasti': // 8 godina
                competitionYearStart = currentYear - 8
                competitionYearEnd = currentYear - 8
                break
            case '10s - Zeleni': // 9 i 10 godina
                competitionYearStart = currentYear - 10
                competitionYearEnd = currentYear - 9
                break
            case '10s': // od 6 do 10, racunajuci i 10
                competitionYearStart = currentYear - 10
                competitionYearEnd = currentYear
                break
            case '12': // 11 i 12 godina
                competitionYearStart = currentYear - 12
                competitionYearEnd = currentYear - 11
                break
            case '14': //13 i 14 godina
                competitionYearStart = currentYear - 14
                competitionYearEnd = currentYear - 13
                break
            case '16': // 15 i 16 godina
                competitionYearStart = currentYear - 16
                competitionYearEnd = currentYear - 15
                break
            case '18': // 17 i 18 godina
                competitionYearStart = currentYear - 18
                competitionYearEnd = currentYear - 17
                break
            case 'Seniori': // stariji od 18 godina
                competitionYearStart = 1899
                competitionYearEnd = currentYear - 19
                break
        }

        let startDate = `${competitionYearStart}-01-01`
        let endDate = `${competitionYearEnd}-12-31`

        let filterGte = `filters[$and][${count}][person_info][birthdate][$gte]=${startDate}&`
        count += 1
        let filterLte = `filters[$and][${count}][person_info][birthdate][$lte]=${endDate}&`
        filterString = filterString.concat(filterGte, filterLte)
        count += 1
    }

    if (filterParams?.email_is_null) {
        let newString = `filters[$and][${count}][person_info][email][$null]=true&`
        filterString = filterString.concat(newString)
    }
    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/players?${filterParams ? filterString : ''}${
                searchParams ? searchFilters : ''
            }&populate[0]=person_info&populate[1]=person_info.picture&populate[2]=status.status_name${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=person_info.given_name&sort[1]=person_info.family_name`
        )

        let competitors = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { competitors, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getSinglePlayer = async (uuid, fields) => {
    let query = fields && handleFieldsQuery(fields)

    try {
        const { data } = await axiosInstance.get(
            `api/players/${uuid}?${
                fields ? query : ''
            }&populate[0]=person_info.picture&populate[1]=residential_address&populate[2]=birth_address&populate[3]=gender&populate[4]=status&populate[5]=playing_hand&populate[6]=citizenship.citizenship_type&populate[7]=allowed_competitions`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getSinglePlayerWithQuery = async (uuid, query) => {
    try {
        const { data } = await axiosInstance.get(`api/players/${uuid}?${query}`)
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayerStatistisc = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/player-statistics/${playerUuid}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

export const updatePlayer = async (playerUuid, playerData, playerImg) => {
    try {
        let formData = new FormData()
        formData.append('person_info', JSON.stringify(playerData))
        playerImg && formData.append('picture', playerImg)

        const { data } = await axiosInstance.put(
            `api/players/${playerUuid}`,
            formData
        )

        return data?.data
    } catch (error) {
        throw { ...error }
    }
}

export const addMembership = async (membershipsData) => {
    try {
        const { data } = await axiosInstance.post(`api/memberships`, {
            data: { ...membershipsData },
        })
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const getMemberships = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/memberships?filters[member_uuid]=${memberUuid}&sort=date_of_payment:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const removeMembership = async (membershipsUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/memberships/${membershipsUuid}`
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const addPlayersPenalties = async (penaltyData) => {
    try {
        const { data } = await axiosInstance.post(`api/players-penalties`, {
            data: { ...penaltyData },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersPenalties = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-penalties?filters[player_uuid]=${playerUuid}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const deletePenalty = async (penaltyUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/players-penalties/${penaltyUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getMatchPenalties = async (matchUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-penalties?filters[match_uuid]=${matchUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getLastMembership = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/memberships?filters[member_uuid]=${memberUuid}&sort=date_of_payment:desc&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getFirstMembership = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/memberships?filters[member_uuid]=${memberUuid}&sort=date_of_payment:asc&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getLastFourMemberships = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/memberships?filters[member_uuid]=${memberUuid}&sort=date_of_payment:desc&pagination[page]=1&pagination[pageSize]=4`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getLastMedicalCerificate = async (memberUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/medical-certificates?filters[member_uuid]=${memberUuid}&sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Get Coach licenses
export const getCoachLicenses = async (coachUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/coach-licenses/${coachUuid}?filters[coach_uuid]=${coachUuid}&populate=*`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

// Create coach licenses
export const createCoachLicenses = async (licensesData) => {
    try {
        const { data } = await axiosInstance.post(`api/coach-licenses`, {
            data: licensesData,
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateCoach = async (coachUuid, coachData, coachImg) => {
    try {
        let formData = new FormData()
        formData.append('person_info', JSON.stringify(coachData))
        coachImg && formData.append('picture', coachImg)

        const { data } = await axiosInstance.put(
            `api/coaches/${coachUuid}`,
            formData
        )

        return data?.data
    } catch (error) {
        throw new Error(error)
    }
}

export const updateCoachLicenses = async (licenseUuid, licenseData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/coach-licenses/${licenseUuid}`,
            {
                data: {
                    ...licenseData,
                },
            }
        )

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const playersClubRegistration = async (registrationData) => {
    try {
        const { data } = await axiosInstance.post(`api/players-clubs`, {
            data: registrationData,
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const playersClubDeparture = async (entityUuid, departureDate) => {
    try {
        const { data } = await axiosInstance.put(
            `api/players-clubs/${entityUuid}`,
            {
                data: {
                    departure_date: departureDate,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersClub = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-clubs?filters[player_uuid]=${playerUuid}&filters[is_loan]=false&filters[departure_date][$null]=true&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersCurrentClub = async (playerUuid) => {
    const currentYear = new Date().toLocaleDateString('en-CA')
    try {
        const { data } = await axiosInstance.get(
            `api/players-clubs?filters[player_uuid]=${playerUuid}&filters[$or][0][departure_date][$gte]=${currentYear}&filters[$or][1][departure_date][$null]=true&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersClubLoan = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-clubs?filters[player_uuid]=${playerUuid}&filters[is_loan]=true&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const getClubsPlayers = async (clubUuid, page = 1) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-clubs?filters[$and][0][club_uuid]=${clubUuid}&filters[$and][1][departure_date][$null]=true&filters[$and][2][is_player_active]=true&pagination[page]=${page}&pagination[pageSize]=10`
        )

        let players = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { players, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersClubHistory = async (playerUuid, limit = 3) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-clubs?filters[player_uuid]=${playerUuid}&pagination[page]=1&filters[departure_date][$null]=false&pagination[pageSize]=${limit}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getTournaments = async (
    fields,
    searchParams,
    filterParams,
    page,
    isFinished,
    allEntries = false,
    rowsPerPage = false,
    sortQueryParams = '&sort=createdAt:desc'
) => {
    let query = fields ? handleFieldsQuery(fields) + '&' : '&'

    if (page) {
        page = page
    } else {
        page = 1
    }

    let searchFilters = `filters[$or][0][name][$containsi]=${searchParams?.name}`

    let filterString = generateCompetitionQueryString({
        filterParams: filterParams,
        initFilterString: '',
        initFilterStringIndex: 1,
    })

    let isFinishedFilter

    if (isFinished !== null) {
        isFinishedFilter = `filters[is_finished][$eq]=${isFinished}&`
    } else {
        isFinishedFilter = ''
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    let refereeUuidFilter = filterParams?.refereeUuid
        ? `refereeUuid=${filterParams?.refereeUuid}&`
        : ''

    try {
        const { data } = await axiosInstance.get(
            `api/tournaments?populate=*&${
                filterParams ? filterString : ''
            }${isFinishedFilter}${
                searchParams ? searchFilters : ''
            }${query}${refereeUuidFilter}pagination[page]=${page}&pagination[pageSize]=${pageSize}${sortQueryParams}`
        )

        let tournaments = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { tournaments, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getSingleTournament = async (tournamentUuid, fields) => {
    let query = fields && handleFieldsQuery(fields)

    try {
        const { data } = await axiosInstance.get(
            `api/tournaments/${tournamentUuid}?${
                fields ? query : ''
            }&populate=*`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const addTournamentAdditionalInformations = async (
    tournamentAdditionalInformations
) => {
    try {
        const { data } = await axiosInstance.post(
            `api/tournament-additional-informations`,
            {
                data: {
                    ...tournamentAdditionalInformations,
                },
            }
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const addTournamentAccommodation = async (
    tournamentAccommodationData
) => {
    try {
        const { data } = await axiosInstance.post(
            `api/tournament-accommodations`,
            {
                data: {
                    ...tournamentAccommodationData,
                },
            }
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const getTournamentsAdditionalInformations = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-additional-informations?filters[tournament_uuid][$eq]=${tournamentUuid}&sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data[0]
    } catch (error) {
        throw new Error(error)
    }
}

export const getTournamentAccommodation = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-accommodations?populate=*&filters[tournament_uuid][$eq]=${tournamentUuid}&sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data[0]
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTournamentAdditionalInformations = async (
    tournamentAdditionalInformations,
    additionalInformationsUuid
) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-additional-informations/${additionalInformationsUuid}`,
            {
                data: {
                    ...tournamentAdditionalInformations,
                },
            }
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTournamentAccommodation = async (
    tournamentAccommodationData,
    accomodationUuid
) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-accommodations/${accomodationUuid}`,
            {
                data: {
                    ...tournamentAccommodationData,
                },
            }
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const addPlayerOnTurnament = async (tournamentParticipantData) => {
    try {
        const { data } = await axiosInstance.post(
            `api/tournament-participants`,
            {
                data: {
                    ...tournamentParticipantData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const removePlayerFromTournament = async (participantUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/tournament-participants/${participantUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateTournamentPlayerParticipant = async (
    participantUuid,
    participantData
) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-participants/${participantUuid}`,
            {
                data: {
                    ...participantData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getMatchParticipants = async (matchData) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-participants?filters[$or][0][uuid][$eq]=${matchData?.side_1_uuid}&filters[$or][1][uuid][$eq]=${matchData?.side_2_uuid}&pagination[page]=1&pagination[pageSize]=2`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getSingleParticipant = async (participantUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-participants/${participantUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getNumberOfPlayersByStatus = async (
    tournament_uuid,
    is_double,
    gender,
    status
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-participants?filters[$and][0][tournament_uuid]=${tournament_uuid}&filters[$and][1][player_2_uuid]${
                is_double ? '[$notNull]=true' : '[$null]=true'
            }&filters[$and][2][gender][gender_name]=${gender}&filters[$and][3][player_status][status_name]=${status}&fields[0]=id&pagination[page]=1&pagination[pageSize]=1`
        )

        return data?.meta?.pagination?.total || 0
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersForTournament = async (
    fields,
    searchParams,
    filterParams,
    page = 1,
    allEntries = false,
    rowsPerPage = false
) => {
    let query = fields ? handleFieldsQuery(fields) : ''

    let filterString = ''

    let counter = 0

    if (filterParams?.club_uuid) {
        let newString = `filters[$and][${counter}][club_uuid][$eq]=${filterParams?.club_uuid}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.tournament_uuid) {
        let newString = `filters[$and][${counter}][tournament_uuid][$eq]=${filterParams?.tournament_uuid}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.is_double === false) {
        let newString = `filters[$and][${counter}][player_2_uuid][$null]=true&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.is_double === true) {
        let newString = `filters[$and][${counter}][player_2_uuid][$notNull]=true&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.gender) {
        let newString = `filters[$and][${counter}][gender][gender_name][$eq]=${filterParams?.gender}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.selected_side_1) {
        let newString = `filters[$and][${counter}][uuid][$ne]=${filterParams?.selected_side_1}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.selected_side_2) {
        let newString = `filters[$and][${counter}][uuid][$ne]=${filterParams?.selected_side_2}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.subtournament_name) {
        let newString = `filters[$and][${counter}][subtournament_name][tournament_subcategory_name][$eq]=${filterParams?.subtournament_name}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (
        filterParams?.player_status &&
        filterParams?.player_status?.length > 0
    ) {
        let newString = ''
        filterParams?.player_status?.map((status, index) => {
            newString += `filters[$and][${counter}][$or][${index}][player_status][status_name]=${status}&`
        })

        filterString = filterString.concat(newString)
        counter++
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/tournament-participants?${
                filterParams ? filterString : ''
            }populate=player_status&populate=subtournament_name${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=players_tournament_seed`
        )

        let participants = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { participants, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const addRefereeOnTournament = async (tournamentRefereesData) => {
    try {
        const { data } = await axiosInstance.post(`api/tournament-referees`, {
            data: {
                ...tournamentRefereesData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateRefereeOnTournament = async (
    tournamentRefereeId,
    tournamentRefereesData
) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-referees/${tournamentRefereeId}`,
            {
                data: {
                    ...tournamentRefereesData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentsReferees = async (
    tournamentUuid,
    filterParams = false
) => {
    let filterString = ''
    if (filterParams?.admin_referee) {
        let newString = `&filters[referee_type][referee_type_name][$eq]=Vrhovni sudija`
        filterString = filterString.concat(newString)
    }
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-referees?populate=*&filters[tournament_uuid][$eq]=${tournamentUuid}${
                filterParams ? filterString : ''
            }&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        throw new Error(error)
    }
}

export const addClubOnTurnament = async (tournamentClubParticipantData) => {
    try {
        const { data } = await axiosInstance.post(
            `api/tournament-clubs-participants`,
            {
                data: {
                    ...tournamentClubParticipantData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getClubMatchParticipants = async (uuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-clubs-participants/${uuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getClubTournamentParticipations = async (
    clubUuid,
    page,
    rowsPerPage = false
) => {
    if (page) {
        page = page
    } else {
        page = 1
    }
    const pageSize = rowsPerPage ? rowsPerPage : RowsPerPageOptions[0]
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-clubs-participants?filters[club_uuid]=${clubUuid}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=createdAt:desc`
        )
        let participants = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { participants, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getClubsForTournament = async (
    fields,
    searchParams,
    filterParams,
    page = 1,
    rowsPerPage,
    allEntries = false
) => {
    let filterString = ''

    let counter = 0

    if (filterParams?.tournament_uuid) {
        let newString = `filters[$and][${counter}][tournament_uuid][$eq]=${filterParams?.tournament_uuid}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.selected_side_1) {
        let newString = `filters[$and][${counter}][uuid][$ne]=${filterParams?.selected_side_1}&`
        filterString = filterString.concat(newString)
        counter++
    }

    if (filterParams?.selected_side_2) {
        let newString = `filters[$and][${counter}][uuid][$ne]=${filterParams?.selected_side_2}&`
        filterString = filterString.concat(newString)
        counter++
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/tournament-clubs-participants?${
                filterParams ? filterString : ''
            }pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=club_tournament_seed&sort[1]=createdAt:desc`
        )

        let participants = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { participants, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const removeClubFromTournament = async (participantUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/tournament-clubs-participants/${participantUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const updateTournamentClubParticipant = async (UUID, newData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-clubs-participants/${UUID}`,
            {
                data: {
                    ...newData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const createTournamentMatch = async (matchData) => {
    try {
        const { data } = await axiosInstance.post(`api/matches`, {
            data: {
                ...matchData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const confirmInvitationEmail = async (invitation_code) => {
    try {
        let { data } = await axiosInstance.get(
            `/api/email-invitation-confirm?invitation_code=${invitation_code}`
        )

        return data
    } catch (error) {
        throw { ...error }
    }
}

export const getSingleMatch = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/matches?filters[$and][0][round_number]=${
                matchDetails.round_number
            }&filters[$and][1][pair_number]=${
                matchDetails.pair_number
            }&filters[$and][2][tournament_uuid]=${
                matchDetails.tournament_uuid
            }&filters[$and][3][is_double]=${
                matchDetails.is_double
            }&filters[$and][4][gender][gender_name][$eq]=${
                matchDetails.gender.gender_name
            }&filters[$and][5][draw_type][draw_type_name][$eq]=${
                matchDetails.draw_type.draw_type_name
            }${
                matchDetails?.qualifying_section_number
                    ? `&filters[$and][6][qualifying_section_number][$eq]=${matchDetails?.qualifying_section_number}`
                    : ''
            }&populate[0]=side_1_result&populate[1]=side_2_result&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data[0]
    } catch (error) {
        throw { ...error }
    }
}

export const getGroupMatches = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/matches?filters[$and][0][tournament_uuid]=${
                matchDetails?.tournament_uuid
            }&filters[$and][1][gender][gender_name]=${
                matchDetails?.gender?.gender_name
            }&filters[$and][2][draw_type][draw_type_name]=${
                matchDetails.draw_type.draw_type_name
            }&filters[$and][3][round_number]=${
                matchDetails?.round_number
            }&filters[$and][4][is_double]=${matchDetails?.is_double}${
                matchDetails?.subtournament_name
                    ? `&filters[$and][5][subtournament_name][tournament_subcategory_name][$eq]=${matchDetails?.subtournament_name}`
                    : ''
            }&populate[0]=side_1_result&populate[1]=side_2_result`
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const getListOfTournamentsByRefereeUuid = async (
    refereeUuid,
    page = 1
) => {
    let query = `referee_uuid=${refereeUuid}`

    try {
        const { data } = await axiosInstance.get(
            `api/tournament-referees?${query}&pagination[page]=${page}&pagination[pageSize]=10&sort=createdAt:desc`
        )

        let tournaments = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { tournaments, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const autoGenerateDraw = async (tournamentUuid, gender) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/generate-draw?tournament_uuid=${tournamentUuid}&gender=${gender}`
        )
        return data.message
    } catch (error) {
        throw { ...error }
    }
}

export const autoGenerateTeamDraw = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/generate-team-draw?tournament_uuid=${tournamentUuid}`
        )
        return data.message
    } catch (error) {
        throw { ...error }
    }
}

export const autoGenerateGrandPrixDraw = async (
    tournamentUuid,
    gender,
    roundNumber,
    groupName,
    isDouble = false
) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/generate-grand-prix-draw?tournament_uuid=${tournamentUuid}&gender=${gender}&round_number=${roundNumber}&group_name=${groupName}&is_double=${isDouble}`
        )
        return data.message
    } catch (error) {
        throw { ...error }
    }
}

export const updateMatchResult = async (matchUuid, resultData) => {
    try {
        const { data } = await axiosInstance.put(`/api/matches/${matchUuid}`, {
            data: {
                ...resultData,
            },
        })
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const getSingleMatchByUuid = async (matchUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/matches/${matchUuid}?populate=gender`
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const getAllDisciplinaryPenalties = async () => {
    const penaltyNames = [
        'Trajna zabrana nastupa na takmičenjima u zemlji i inostranstvu',
        'Kazna zabrane nastupa na takmičenjima u zemlji i inostranstvu (od 6 meseci do 2 godine)',
        'Zabrana nastupa na takmičenjima u zemlji i inostranstvu (od 6 meseci do 2 godine)',
    ]
    const filterQuery = createOrFiltersQueryBySameProperty({
        arrayOfValues: penaltyNames,
        property: 'penalty_name',
    })
    try {
        const { data } = await axiosInstance.get(
            `api/players-penalties?penalty_type=Disciplinary&${filterQuery}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayerDisciplinaryPenalties = async (playerUuid) => {
    const penaltyNames = [
        'Trajna zabrana nastupa na takmičenjima u zemlji i inostranstvu',
        'Kazna zabrane nastupa na takmičenjima u zemlji i inostranstvu (od 6 meseci do 2 godine)',
        'Zabrana nastupa na takmičenjima u zemlji i inostranstvu (od 6 meseci do 2 godine)',
    ]

    const filterQuery = createOrFiltersQueryBySameProperty({
        arrayOfValues: penaltyNames,
        property: 'penalty_name',
    })

    try {
        const { data } = await axiosInstance.get(
            `api/players-penalties?filters[$and][0][player_uuid][$eq]=${playerUuid}&filters[$and][1][penalty_type][$eq]=Disciplinary&filters[$and][2][$or][0][end_date][$null]=true&filters[$and][2][$or][1][end_date][$gte]=${new Date().toLocaleDateString(
                'en-CA'
            )}&${filterQuery}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersLastMatchOnTournament = async (
    tournamentUuid,
    participantUuid,
    drawType
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][$or][0][side_1_uuid]=${participantUuid}&filters[$and][0][$or][1][side_2_uuid]=${participantUuid}&filters[$and][1][tournament_uuid]=${tournamentUuid}&filters[$and][2][draw_type][draw_type_name]=${drawType}&pagination[page]=1&pagination[pageSize]=2&sort=round_number:desc`
        )

        if (data?.data?.length === 1) {
            return {
                lastMatch: data?.data[0],
                prevMatch: null,
            }
        } else if (data?.data?.length === 2) {
            return {
                lastMatch: data?.data[0],
                prevMatch: data?.data[1],
            }
        } else {
            return {
                lastMatch: null,
                prevMatch: null,
            }
        }
    } catch (error) {
        console.error(error)
    }
}

export const didPlayedInRound = async (
    tournamentUuid,
    participantUuid,
    roundNumber,
    drawType
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[tournament_uuid][$eq]=${tournamentUuid}&filters[draw_type][draw_type_name][$eq]=${drawType}&filters[round_number][$eq]=${roundNumber}&filters[$or][0][side_1_uuid]=${participantUuid}&filters[$or][1][side_2_uuid]=${participantUuid}&pagination[page]=1&pagination[pageSize]=1&fields[0]=id`
        )

        return data.data?.length > 0 ? true : false
    } catch (error) {
        console.error(error)
    }
}

export const didClubPlayedInRound = async (
    tournamentUuid,
    participantUuid,
    roundNumber,
    drawType
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[tournament_uuid][$eq]=${tournamentUuid}&filters[draw_type][draw_type_name][$eq]=${drawType}&filters[round_number][$eq]=${roundNumber}&filters[$or][0][side_1_uuid]=${participantUuid}&filters[$or][1][side_2_uuid]=${participantUuid}&pagination[page]=1&pagination[pageSize]=1&fields[0]=id`
        )

        return data.data?.length > 0 ? true : false
    } catch (error) {
        console.error(error)
    }
}

export const isWinnerFromPrevRound = async (
    tournamentUuid,
    participantUuid,
    roundNumber,
    drawType,
    pairOne,
    pairTwo
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/matches?filters[$and][0][loser_participant_uuid][$notNull]=true&filters[$and][1][loser_participant_uuid][$ne]=${participantUuid}&filters[$and][2][tournament_uuid][$eq]=${tournamentUuid}&filters[$and][3][draw_type][draw_type_name][$eq]=${drawType}&filters[$and][4][round_number][$eq]=${roundNumber}&filters[$and][5][$or][0][side_1_uuid]=${participantUuid}&filters[$and][5][$or][1][side_2_uuid]=${participantUuid}&filters[$and][6][$or][0][pair_number]=${pairOne}&filters[$and][6][$or][1][pair_number]=${pairTwo}&pagination[page]=1&pagination[pageSize]=1&fields[0]=id`
        )

        return data.data?.length > 0 ? true : false
    } catch (error) {
        console.error(error)
    }
}

export const isClubWinnerFromPrevRound = async (
    tournamentUuid,
    participantUuid,
    roundNumber,
    drawType,
    pairOne,
    pairTwo
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/club-matches?filters[$and][0][loser_participant_uuid][$notNull]=true&filters[$and][1][loser_participant_uuid][$ne]=${participantUuid}&filters[$and][2][tournament_uuid][$eq]=${tournamentUuid}&filters[$and][3][draw_type][draw_type_name][$eq]=${drawType}&filters[$and][4][round_number][$eq]=${roundNumber}&filters[$and][5][$or][0][side_1_uuid]=${participantUuid}&filters[$and][5][$or][1][side_2_uuid]=${participantUuid}&filters[$and][6][$or][0][pair_number]=${pairOne}&filters[$and][6][$or][1][pair_number]=${pairTwo}&pagination[page]=1&pagination[pageSize]=1&fields[0]=id`
        )

        return data.data?.length > 0 ? true : false
    } catch (error) {
        console.error(error)
    }
}

export const addParticipantToGroup = async (groupData) => {
    try {
        const { data } = await axiosInstance.post(`api/participants-groups`, {
            data: {
                ...groupData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getParticipantsOfGroup = async (groupDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/participants-groups?filters[$and][0][tournament_uuid]=${groupDetails?.tournament_uuid}&filters[$and][1][group_number]=${groupDetails?.group_number}&filters[$and][2][gender][gender_name]=${groupDetails?.gender?.gender_name}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getClubParticipantsOfGroup = async (groupDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/participants-groups?filters[$and][0][tournament_uuid]=${groupDetails?.tournament_uuid}&filters[$and][1][group_number]=${groupDetails?.group_number}&sort=createdAt:desc`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const deleteGroupParticipant = async (groupParticipantUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/participants-groups/${groupParticipantUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const addPlayerDocuments = async (playerUuid, documents) => {
    try {
        if (documents) {
            let bodyData = {
                player_uuid: playerUuid,
                player_documents: [],
            }
            let formData = new FormData()
            if (documents?.length > 0) {
                documents?.forEach((document, index) => {
                    formData.append(
                        `files.player_documents[${index}].file`,
                        document,
                        document.name
                    )
                    bodyData.player_documents.push({
                        caption: document.name,
                        date: new Date().toISOString().split('T')[0],
                    })
                })
            }

            formData.append('data', JSON.stringify(bodyData))

            const { data } = await axiosInstance.post(
                `api/players-documents`,
                formData
            )

            return data?.data
        } else {
            throw new Error('No documents attached.')
        }
    } catch (error) {
        throw { ...error }
    }
}

export const isParticipantAlreadyInGroup = async (
    tournamentUuid,
    participantUuid
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/participants-groups?filters[$and][0][tournament_uuid]=${tournamentUuid}&filters[$and][1][participant_uuid]=${participantUuid}`
        )

        return data.data?.length > 0 ? true : false
    } catch (error) {
        console.error(error)
    }
}

export const updateParticipantsGroup = async (UUID, dataForUpdate) => {
    try {
        const { data } = await axiosInstance.put(
            `api/participants-groups/${UUID}`,
            {
                data: {
                    ...dataForUpdate,
                },
            }
        )

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayerDocuments = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/players-documents?populate[player_documents][populate][0]=file&filters[$and][0][player_uuid]=${playerUuid}`
        )

        return data.data[0]
    } catch (error) {
        throw { ...error }
    }
}

export const updatePlayerDocuments = async (
    documentsEntryUuid,
    oldDocuments,
    newDocuments
) => {
    try {
        let previousLength = oldDocuments?.length
        if (newDocuments) {
            let bodyData = {
                player_documents: [],
            }
            oldDocuments?.map((oldDoc) => {
                bodyData.player_documents.push({ id: oldDoc?.id })
            })
            let formData = new FormData()
            if (newDocuments?.length > 0) {
                newDocuments?.forEach((document, index) => {
                    formData.append(
                        `files.player_documents[${
                            previousLength + index
                        }].file`,
                        document,
                        document.name
                    )
                    bodyData.player_documents.push({
                        caption: document.name,
                        date: new Date().toISOString().split('T')[0],
                    })
                })
            }

            formData.append('data', JSON.stringify(bodyData))

            const { data } = await axiosInstance.put(
                `api/players-documents/${documentsEntryUuid}`,
                formData
            )

            return data?.data
        } else {
            throw new Error('No documents attached.')
        }
    } catch (error) {
        throw { ...error }
    }
}

export const createTournamentWinner = async (winnerData) => {
    try {
        const { data } = await axiosInstance.post(`api/tournament-winners`, {
            data: {
                ...winnerData,
            },
        })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const determinateTournamentWinner = async (
    tournamentUuid,
    gender,
    groupName,
    isDouble = false
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/determinate-winner?tournament_uuid=${tournamentUuid}&gender=${gender}&group_name=${groupName}&is_double=${isDouble}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentWinner = async (winnerDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-winners?filters[$and][0][tournament_uuid]=${winnerDetails?.tournament_uuid}&filters[$and][1][gender][gender_name]=${winnerDetails?.gender}&filters[$and][2][is_double]=${winnerDetails?.is_double}&sort=createdAt:desc`
        )
        return data.data[0]
    } catch (error) {
        console.error(error)
    }
}

export const updateTournamentWinner = async (winnerUuid, winnerData) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-winners/${winnerUuid}`,
            {
                data: {
                    ...winnerData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentWiners = async (query) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-winners?${query}`
        )
        let participants = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { participants, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getMatchesFromTournament = async (
    fields,
    searchParams,
    filterParams,
    page = 1
) => {
    let filterString = ''

    let counter = 0

    if (filterParams?.tournament_uuid) {
        let newString = `filters[$and][${counter}][tournament_uuid][$eq]=${filterParams?.tournament_uuid}&`
        filterString = filterString.concat(newString)
        counter += 1
    }

    if (filterParams?.not_finished) {
        let newString = `filters[$and][${counter}][loser_participant_uuid][$null]=true&`
        filterString = filterString.concat(newString)
        counter += 1
    }

    if (filterParams?.alreadySelectedMatches) {
        filterParams?.alreadySelectedMatches?.map((entry, index) => {
            let newString = `filters[$and][${counter}][uuid][$notIn][${index}]=${entry}&`
            filterString = filterString.concat(newString)
        })
        counter += 1
    }
    let newString = `filters[$and][${counter}][side_1_uuid][$notNull]=true&filters[$and][${
        counter + 1
    }][side_2_uuid][$notNull]=true`
    filterString = filterString.concat(newString)

    try {
        const { data } = await axiosInstance.get(
            `api/matches?${
                filterParams ? filterString : ''
            }&pagination[page]=${page}&pagination[pageSize]=10&sort[0]=pair_number:asc&sort[1]=createdAt:desc`
        )

        let matches = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { matches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getPotentialMatchesFromTournament = async (
    fields,
    searchParams,
    filterParams,
    page = 1
) => {
    let filterString = ''

    let counter = 0

    if (filterParams?.tournament_uuid) {
        let newString = `filters[$and][${counter}][tournament_uuid][$eq]=${filterParams?.tournament_uuid}&`
        filterString = filterString.concat(newString)
        counter += 1
    }

    if (filterParams?.not_finished) {
        let newString = `filters[$and][${counter}][loser_participant_uuid][$null]=true&`
        filterString = filterString.concat(newString)
        counter += 1
    }

    if (filterParams?.gender) {
        let newString = `filters[$and][${counter}][gender][gender_name][$eq]=${filterParams?.gender}&`
        filterString = filterString.concat(newString)
        counter += 1
    }

    if (filterParams?.alreadySelectedMatches) {
        filterParams?.alreadySelectedMatches?.map((entry, index) => {
            let newString = `filters[$and][${counter}][uuid][$notIn][${index}]=${entry}&`
            filterString = filterString.concat(newString)
        })
        counter += 1
    }

    let newString = `filters[$and][${counter}][$or][0][side_1_uuid][$notNull]=true&filters[$and][${counter}][$or][1][side_2_uuid][$notNull]=true`
    filterString = filterString.concat(newString)

    try {
        const { data } = await axiosInstance.get(
            `api/matches?${
                filterParams ? filterString : ''
            }&pagination[page]=${page}&pagination[pageSize]=10&sort[0]=pair_number:asc&sort[1]=createdAt:desc`
        )

        let matches = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { matches, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getSchedules = async (fieldUuid, scheduleDate) => {
    try {
        const { data } = await axiosInstance.get(
            `api/schedules?filters[$and][0][field_uuid][$eq]=${fieldUuid}&filters[$and][1][match_date][$eq]=${scheduleDate}&sort=createdAt`
        )

        return data?.data
    } catch (error) {
        console.error(error)
    }
}

export const createScheduleDate = async (dateData) => {
    try {
        const { data } = await axiosInstance.post(
            `api/tournament-schedule-dates`,
            {
                data: {
                    ...dateData,
                },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const deleteScheduleDate = async (dateUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/tournament-schedule-dates/${dateUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
        throw new Error('Error deleting field')
    }
}

export const getScheduleDates = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-schedule-dates?filters[tournament_uuid]=${tournamentUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const checkIsPlayerAlreadyCheckedInForTournament = async (
    playerUuid,
    tournamentUuid,
    page = 1
) => {
    let query = `filters[$or][0][player_1_uuid][$eq]=${playerUuid}&filters[$or][1][player_2_uuid][$eq]=${playerUuid}&filters[$and][0][tournament_uuid][$eq]=${tournamentUuid}`

    try {
        const { data } = await axiosInstance.get(
            `api/tournament-participants?${query}&pagination[page]=${page}&pagination[pageSize]=1&sort=createdAt:desc`
        )

        let tournaments = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { tournaments, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentReport = async (tournamentUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-reports?filters[$and][0][tournament_uuid]=${tournamentUuid}`
        )

        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const isRefereeTournamentAdmin = async (refereeUuid, tournamentUuid) => {
    try {
        let { data } = await axiosInstance.get(
            `api/tournament-referees?filters[$and][0][tournament_uuid][$eq]=${tournamentUuid}&filters[$and][0][referee_uuid][$eq]=${refereeUuid}&filters[$and][0][referee_type][referee_type_name][$eq]=${'Vrhovni sudija'}`
        )

        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const removeRefereeFromTournament = async (entryUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/tournament-referees/${entryUuid}`
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const updateTournamentReport = async (
    report_data,
    tournament_report_uuid
) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-reports/${tournament_report_uuid}`,
            {
                data: {
                    report_data,
                },
            }
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const verifyTournamentReport = async (tournament_report_uuid) => {
    try {
        const { data } = await axiosInstance.put(
            `api/tournament-reports/${tournament_report_uuid}`,
            {
                data: {
                    is_verified: true,
                },
            }
        )
        return data.data
    } catch (error) {
        throw { ...error }
    }
}

export const getStatisticsData = async (filterParams) => {
    try {
        const { data } = await axiosInstance.get(
            `api/statistics?period_from=${
                filterParams?.period_from || ''
            }&period_to=${filterParams?.period_to || ''}`
        )
        return data
    } catch (error) {
        throw { ...error }
    }
}

export const addClubPhotosToGallery = async (
    clubUuid,
    photos,
    oldPhotos = []
) => {
    try {
        if (photos) {
            let bodyData = {
                gallery: [],
            }

            let previousLength = oldPhotos?.length

            // If club already have some pictures in their gallery
            if (previousLength > 0) {
                oldPhotos?.map((oldPhoto) => {
                    bodyData.gallery.push({ id: oldPhoto?.id })
                })
            }

            // Adding new images
            let formData = new FormData()
            if (photos?.length > 0) {
                photos?.forEach((photo, index) => {
                    formData.append(
                        `files.gallery[${previousLength + index}].photo`,
                        photo,
                        photo.name
                    )
                    bodyData.gallery.push({
                        caption: photo.name,
                        date: new Date().toISOString().split('T')[0],
                    })
                })
            }

            formData.append('data', JSON.stringify(bodyData))

            // With populate - we are getting gallery in the response and we can check it on frontend to close the modal or handle errors
            const { data } = await axiosInstance.put(
                `api/clubs/${clubUuid}?populate[gallery][populate][photo]=true`,
                formData
            )

            return data?.data
        } else {
            throw new Error('No Photos Attached')
        }
    } catch (error) {
        throw { ...error }
    }
}

export const removeClubPhotoFromGallery = async (
    clubUuid,
    oldPhotos,
    deletedPhoto
) => {
    try {
        if (!deletedPhoto) {
            throw new Error('No Deleted Photo')
        }
        oldPhotos = oldPhotos?.filter((item) => item.id !== deletedPhoto?.id)
        let bodyData = {
            gallery: [],
        }

        if (oldPhotos?.length > 0) {
            oldPhotos?.map((oldPhoto) => {
                bodyData.gallery.push({ id: oldPhoto?.id })
            })
        }
        let formData = new FormData()
        formData.append('data', JSON.stringify(bodyData))

        const { data } = await axiosInstance.put(
            `api/clubs/${clubUuid}?populate[gallery][populate][photo]=true`,
            formData
        )

        await axiosInstance.delete(
            `/api/upload/files/${deletedPhoto?.photo?.data?.id}`
        )

        return data?.data
    } catch (error) {
        console.error(error)
        throw { ...error }
    }
}

export const getCodebook = async (
    codebookUid,
    setCodebook,
    subCodebook = false
) => {
    try {
        let { data } = await axiosInstance.get(
            `api/content-type-builder/components/${codebookUid}?fields[0]=schema`
        )
        if (codebookUid.includes('-')) {
            codebookUid = codebookUid.replace(/-/g, '_')
        }

        if (containsWords(codebookUid, ['coach', 'license'])) {
            codebookUid = removeWordAndJoin(codebookUid, 'coach')
        }

        if (containsWords(codebookUid, ['referee', 'license'])) {
            codebookUid = removeWordAndJoin(codebookUid, 'referee')
        }

        if (containsWords(codebookUid, ['referee', 'badge'])) {
            codebookUid = removeWordAndJoin(codebookUid, 'referee')
        }

        const attributeName =
            codebookUid.split('.')[1] === 'tournament_category' && subCodebook
                ? 'tournament_subcategory'
                : false

        let values =
            data?.data?.schema?.attributes?.[
                `${attributeName ? attributeName : codebookUid.split('.')[1]}${
                    codebookUid.split('.')[1] === 'players_competition' &&
                    subCodebook
                        ? '_subcategory'
                        : ''
                }_name`
            ]?.enum
        return setCodebook ? setCodebook(values) : values
    } catch (error) {
        throw { ...error }
    }
}

export const getPlayersRank = async (
    playerUuid,
    playersCompetitionName,
    playersCompetitionSubcategoryName
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/player-ratings?filters[$and][0][region][region_name][$null]=true&filters[$and][1][player_uuid]=${playerUuid}&filters[$and][2][is_current_year]=false&filters[$and][3][players_competition][players_competition_name]=${playersCompetitionName}${
                playersCompetitionSubcategoryName
                    ? `&filters[$and][4][players_competition][players_competition_subcategory_name]=${playersCompetitionSubcategoryName}`
                    : ''
            }&pagination[page]=1&pagination[pageSize]=1&sort=createdAt:desc`
        )

        if (data.data.length > 0) {
            return data.data[0]
        } else {
            return null
        }
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersRankingLists = async (playerUuid) => {
    try {
        const { data } = await axiosInstance.get(
            `api/player-ratings?filters[$and][0][player_uuid]=${playerUuid}&filters[$and][1][is_current_year]=false`
        )

        return data?.data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersPrevMatch = async (matchDetails) => {
    try {
        const { data } = await axiosInstance.get(
            `/api/matches?filters[$and][0][round_number]=${
                matchDetails.round_number
            }&filters[$and][1][tournament_uuid]=${
                matchDetails.tournament_uuid
            }&filters[$and][2][is_double]=${
                matchDetails.is_double
            }&filters[$and][3][gender][gender_name][$eq]=${
                matchDetails.gender.gender_name
            }&filters[$and][4][draw_type][draw_type_name][$eq]=${
                matchDetails.draw_type.draw_type_name
            }${
                matchDetails?.qualifying_section_number
                    ? `&filters[$and][5][qualifying_section_number][$eq]=${matchDetails?.qualifying_section_number}`
                    : ''
            }&filters[$and][6][$or][0][side_1_uuid]=${
                matchDetails?.participant_uuid
            }&filters[$and][6][$or][1][side_2_uuid]=${
                matchDetails?.participant_uuid
            }&populate[0]=side_1_result&populate[1]=side_2_result&pagination[page]=1&pagination[pageSize]=1`
        )
        return data.data[0]
    } catch (error) {
        throw { ...error }
    }
}

export const getClubAssemblyRankingSettings = async () => {
    try {
        const { data } = await axiosInstance.get(
            '/api/club-assembly-setting?populate=*'
        )

        return data?.data
    } catch (error) {
        throw { ...error }
    }
}

export const updateClubAssemblyRankingPoints = async (uuid, points) => {
    try {
        const { data } = await axiosInstance.put(
            `api/club-assembly-rankings/${uuid}`,
            {
                data: { ...points },
            }
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const filterRankingList = async (
    filterParams,
    page,
    allEntries = false,
    rowsPerPage = false
) => {
    if (page) {
        page = page
    } else {
        page = 1
    }

    let filterString = ''
    let counter = 0

    if (filterParams?.region) {
        filterParams?.region?.map(({ value }, index) => {
            let newString = `region=${value}&`
            filterString = filterString.concat(newString)
        })
        counter++
    }

    if (filterParams?.list_to_look) {
        let newString = `list_to_look=${filterParams?.list_to_look}&`
        filterString = filterString.concat(newString)
        counter++
    } else {
        let newString = `list_to_look=by-players&`
        filterString = filterString.concat(newString)
        counter++
    }

    const pageSize = allEntries
        ? '1000000'
        : rowsPerPage
        ? rowsPerPage
        : RowsPerPageOptions[0]

    try {
        const { data } = await axiosInstance.get(
            `api/filter-ranking-list?${
                filterParams ? filterString : ''
            }populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=${
                filterParams?.list_to_look === 'assembly'
                    ? 'total_votes'
                    : 'total_points'
            }:desc`
        )
        let clubs = data?.data
        let pgCount = data?.meta?.pagination?.pageCount
        let total = data?.meta?.pagination?.total
        return { clubs, pgCount, total }
    } catch (error) {
        console.error(error)
    }
}

export const getUserByEmail = async (email) => {
    try {
        const { data } = await axiosInstance.get(
            `api/users?filters[$and][0][email][$eq]=${email}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

export const calcualteParticipantsPointsOnTournament = async (
    tournamentUUID
) => {
    try {
        const { data } = await axiosInstance.get(
            `api/calculate-points-on-tournament/${tournamentUUID}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

export const getTournamentParticipantsNumber = async (
    tournamentUUID,
    gender,
    isDouble = false
) => {
    try {
        let query = `filters[$and][0][tournament_uuid]=${tournamentUUID}&filters[$and][1][gender][gender_name]=${gender}&`

        if (isDouble === false) {
            let newString = `filters[$and][2][player_2_uuid][$null]=true&fields[0]=id&pagination[page]=1&pagination[pageSize]=1`
            query += newString
        }

        if (isDouble === true) {
            let newString = `filters[$and][2][player_2_uuid][$notNull]=true&fields[0]=id&pagination[page]=1&pagination[pageSize]=1`
            query += newString
        }

        const { data } = await axiosInstance.get(
            `api/tournament-participants?${query}`
        )

        let total = data?.meta?.pagination?.total

        return { total }
    } catch (error) {
        console.error(error)
    }
}

export const getTeamTournamentParticipantsNumber = async (tournamentUUID) => {
    try {
        const { data } = await axiosInstance.get(
            `api/tournament-clubs-participants?fields[0]=id&filters[tournament_uuid]=${tournamentUUID}`
        )

        let total = data?.meta?.pagination?.total

        return { total }
    } catch (error) {
        console.error(error)
    }
}

export const removeClubFromPlayerHistory = async (clubEntryUuid) => {
    try {
        const { data } = await axiosInstance.delete(
            `api/players-clubs/${clubEntryUuid}`
        )
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const checkAvailableGroups = async (tournamentID) => {
    try {
        const { data } = await axiosInstance.get(
            `api/check-available-groups/${tournamentID}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

export const getPlayersTournaments = async ({ uuid, query = '' }) => {
    try {
        const { data } = await axiosInstance.get(
            `api/get-players-tournaments/${uuid}?${query}`
        )

        const response = data?.data
        const pgCount = data?.meta?.pagination?.pageCount
        const total = data?.meta?.pagination?.total
        return {
            response,
            pgCount,
            total,
        }
    } catch (error) {
        console.error(error)
    }
}

export const getRoundRobinDrawData = async ({ tournamentUuid, gender }) => {
    try {
        const { data } = await axiosInstance.get(
            `api/get-round-robin-draw/${tournamentUuid}?gender=${gender}`
        )
        return data
    } catch (error) {
        console.error(error)
    }
}
