import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CheckBox from '../../../../../components/Checkbox'
import NoDataForView from '../../../../../components/NoDataForView'
import TournamentsTable from '../../../../../components/TournamentsTable'
import TournamentsTableCard from '../../../../../components/TournamentsTableCard'
import { isArrayEmpty } from '../../../../../lib/helpers'
import { getCodebook, updatePlayer } from '../../../../../lib/api'
import { COMPETITIONS_10_S } from '../../../../../lib/constants'
import { useAppContext } from '../../../../../lib/contextLib'
import './styles.scss'
import Button from '../../../../../components/Button'

const TournamentsTab = ({
    customActiveTab,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    comingCompetitions,
    finishedCompetitions,
    setTournamentsTabSelectedSubTab,
    getAllFinishedCompetitions,
    getComingCompetitions,
    rowsPerPage,
    setRowsPerPage,
    competitor,
    setCompetitorData,
    setFilterParams,
}) => {
    const { setShowCustomToast, setIsLoading, user } = useAppContext()
    const [selectedTab, setSelectedTab] = useState(customActiveTab)
    const [playersCompetition, setPlayersCompetition] = useState([])

    const [oldAllowedCompetitions, setOldAllowedCompetitions] = useState({})
    const [allowedCompetitions, setAllowedCompetitions] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(() => {
                const resultData = result.reduce(
                    (accumulator, currentValue) => {
                        if (
                            ![
                                'Seniorke',
                                'I Liga Seniori',
                                '10',
                                '10s',
                                '45',
                            ].includes(currentValue)
                        ) {
                            return [...accumulator, currentValue]
                        }
                        return accumulator
                    },
                    []
                )

                return [...COMPETITIONS_10_S, ...resultData]
            })
        })
    }

    const handleUpdateCompetitor = async () => {
        try {
            setIsLoading(true)
            const updatedCompetitor = await updatePlayer(
                competitor?.attributes?.uuid,
                {
                    allowed_competitions: allowedCompetitions,
                }
            )

            if (!updatedCompetitor) return

            setOldAllowedCompetitions(allowedCompetitions)

            setCompetitorData((prev) => ({
                ...prev,
                attributes: {
                    ...prev.attributes,
                    allowed_competitions: allowedCompetitions,
                },
            }))

            setShowCustomToast({
                show: true,
                type: 'success',
                message: 'Uspešno sačuvano',
            })
        } catch (error) {
            console.error(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Greška prilikom ažuriranja',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleCompetitionSelect = (competition) => {
        setAllowedCompetitions((prev) => {
            const [mainCategory, subCategory] = competition.includes(' - ')
                ? competition.split(' - ').map((part) => part.trim())
                : [competition, null]

            const exists = prev.some(
                (c) =>
                    c.players_competition_name === mainCategory &&
                    (c.players_competition_subcategory_name
                        ? c.players_competition_subcategory_name === subCategory
                        : true)
            )

            if (exists) {
                return prev.filter(
                    (c) =>
                        !(
                            c.players_competition_name === mainCategory &&
                            (c.players_competition_subcategory_name
                                ? c.players_competition_subcategory_name ===
                                  subCategory
                                : true)
                        )
                )
            } else {
                return [
                    ...prev,
                    {
                        players_competition_name: mainCategory,
                        ...(subCategory && {
                            players_competition_subcategory_name: subCategory,
                        }),
                    },
                ]
            }
        })
    }

    useEffect(() => {
        handleCodebooks()
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
    }, [currentPage, selectedTab, rowsPerPage])

    useEffect(() => {
        setAllowedCompetitions(
            competitor?.attributes?.allowed_competitions?.map(
                ({ id, ...rest }) => rest
            )
        )
        setOldAllowedCompetitions(
            competitor?.attributes?.allowed_competitions?.map(
                ({ id, ...rest }) => rest
            )
        )
    }, [competitor])

    const isDifferent =
        JSON.stringify(allowedCompetitions) !==
        JSON.stringify(oldAllowedCompetitions)

    return (
        <Row className="tournaments-tab-wrapper">
            {user?.role?.type !== 'competitor' && (
                <Col xs={12}>
                    <h4>Specijalne dozvole za prijavu na turnire</h4>
                    <p
                        className="mt-1"
                        style={{
                            fontSize: '12px',
                            color: 'gray',
                        }}
                    >
                        Selektuj kategorije za koje takmičar ima pravo učešća
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {playersCompetition?.map((competition) => (
                            <div key={competition} className="p-2">
                                <CheckBox
                                    id={competition}
                                    checked={allowedCompetitions?.some(
                                        (competitionData) =>
                                            competitionData?.players_competition_name ===
                                                competition ||
                                            competition?.includes(
                                                competitionData?.players_competition_subcategory_name
                                            )
                                    )}
                                    onChange={() =>
                                        handleCompetitionSelect(competition)
                                    }
                                    label={competition}
                                />
                            </div>
                        ))}
                    </div>

                    <Button
                        className={'mt-2'}
                        onClick={handleUpdateCompetitor}
                        disabled={!isDifferent}
                    >
                        Sačuvaj
                    </Button>
                </Col>
            )}
            <Col className="p-0 tabular-navigation mt-3">
                <Tabs
                    id="uncontrolled-tab-example"
                    activeKey={selectedTab}
                    onSelect={(tab) => {
                        setSelectedTab(tab)
                        setTournamentsTabSelectedSubTab(tab)
                        setCurrentPage(1)
                        setFilterParams({
                            gender: '',
                            region: '',
                            competition: '',
                            category: '',
                            sub_category: '',
                            club_organizer_uuid: '',
                        })
                    }}
                >
                    <Tab eventKey="nadolazeca" title="Nadolazeća">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TournamentsTable
                                    data={comingCompetitions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Col>
                        </Row>
                        <Row className="d-block d-md-none">
                            <Col>
                                {!isArrayEmpty(comingCompetitions) ? (
                                    comingCompetitions?.map((result, index) => (
                                        <TournamentsTableCard
                                            key={index}
                                            result={result}
                                            isWhite={index % 2 !== 0}
                                        />
                                    ))
                                ) : (
                                    <NoDataForView
                                        title={`Nema nadolazećih takmičenja`}
                                        description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="zavrsena" title="Završena">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TournamentsTable
                                    data={finishedCompetitions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    finished
                                />
                            </Col>
                        </Row>
                        <Row className="d-block d-md-none mt-3">
                            <Col>
                                {!isArrayEmpty(finishedCompetitions) ? (
                                    finishedCompetitions?.map(
                                        (result, index) => (
                                            <TournamentsTableCard
                                                key={index}
                                                result={result}
                                                isWhite={index % 2 !== 0}
                                                className="d-md-none"
                                                finished={true}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema završenih takmičenja`}
                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    )
}

export default TournamentsTab
