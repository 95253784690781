import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NoDataForView from '../../../../components/NoDataForView'
import PaginationWrapper from '../../../../components/Pagination'
import {
    formatDate,
    formatImgUrl,
    handleGenders,
    isArrayEmpty,
} from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'

const FinishedTournaments = ({
    tournaments,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    profilePage = false,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return !isArrayEmpty(tournaments) ? (
        <>
            <Table className="d-none d-md-table">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Organizator</h5>
                        </th>
                        <th>
                            <h5>Početak</h5>
                        </th>
                        <th>
                            <h5>Pol</h5>
                        </th>
                        <th>
                            <h5>Konkurencija</h5>
                        </th>
                        <th>
                            <h5>Kategorija</h5>
                        </th>
                        <th>
                            <h5>Bodovi</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tournaments?.map((result, index) => (
                        <tr className="club" key={index}>
                            <td>
                                <Link
                                    to={`/takmicar/takmicenja/${result?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {result?.attributes?.name}
                                    </p>
                                </Link>
                            </td>
                            <td>
                                {result?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                result?.attributes
                                                    ?.club_organizer?.attributes
                                                    ?.logo?.data
                                                    ? formatImgUrl(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    result?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </td>
                            <td>
                                <p>
                                    {result?.attributes?.start_date
                                        ? formatDate(
                                              result?.attributes?.start_date
                                          )
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {result?.attributes?.genders
                                        ? handleGenders(
                                              result?.attributes?.genders
                                          )
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {result?.attributes?.players_competition
                                        ?.players_competition_name
                                        ? result?.attributes
                                              ?.players_competition
                                              ?.players_competition_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {result?.attributes?.tournament_category
                                        ?.tournament_category_name
                                        ? result?.attributes
                                              ?.tournament_category
                                              ?.tournament_category_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p>{result?.attributes?.ranking_points || 0}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
            <Col className="d-md-none">
                {tournaments?.map((result, index) => (
                    <Card
                        key={index}
                        className={`club-card competitor-tournaments-card ${
                            index % 2 !== 0
                                ? 'white-club-card'
                                : 'grey-club-card'
                        }`}
                    >
                        <Card.Body className="py-0">
                            <Row>
                                <Col>
                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Naziv</h4>
                                        </Col>
                                        <Col className="d-flex align-items-center">
                                            <Link
                                                to={`/takmicar/takmicenja/${result?.id}`}
                                            >
                                                <p className="link">
                                                    {result?.attributes?.name}
                                                </p>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Organizator</h4>
                                        </Col>
                                        {result?.attributes?.club_organizer
                                            ?.attributes?.name ? (
                                            <Col className="d-flex align-items-center">
                                                <div className="d-flex align-items-center align-items-center justify-content-start">
                                                    <img
                                                        src={
                                                            result?.attributes
                                                                ?.club_organizer
                                                                ?.attributes
                                                                ?.logo?.data
                                                                ? formatImgUrl(
                                                                      result
                                                                          ?.attributes
                                                                          ?.club_organizer
                                                                          ?.attributes
                                                                          ?.logo
                                                                          ?.data
                                                                          ?.attributes
                                                                          ?.url
                                                                  )
                                                                : generateAvatar(
                                                                      result
                                                                          ?.attributes
                                                                          ?.club_organizer
                                                                          ?.attributes
                                                                          ?.name
                                                                  )
                                                        }
                                                        alt={`${result?.attributes?.club_organizer_id?.data?.attributes?.name} logo`}
                                                        className="me-2 competitor-tournaments-club-logo"
                                                    />
                                                    <Link
                                                        to={`/takmicar/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                                    >
                                                        <p className="link">
                                                            {
                                                                result
                                                                    ?.attributes
                                                                    ?.club_organizer
                                                                    ?.attributes
                                                                    ?.name
                                                            }
                                                        </p>
                                                    </Link>
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col>
                                                <p>-</p>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Početak</h4>
                                        </Col>
                                        <Col>
                                            <p>
                                                {result?.attributes?.start_date
                                                    ? formatDate(
                                                          result?.attributes
                                                              ?.start_date
                                                      )
                                                    : '-'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Pol</h4>
                                        </Col>
                                        <Col>
                                            <p>
                                                {result?.attributes?.genders
                                                    ? handleGenders(
                                                          result?.attributes
                                                              ?.genders
                                                      )
                                                    : '-'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Konkurencija</h4>
                                        </Col>
                                        <Col>
                                            <p>
                                                {result?.attributes
                                                    ?.players_competition
                                                    ?.players_competition_name
                                                    ? result?.attributes
                                                          ?.players_competition
                                                          ?.players_competition_name
                                                    : '-'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="car-row align-items-center">
                                        <Col>
                                            <h4>Kategorija</h4>
                                        </Col>
                                        <Col>
                                            <p>
                                                {result?.attributes
                                                    ?.tournament_category
                                                    ?.tournament_category_name
                                                    ? result?.attributes
                                                          ?.tournament_category
                                                          ?.tournament_category_name
                                                    : '-'}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ))}
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    total={total}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    showOnMobile
                />
            </Col>
        </>
    ) : (
        <NoDataForView
            title="Nema završenih takmičenja"
            description={`Ovde je prikazana lista ${
                profilePage ? 'Vaših' : ''
            } završenih takmičenja.`}
            className="border-0"
        />
    )
}

export default FinishedTournaments
