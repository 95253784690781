import React from 'react'
import { Col, Table } from 'react-bootstrap'
import { isArrayEmpty } from '../../../../lib/helpers'

import NoDataForView from '../../../../components/NoDataForView'
import PaginationWrapper from '../../../../components/Pagination'
import TableRow from './TableRow'
import './styles.scss'

const UpcomingTournamets = ({
    tournaments,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    profilePage = false,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return !isArrayEmpty(tournaments) ? (
        <>
            <Table className="d-none d-md-table">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Organizator</h5>
                        </th>
                        <th>
                            <h5>Prijava do</h5>
                        </th>
                        <th>
                            <h5>Odjava do</h5>
                        </th>
                        <th>
                            <h5>Pol</h5>
                        </th>
                        <th>
                            <h5>Kategorija</h5>
                        </th>
                        <th>
                            <h5>Bodovi</h5>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tournaments?.map((result, index) => (
                        <TableRow result={result} index={index} key={index} />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
            <Col className="d-md-none">
                {tournaments?.map((result, index) => (
                    <TableRow
                        result={result}
                        index={index}
                        key={index}
                        mobile
                    />
                ))}
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    total={total}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    showOnMobile
                />
            </Col>
        </>
    ) : (
        <NoDataForView
            title="Nema nadolazećih takmičenja"
            description={`Ovde je prikazana lista ${
                profilePage ? 'Vaših' : ''
            } nadolazećih takmičenja.`}
            className="border-0"
        />
    )
}

export default UpcomingTournamets
