import React from 'react'
import ConfirmationModal from '../../../../../../components/ConfirmationModal'
import {
    verifyTournamentReport,
    calcualteParticipantsPointsOnTournament,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'

function VerifyTournamentModal({ show, onHide, refreshData }) {
    const { setShowCustomToast } = useAppContext()

    const handleVerifyTournamentReport = async () => {
        try {
            const verifiedReport = await verifyTournamentReport(
                show?.attributes?.uuid
            )

            if (verifiedReport) {
                await calcualteParticipantsPointsOnTournament(
                    show?.attributes?.tournament_uuid
                )
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Takmičenje uspešno verifikovano.',
                })
                refreshData()
                onHide()
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    return (
        <ConfirmationModal
            modalTitle="Potvrda verifikacije"
            modalText="Jeste li sigurni da želite verifikovati takmičenje?"
            show={show}
            onClick={() => handleVerifyTournamentReport()}
            onHide={onHide}
            secondButtonText="Verifikuj"
            dialogClassName="verify-tournament-report-modal"
        />
    )
}

export default VerifyTournamentModal
