import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import Add from '../../../assets/svg/Add'
import Settings from '../../../assets/svg/Settings'
import Button from '../../../components/Button'
import CompetitionCard from '../../../components/CompetitionsTableCards/CompetitionsCard'
import SearchInput from '../../../components/SearchInput'
import TableCompetitionsPagination from '../../../components/TableCompetitonsPagination'
import { useAppContext } from '../../../lib/contextLib'
import {
    isArrayEmpty,
    getComingCompetitions,
    getFinishedCompetitions,
    debounce,
} from '../../../lib/helpers'
import NoDataForView from '../../../components/NoDataForView'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import CompetitionFiltersCanvas from '../../../components/CompetitionFiltersCanvas'
import './styles.scss'
import PaginationWrapper from '../../../components/Pagination'

const searchInputID = `searchInput-admin-competitions`

const Competitions = () => {
    const { setIsLoading, setSgpDataForPDF } = useAppContext()

    const initialFilters = {
        gender: '',
        region: '',
        competition: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
    }

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [filterParams, setFilterParams] = useState(initialFilters)

    const getComingCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        try {
            setIsLoading(true)
            const { updatedTournaments, pgCount, totalCount, page } =
                await getComingCompetitions(
                    currentPage,
                    filterParams,
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setComingCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getFinishedCompetitionsWrapper = async (
        currentPage,
        filterParams,
        allEntries = false
    ) => {
        try {
            setIsLoading(true)

            let filtersData = filterParams
            if (selectedTab === 'priprema') {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'priprema',
                }
            }
            if (selectedTab === 'verifikovano') {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'verifikovano',
                }
            }
            if (
                selectedTab === 'ceka-verifikaciju' ||
                selectedTab === 'zavrsena'
            ) {
                filtersData = {
                    ...filterParams,
                    tournament_report_status: 'ceka-verifikaciju',
                }
            }

            const { updatedTournaments, pgCount, totalCount, page } =
                await getFinishedCompetitions(
                    currentPage,
                    filtersData,
                    rowsPerPage,
                    document.getElementById(searchInputID)?.value || '',
                    allEntries
                )

            if (allEntries) {
                return updatedTournaments
            }

            setFinishedCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitionsWrapper(1, null)
            : getFinishedCompetitionsWrapper(1, null)
    }

    const handleTabSelect = (tab) => {
        setFilterParams(initialFilters)
        document.getElementById(searchInputID).value = ''
        setCurrentPage(1)
        setSelectedTab(tab)
    }

    useEffect(() => {
        if (selectedTab === 'nadolazeca') {
            getComingCompetitionsWrapper(currentPage, filterParams)
        } else if (
            selectedTab === 'zavrsena' ||
            selectedTab === 'ceka-verifikaciju' ||
            selectedTab === 'priprema' ||
            selectedTab === 'verifikovano'
        ) {
            getFinishedCompetitionsWrapper(currentPage, filterParams)
        }

        setSgpDataForPDF({
            groupMatches: [],
            drawToShow: null,
            tournament: null,
        })
    }, [currentPage, selectedTab, rowsPerPage])

    useEffect(() => {
        localStorage.removeItem('competitionDrawToShow')
    }, [])

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitionsWrapper(1, filterParams)
            : await getFinishedCompetitionsWrapper(1, filterParams)
    }, 1000)

    return (
        <Row className="club-ranking-wrapper h-100">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Takmičenja
                                </h2>
                                <p className="d-block d-md-none">Takmičenja</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičenja
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Link to="/takmicenja/podesavanje-takmicenja">
                                <Button type="text">
                                    <Settings />
                                </Button>
                            </Link>

                            <Link to="kreiraj-takmicenje">
                                <Button type="text">
                                    <Add />
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    id={searchInputID}
                                    placeholder="Pronađi takmičenje po imenu"
                                    onChange={(e) =>
                                        updateDebounceText(e.target.value)
                                    }
                                />

                                <CompetitionFiltersCanvas
                                    filterParams={filterParams}
                                    setFilterParams={setFilterParams}
                                    getComingCompetitions={
                                        getComingCompetitionsWrapper
                                    }
                                    getFinishedCompetitions={
                                        getFinishedCompetitionsWrapper
                                    }
                                    selectedTab={selectedTab}
                                    handleDisableFiltering={
                                        handleDisableFiltering
                                    }
                                />

                                <Link
                                    to="/takmicenja/podesavanje-takmicenja"
                                    tabIndex="1"
                                    className="mx-2 d-none d-md-block"
                                >
                                    <Button type="secondary">
                                        Podešavanja
                                    </Button>
                                </Link>

                                <Link
                                    className="d-none d-md-block"
                                    to="kreiraj-takmicenje"
                                    tabIndex="1"
                                >
                                    <Button>Kreiraj takmičenje</Button>
                                </Link>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            id="uncontrolled-tab-example"
                            onSelect={(tab) => {
                                setCurrentPage(1)
                                document.getElementById(searchInputID).value =
                                    ''
                                handleTabSelect(tab)
                            }}
                        >
                            <Tab eventKey="nadolazeca" title="Nadolazeća">
                                <Row className="d-none d-md-block">
                                    <Col>
                                        <TableCompetitionsPagination
                                            data={comingCompetitions}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            className="d-none d-md-block"
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </Col>
                                </Row>

                                <Row className="d-md-none">
                                    <Col>
                                        {!isArrayEmpty(comingCompetitions) ? (
                                            <>
                                                {comingCompetitions?.map(
                                                    (result, index) => (
                                                        <CompetitionCard
                                                            key={index}
                                                            result={result}
                                                            isWhite={
                                                                index % 2 !== 0
                                                            }
                                                        />
                                                    )
                                                )}
                                                <PaginationWrapper
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                    showOnMobile
                                                />
                                            </>
                                        ) : (
                                            <NoDataForView
                                                title={`Nema nadolazećih takmičenja`}
                                                description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                                className="border-0"
                                                column
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="zavrsena" title="Završena">
                                <Row className="d-none d-md-block">
                                    <Col>
                                        <Tabs
                                            id="uncontrolled-tab-example"
                                            onSelect={(tab) => {
                                                setCurrentPage(1)
                                                document.getElementById(
                                                    searchInputID
                                                ).value = ''
                                                handleTabSelect(tab)
                                            }}
                                        >
                                            <Tab
                                                eventKey="ceka-verifikaciju"
                                                title="Čeka verifikaciju"
                                            >
                                                <TableCompetitionsPagination
                                                    data={finishedCompetitions}
                                                    finished={true}
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    className="d-none d-md-block"
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="priprema"
                                                title="Bez izeštaja"
                                            >
                                                <TableCompetitionsPagination
                                                    data={finishedCompetitions}
                                                    finished={true}
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    className="d-none d-md-block"
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="verifikovano"
                                                title="Verifikovana"
                                            >
                                                <TableCompetitionsPagination
                                                    data={finishedCompetitions}
                                                    finished={true}
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    className="d-none d-md-block"
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Row className="d-md-none">
                                    <Col>
                                        <Tabs
                                            id="uncontrolled-tab-example"
                                            onSelect={(tab) => {
                                                setCurrentPage(1)
                                                document.getElementById(
                                                    searchInputID
                                                ).value = ''
                                                handleTabSelect(tab)
                                            }}
                                        >
                                            <Tab
                                                eventKey="ceka-verifikaciju"
                                                title="Čeka verifikaciju"
                                            >
                                                {!isArrayEmpty(
                                                    finishedCompetitions
                                                ) ? (
                                                    <>
                                                        {finishedCompetitions?.map(
                                                            (result, index) => (
                                                                <CompetitionCard
                                                                    key={index}
                                                                    result={
                                                                        result
                                                                    }
                                                                    isWhite={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                    }
                                                                    finished={
                                                                        true
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                        <PaginationWrapper
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                            showOnMobile
                                                        />
                                                    </>
                                                ) : (
                                                    <NoDataForView
                                                        title={`Nema završenih takmičenja`}
                                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                                        className="border-0"
                                                        column
                                                    />
                                                )}
                                            </Tab>
                                            <Tab
                                                eventKey="priprema"
                                                title="Bez izeštaja"
                                            >
                                                {!isArrayEmpty(
                                                    finishedCompetitions
                                                ) ? (
                                                    <>
                                                        {finishedCompetitions?.map(
                                                            (result, index) => (
                                                                <CompetitionCard
                                                                    key={index}
                                                                    result={
                                                                        result
                                                                    }
                                                                    isWhite={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                    }
                                                                    finished={
                                                                        true
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                        <PaginationWrapper
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                            showOnMobile
                                                        />
                                                    </>
                                                ) : (
                                                    <NoDataForView
                                                        title={`Nema završenih takmičenja`}
                                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                                        className="border-0"
                                                        column
                                                    />
                                                )}
                                            </Tab>
                                            <Tab
                                                eventKey="verifikovano"
                                                title="Verifikovana"
                                            >
                                                {!isArrayEmpty(
                                                    finishedCompetitions
                                                ) ? (
                                                    <>
                                                        {finishedCompetitions?.map(
                                                            (result, index) => (
                                                                <CompetitionCard
                                                                    key={index}
                                                                    result={
                                                                        result
                                                                    }
                                                                    isWhite={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                    }
                                                                    finished={
                                                                        true
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                        <PaginationWrapper
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            setCurrentPage={
                                                                setCurrentPage
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            total={total}
                                                            rowsPerPage={
                                                                rowsPerPage
                                                            }
                                                            setRowsPerPage={
                                                                setRowsPerPage
                                                            }
                                                            showOnMobile
                                                        />
                                                    </>
                                                ) : (
                                                    <NoDataForView
                                                        title={`Nema završenih takmičenja`}
                                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                                        className="border-0"
                                                        column
                                                    />
                                                )}
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Competitions
