import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

import { generateAvatar } from '../../utils/generateAvatar'

import './styles.scss'
import PaginationWrapper from '../Pagination'

const TableCardCouches = ({
    className,
    data,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <>
            {data.map((coach) => (
                <Row
                    key={coach?.attributes?.uuid}
                    className={`couches-table-card-wrapper py-2 g-3 ${className}`}
                >
                    <Col xs={6}>
                        <h5>Trener</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0">
                        <img
                            src={
                                coach?.attributes?.person_info?.picture
                                    ? coach?.attributes?.person_info?.picture
                                    : generateAvatar(
                                          `${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`
                                      )
                            }
                            alt={`${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`}
                            className="me-2 img-couches"
                        />
                        <Link to={`/treneri/${coach?.attributes?.uuid}`}>
                            <p className="link">
                                {coach?.attributes?.person_info?.given_name}{' '}
                                {coach?.attributes?.person_info?.family_name}
                            </p>
                        </Link>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Nacionalna licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.national_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.national_license?.national_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />

                    <Col xs={6} className="m-0">
                        <h5>ITF licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.itf_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.itf_license?.itf_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>GPTCA licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.gptca_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.gptca_license?.gptca_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>USPTA licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.uspta_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.uspta_license?.uspta_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>LTA licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.lta_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.lta_license?.lta_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>PTR licenca</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {coach?.attributes?.licenses?.attributes
                                ?.ptr_license
                                ? coach?.attributes?.licenses?.attributes
                                      ?.ptr_license?.ptr_license_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={5} className="m-0 pb-3">
                        <Link
                            to={`/treneri/${coach?.attributes?.uuid}/izmeni-profil-trenera`}
                        >
                            <p className="link">Izmeni </p>
                        </Link>
                    </Col>
                </Row>
            ))}
            <div className="d-md-none">
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    total={total}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    showOnMobile
                />
            </div>
        </>
    )
}

export default TableCardCouches
