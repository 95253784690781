import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Modal, Row, Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Add from '../../../../assets/svg/Add'
import Button from '../../../../components/Button'
import CompetitorsRankingLists from '../../../../components/CompetitorsRankingLists'
import Dropdown from '../../../../components/Dropdown'
import RadioButton from '../../../../components/RadioButton'
import CompetitorsCategoryCard from '../../../../components/RankingTableCards/CompetitorsCategoryCard'
import TextField from '../../../../components/TextField'
import {
    createCompetitorsRankingList,
    getCompetitorsRankingLists,
    calculateRank,
    getRegionCompetitorsRankingLists,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'

import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import './styles.scss'
import PaginationWrapper from '../../../../components/Pagination'

const CompetitorsRanking = () => {
    const { user, setIsLoading } = useAppContext()
    const genderValues = ['Muški', 'Ženski']
    const playersCompetitionsList = [
        '10s',
        '12',
        '14',
        '16',
        '18',
        'Seniori',
        'I Liga Seniori',
        'Seniorke',
    ]

    const [formData, setFormData] = useState()
    const [openModal, setOpenModal] = useState()
    const [disableButton, setDisableButton] = useState(true)
    const [competitorsRankingLists, setCompetitorsRankingLists] = useState([])
    const [
        showRankingListConfirmationModal,
        setShowRankingListConfirmationModal,
    ] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])
    const [activeTab, setActiveTab] = useState('opste-rang-liste')

    const handleGetCompetitorsRankingLists = async () => {
        try {
            setIsLoading(true)
            const { rankingLists, pgCount, total } =
                await getCompetitorsRankingLists(currentPage, rowsPerPage)
            if (!rankingLists) return

            setCompetitorsRankingLists(rankingLists)
            setPageCount(pgCount)
            setTotal(total)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleGetRegionCompetitorsRankingLists = async () => {
        try {
            setIsLoading(true)
            const { rankingLists, pgCount, total } =
                await getRegionCompetitorsRankingLists(currentPage, rowsPerPage)
            if (!rankingLists) return

            setCompetitorsRankingLists(rankingLists)
            setPageCount(pgCount)
            setTotal(total)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleConfirm = async () => {
        const listData = await createCompetitorsRankingList(formData)
        if (listData) {
            handleCloseModal()
            handleGetCompetitorsRankingLists()
        }
    }

    const handleRequiredFields = () => {
        if (
            !formData?.name ||
            !formData?.gender?.gender_name ||
            !formData?.players_competition?.players_competition_name
        ) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const handleCloseModal = () => {
        setFormData({})
        setOpenModal(false)
    }

    const handleUpdateRankingList = async () => {
        try {
            setIsLoading(true)
            setShowRankingListConfirmationModal(false)
            const calculatedRank = await calculateRank()

            if (calculatedRank) {
                handleGetCompetitorsRankingLists()
            }
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        } finally {
            setShowRankingListConfirmationModal(false)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (activeTab === 'opste-rang-liste') {
            handleGetCompetitorsRankingLists()
        } else {
            handleGetRegionCompetitorsRankingLists()
        }
    }, [currentPage, rowsPerPage, activeTab])

    useEffect(() => {
        setCurrentPage(1)
    }, [activeTab])

    useEffect(() => {
        handleRequiredFields()
    }, [formData])

    return (
        <Row className="competitors-wrapper h-100">
            <Col md={12}>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Rang liste
                                </h2>
                                <p className="d-block d-md-none">Rang liste</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/pocetna'
                                                    : '/takmicar/pocetna',
                                        }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/rang-lista-takmicara'
                                                    : '/takmicar/rang-lista-takmicara',
                                        }}
                                    >
                                        Rang liste
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičara
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        {user?.role?.type === 'admin' && (
                            <div className="d-flex align-items-center d-md-none mb-3">
                                <Button
                                    type="text"
                                    onClick={() => setOpenModal(true)}
                                >
                                    <Add />
                                </Button>
                            </div>
                        )}
                    </Col>

                    {user?.role?.type === 'admin' && (
                        <Col xs={12} md={8}>
                            <Row className="d-flex align-items-center">
                                <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                    <Button
                                        type="secondary"
                                        className="ms-2 d-none d-md-block"
                                        onClick={() =>
                                            setShowRankingListConfirmationModal(
                                                true
                                            )
                                        }
                                    >
                                        Osveži rang liste
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row className="px-2">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            defaultActiveKey={'opste-rang-liste'}
                            onSelect={(e) => {
                                setActiveTab(e)
                            }}
                            activeKey={activeTab}
                        >
                            <Tab
                                eventKey={'opste-rang-liste'}
                                title={'Opšte rang liste'}
                            >
                                <Row>
                                    <Col>
                                        <Row className="d-md-none">
                                            <Col>
                                                <>
                                                    {competitorsRankingLists?.map(
                                                        (list, index) => (
                                                            <CompetitorsCategoryCard
                                                                key={index}
                                                                list={list}
                                                                isWhite={
                                                                    index %
                                                                        2 !==
                                                                    0
                                                                        ? true
                                                                        : false
                                                                }
                                                                path={`/rang-lista-po-tipu-licence/${list?.attributes?.uuid}`}
                                                            />
                                                        )
                                                    )}
                                                    {competitorsRankingLists?.length >
                                                        0 && (
                                                        <div className="d-md-none">
                                                            <PaginationWrapper
                                                                currentPage={
                                                                    currentPage
                                                                }
                                                                setCurrentPage={
                                                                    setCurrentPage
                                                                }
                                                                pageCount={
                                                                    pageCount
                                                                }
                                                                total={total}
                                                                rowsPerPage={
                                                                    rowsPerPage
                                                                }
                                                                setRowsPerPage={
                                                                    setRowsPerPage
                                                                }
                                                                showOnMobile
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            </Col>
                                        </Row>
                                        <Row className="d-none d-md-block">
                                            <Col>
                                                <CompetitorsRankingLists
                                                    data={
                                                        competitorsRankingLists
                                                    }
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    handleGetCompetitorsRankingLists={
                                                        handleGetCompetitorsRankingLists
                                                    }
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>

                            <Tab
                                eventKey={'regionalne-rang-liste'}
                                title={'Regionalne rang liste'}
                            >
                                <Row>
                                    <Col>
                                        <Row className="d-md-none">
                                            <Col>
                                                <>
                                                    {competitorsRankingLists?.map(
                                                        (list, index) => (
                                                            <CompetitorsCategoryCard
                                                                key={index}
                                                                list={list}
                                                                isWhite={
                                                                    index %
                                                                        2 !==
                                                                    0
                                                                        ? true
                                                                        : false
                                                                }
                                                                path={`/rang-lista-po-tipu-licence/${list?.attributes?.uuid}`}
                                                            />
                                                        )
                                                    )}
                                                    {competitorsRankingLists?.length >
                                                        0 && (
                                                        <div className="d-md-none">
                                                            <PaginationWrapper
                                                                currentPage={
                                                                    currentPage
                                                                }
                                                                setCurrentPage={
                                                                    setCurrentPage
                                                                }
                                                                pageCount={
                                                                    pageCount
                                                                }
                                                                total={total}
                                                                rowsPerPage={
                                                                    rowsPerPage
                                                                }
                                                                setRowsPerPage={
                                                                    setRowsPerPage
                                                                }
                                                                showOnMobile
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            </Col>
                                        </Row>
                                        <Row className="d-none d-md-block">
                                            <Col>
                                                <CompetitorsRankingLists
                                                    data={
                                                        competitorsRankingLists
                                                    }
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    handleGetCompetitorsRankingLists={
                                                        handleGetCompetitorsRankingLists
                                                    }
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                    showRegion={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>

            {/* MODAL - NOVA RANG LISTA */}
            <Modal
                size="md"
                centered
                show={openModal}
                onHide={() => handleCloseModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Nova rang lista</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col sm={12} className="mb-3">
                        <TextField
                            type="text"
                            label="Naziv"
                            placeholder="Naziv"
                            value={formData?.name || ''}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                            required
                        />
                    </Col>
                    <Col sm={12} className="mb-3">
                        <Dropdown
                            label="Konkurencija"
                            placeholder="Izaberi konkurenciju"
                            value={
                                formData?.players_competition
                                    ?.players_competition_name
                            }
                            required={true}
                            dropdownLinks={playersCompetitionsList}
                            handleSelect={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    players_competition: {
                                        players_competition_name: e,
                                    },
                                }))
                            }}
                        />
                    </Col>

                    {formData?.players_competition?.players_competition_name ===
                        '10s' && (
                        <Col md={12} className="mb-3">
                            <Dropdown
                                label={'Vrsta takmičarske legitimacije'}
                                placeholder="Izaberi takmičarsku legitimaciju..."
                                dropdownLinks={[
                                    'Crveni',
                                    'Narandžasti',
                                    'Zeleni',
                                ]}
                                handleSelect={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        players_competition: {
                                            ...prev.players_competition,
                                            players_competition_subcategory_name:
                                                e,
                                        },
                                    }))
                                }
                                value={
                                    formData?.players_competition
                                        ?.players_competition_subcategory_name ||
                                    ''
                                }
                            />
                        </Col>
                    )}

                    <Col md={12} className="mb-3">
                        <RadioButton
                            name="Pol"
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    gender: { gender_name: e.target.value },
                                }))
                            }}
                            radioValues={genderValues}
                            checkedValue={formData?.gender?.gender_name || ''}
                            required
                        />
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={() => handleCloseModal()}>
                        Odustani
                    </Button>
                    <Button
                        onClick={() => handleConfirm()}
                        disabled={disableButton}
                    >
                        Sačuvaj
                    </Button>
                </Modal.Footer>
            </Modal>

            <ConfirmationModal
                modalText="Da li ste sigurni da želite da ažurirate rang liste?"
                show={showRankingListConfirmationModal}
                onClick={() => {
                    handleUpdateRankingList()
                }}
                onHide={() => {
                    setShowRankingListConfirmationModal(false)
                }}
                secondButtonText="Ažuriraj"
            />
        </Row>
    )
}

export default CompetitorsRanking
